import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';


class ModalCustom extends Component {
  render() {
    const modalStyle = {
      overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)'
      },
      modal: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        color: '#000000',
        boxShadow: '0 0 0 rgba(0, 0, 0, 0.25)',
        borderTop: '5px solid #950024',
        borderBottom: '5px solid #950024',
        textAlign: 'center'
      }
    };
    return (
      <Modal open={this.props.open} showCloseIcon={false} onClose={this.props.close} center styles={modalStyle}>
        <div className="container">
          <h2>{this.props.tittle}</h2>
          <div>{this.props.contentModal}</div>
          {this.props.closeText && this.props.acceptText ? (
            <div className="row m-t-18p">
              <div className="col-6">
                <button onClick={this.props.close} className="btn-secondaryReward float-right">{this.props.closeText}</button>
              </div>
              <div className="col-6">
                <button onClick={this.props.onclickAccept} className="btn-redReward float-left">{this.props.acceptText}</button>
              </div>
            </div>
          ) : ('')}
          {
            this.props.closeText && !this.props.acceptText? (
              <div className="row">
                <div className="col-12 float-rigth">
                  <button onClick={this.props.close} className="btn-secondaryReward float-right">{this.props.closeText}</button>
                </div>
              </div>
            ) : ('')
          }
          {this.props.acceptText && !this.props.closeText? (
            <div className="row">
              <div className="col-12 float-rigth">
                <button onClick={this.props.onclickAccept} className="btn-redReward float-right">{this.props.acceptText}</button>
              </div>
            </div>
          ) : ('')}
        </div>
      </Modal>

    );
  }
}

ModalCustom.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.any.isRequired,
  tittle: PropTypes.string,
  contentModal: PropTypes.any,
  onclickClose: PropTypes.any.isRequired,
  onclickAccept: PropTypes.any.isRequired,
  closeText: PropTypes.string,
  acceptText: PropTypes.string,
};

export default ModalCustom;
