import React, { Component } from 'react';

import './footerAvisos.css';

class AvisoPrivacidad extends Component {

  constructor(props) {
    super(props);

    this.avisoPrivacidad = this.avisoPrivacidad.bind(this);
  }

  avisoPrivacidad() {
    window.open('/assets/login/avisoPrivacidad/Aviso_Privacidad_Girasolo_Abril_2020.pdf', '_blank');
  }

  render() {
    return (
      <div className="row avisos-login justify-content-between">
        <div className="col-xl-3 col-lg-4 col-md-5 avisoPrivacidad-login">
          <span onClick={this.avisoPrivacidad}>Aviso de privacidad</span>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-5 derechosReservados-login">
          <a href={'https://girasolo.mx/'} target='_blank' rel="noopener noreferrer">
            Derechos reservados
          </a>
          <p>
            <a href={'mailto:nubentum@girasolo.com'} target='_blank' rel="noopener noreferrer">
              nubentum@girasolo.com
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default AvisoPrivacidad;
