import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

import {
  actualizarUserAdminAction,
  updatePasswordAction
} from './../actions/actualizarUserAdminAction';
//import ModalSucces from './modalSucces';
import { TiInfoOutline } from 'react-icons/ti';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
//import ModalCustom from './../../../globalComponents/modal/modal';
import { CurrentUserIsActive } from './../../../helpers/capabilitiesControl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  IoIosCheckmarkCircle,
  IoIosCloseCircle
} from 'react-icons/io';

class FormEditUsersAdmin extends Component {

  constructor(props) {

    super(props);

    this.state = {
      idEmpresa: props.userAdmin._id,
      email: props.userAdmin.email,
      username: props.userAdmin.username,
      name: props.userAdmin.name,
      maxUsers: props.userAdmin.max_users,
      newPassword: '',
      changePass: false,
      errors: {},
      errorSubmit: {},
      actualizado: false,
      modal: false,
      message: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.isSubmitting = this.isSubmitting.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.changePass = this.changePass.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    let values = {
      email: this.state.email,
      name: this.state.name
    };

    this.validate(event, values, this.state.newPassword);
  }
  closeModal() {
    this.setState({ modal: false });
  }
  validate(event, values, newPassword) {
    let errors = {};
    if (!values.email || values.email === '') {
      errors.email = 'Por favor, introduce tu correo.';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Lo sentimos, correo inválido.';
    }

    if (!values.name || values.name === '') {
      errors.name = 'Por favor, introduce el nombre de la empresa.';
    }
    if (this.state.changePass) {
      if (!newPassword || newPassword === '') {
        errors.newPassword = 'Por favor, introduce una contraseña.';
      }
    }

    this.setState({ errors });
    this.isSubmitting(event, errors, values);
  }

  isSubmitting(event, errors, values) {
    event.preventDefault();

    if (Object.keys(errors).length === 0) {
      const token = JSON.parse(localStorage.getItem('token'));
      const loginData = JSON.parse(localStorage.getItem('loginData'));

      return actualizarUserAdminAction(values, this.state.idEmpresa, token).then(r => {
        if (r.ok) {
          if (r.data.ok) {
            //eslint-disable no-else-return
            if (this.state.changePass) {
              let newdata = {
                id: loginData._id,
                password: this.state.newPassword
              };

              return updatePasswordAction(token, newdata).then(
                response => {
                  if (response.ok) {
                    this.setState({
                      actualizado: true,
                      changePass: false,
                      newPassword: '',
                      message:'Datos actualizados'
                    });
                    this.notifySuccess();
                  } else if (response.error.name === 'TokenExpiredError') {
                    // eslint-disable-next-line react/prop-types
                    this.props.history.push('/admin/logout/true');
                  } else {
                    this.setState({
                      modal: true,
                      message:'La acción no pudo completarse correctamente'
                    });
                    this.notifyError();
                  }
                }
              );
            }
            this.setState({
              actualizado: true,
              message:'Datos actualizados'
            });
            this.notifySuccess();

          } else
          if (!r.data.ok) {
            if (Object.keys(r.data).length === 1) {
              if (r.data.message) {
                this.setState({
                  actualizado: false,
                  errors: {
                    email: 'Este correo ya existe para otro usuario.'
                  },
                  message: 'Este correo ya existe para otro usuario.'
                });
                this.notifyError();

              }
            } else if (Object.keys(r.data).length === 2) {
              if (r.data.message.errors.username) {
                this.setState({
                  actualizado: false,
                  errors: {
                    username: 'Este nickname ya existe para otro usuario.'
                  },
                  message: 'Este nickname ya existe para otro usuario.'
                });
                this.notifyError();

              }
            } else {
              this.setState({
                actualizado: false,
                message: 'Error actualizando.'
              });
              this.notifyError();
            }
          }
        } else if (r.status) {
          if (r.status === 401)
            this.props.history.push('/admin/logout/true');
        }
      });
    }

  }

  changePass() {
    this.setState({
      changePass: !this.state.changePass
    });
  }

  onCloseModal() {
    this.setState({
      actualizado: false
    });
  }

  notifySuccess = () => {
    toast.success(<div><IoIosCheckmarkCircle className='icon-toast' />&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message}</div>, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  notifyError = () => {
    toast.error(<div><IoIosCloseCircle className='icon-toast' />&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message}</div>, {
      position: toast.POSITION.TOP_RIGHT
    });
  };


  render() {
    return (
      <div className="container-fluid">
        <div className="m-t-5-users-SA">
          <div className='row'>
            <div className='col-11'>
              <ToastContainer autoClose={5000} />

              <form method="post" className="formEditUsers-SA" id="formEditUsers-SA" onSubmit={this.handleSubmit}>


                <Form.Row>
                  <Col xs='4' lg='4' md='8' sm='12'>
                    <Form.Group className="group margin-l-40p">
                      <input type="email" id="email" name="email" value={this.state.email || ''}
                        onChange={event =>
                          this.setState({ email: event.target.value })} required
                        className={(this.state.errors.email && ('input-errorLine')) + ' input-form'}
                        minLength="1" maxLength="90" />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <Form.Label className='custom-label-form'>Correo electrónico*</Form.Label>
                      {this.state.errors.email && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.email}</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs='4' lg='4' md='8' sm='12'>
                    <Form.Group className="group margin-l-40p">
                      <input type="text" id="name" name="name" value={this.state.name || ''}
                        onChange={event =>
                          this.setState({ name: event.target.value })} required
                        className={(this.state.errors.name && ('input-errorLine')) + ' input-form'}
                        minLength="1" maxLength="90" />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <Form.Label className='custom-label-form'>Nombre de la empresa*</Form.Label>
                      {this.state.errors.name && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.name}</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs='4' lg='4' md='8' sm='12'>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <div className='custom-space-row-3'></div>
                </Form.Row>
                <Form.Row>
                  <Col xs='4' lg='4' md='8' sm='12'>
                    <Form.Group className="group margin-l-40p">
                      <input type="text" id="username" name="username" value={this.state.username || ''}
                        disabled
                        className={(this.state.errors.username && ('input-errorLine')) + ' input-form custom-input'}
                        minLength="1" maxLength="90" />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <Form.Label className='custom-label-form'>Nickname de la empresa</Form.Label>
                      {this.state.errors.username && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.username}</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs='4' lg='4' md='8' sm='12'>
                    <Form.Group className="group margin-l-40p">
                      <input type="number" id="max_users" name="max_users" value={this.state.maxUsers || ''}
                        disabled
                        className={(this.state.errors.maxUsers && ('input-errorLine')) + ' input-form custom-input'}
                        minLength="1" maxLength="90" />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <Form.Label className='custom-label-form'>Usuarios máximos</Form.Label>
                      {this.state.errors.maxUsers && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.maxUsers}</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs='4' lg='4' md='8' sm='12'>
                    <Form.Group className="group margin-l-40p">
                      {
                        this.state.changePass ? (
                          <input type="password" id="password" name="password" value={this.state.newPassword}
                            onChange={event => this.setState({ newPassword: event.target.value })}
                            className={' input-form custom-input ' + (this.state.errors.newPassword && ('input-errorLine'))} />
                        ) : (
                          <input type="password" className='custom-input' id="password" name="password" disabled={true} value={'demoPass'} />
                        )
                      }
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <Form.Label className='custom-label-form'>Contraseña <abbr>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</abbr><Link to='/admin/profile' className='change-pass-admin' onClick={this.changePass}>[Cambiar]</Link></Form.Label>
                      {this.state.errors.newPassword && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.newPassword}</span>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row className="m-t-4rem">
                  <Col lg={7} xs={7} md={4} sm={2}></Col>
                  {CurrentUserIsActive() ? (
                    <Col>
                      <div className=''>
                        <div className='fixed-bottom bottom-planes-SA p-b-3-planes-SA p-t-2-planes-SA'>
                          <div className='row align-items-end justify-content-end'>
                            <div className='col-xl-5 col-lg-6 col-md-8'>
                              <Col className='d-inline'>
                                <Link to='/admin/home' className='btn-secondaryReward btn-custom-size back-btn'>Volver</Link>
                              </Col>
                              <Col className='d-inline'>
                                <button className='btn-primaryReward btn-custom-size' type='submit'>Actualizar</button>
                              </Col>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ) : ('')}

                </Form.Row>

              </form>
            </div>
          </div>

        </div>



      </div>
    );
  }
}

FormEditUsersAdmin.propTypes = {
  userAdmin: PropTypes.object.isRequired,
  updatePasswordAction: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(FormEditUsersAdmin);
