

export function CurrentUserCan(data) {

  const capabilities = {
    root: ['MANAGE', 'CREATE', 'DELETE', 'EDIT', 'READ'],
    admin: ['CREATE', 'DELETE', 'EDIT', 'READ'],
    user: ['READ'],
    movile: ['READ'],
  };
  const loginData = JSON.parse(localStorage.getItem('loginData'));
  if (loginData) {

    if (loginData.role === 'root') {
      if (capabilities.root.includes(data)) {
        return true;
      }
      return false;

    } else if (loginData.role === 'admin') {
      if (capabilities.admin.includes(data)) {
        return true;
      }
      return false;
    } else if (loginData.role === 'user') {
      if (capabilities.user.includes(data)) {
        return true;
      }
      return false;
    }
  }
  return false;
}
export function CurrentUserIs(data) {

  const loginData = JSON.parse(localStorage.getItem('loginData'));
  if (loginData) {
    if (loginData.role === data) {
      return true;
    }
    return false;
  } return false;
}

export function CurrentUserIsActive() {

  const loginData = JSON.parse(localStorage.getItem('loginData'));
  if (loginData.active) {
    if (loginData.active.active) {
      return true;
    }
  }
  return false;
}
