import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TiInfoOutline } from 'react-icons/ti';

class ErrorMessages extends Component {
  render() {
    let { errors, errorLogin } = this.props;
    return (
      <div>
        {
          ( Object.keys(errors).length > 0) ?
            (
              <div className="col-md-12 m-b-10-login mensajesError-login">
                {
                  (errors.email) ?
                    (
                      <p><TiInfoOutline className="iconError-login"></TiInfoOutline> {(errors.email)}</p>
                    ) :
                    (
                      <span></span>
                    )
                }
                {
                  (errors.password) ?
                    (
                      <p><TiInfoOutline className="iconError-login"></TiInfoOutline> {(errors.password)}</p>
                    ) :
                    (
                      <span></span>
                    )
                }
                {
                  (errors.passwordConfirm) ?
                    (
                      <p><TiInfoOutline className="iconError-login"></TiInfoOutline> {(errors.passwordConfirm)}</p>
                    ) :
                    (
                      <span></span>
                    )
                }
                {
                  (errors.sinCoincidencia) ?
                    (
                      <p><TiInfoOutline className="iconError-login"></TiInfoOutline> {(errors.sinCoincidencia)}</p>
                    ) :
                    (
                      <span></span>
                    )
                }
              </div>
            ) :
            (
              <div></div>
            )
        }
        {
          (errorLogin !== '') ?
            (
              (errorLogin !== undefined ) ?
                (
                  <div className="col-md-12 m-b-12-login mensajesError-login">
                    <p><TiInfoOutline className="iconError-login"></TiInfoOutline> {(errorLogin)}</p>
                  </div>
                ) :
                (<div></div>)
            ) :
            (
              <div></div>
            )
        }
      </div>
    );
  }
}
ErrorMessages.propTypes = {
  errors: PropTypes.object.isRequired,
  errorLogin: PropTypes.string,
};

export default ErrorMessages;
