import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ToastContainer, toast } from 'react-toastify';
import { TiInfoOutline } from 'react-icons/ti';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import { consultarPlanesAdminAction } from './../actions/consultarPlanesAdminAction';
import { actualizarPlanAdminAction } from './../actions/actualizarPlanAdminAction';
import { consultPlanesAdmin } from './../selectors';

import 'react-toastify/dist/ReactToastify.css';
import equal from 'fast-deep-equal';

class FormActualizarPlanAdmin extends Component {

  constructor(props) {
    super(props);

    this.state = {
      idPlan: props.planAdmin._id,
      name: props.planAdmin.name,
      percent: props.planAdmin.percent,
      actualizarPlanAuto: props.planAdmin.auto_update.active || props.planAdmin.auto_degrade.active,
      visitasH: props.planAdmin.auto_update.conditions.visits.active || props.planAdmin.auto_degrade.conditions.visits.active,
      puntosH: props.planAdmin.auto_update.conditions.points.active || props.planAdmin.auto_degrade.conditions.points.active,
      comprasH: props.planAdmin.auto_update.conditions.purchase_amount.active || props.planAdmin.auto_degrade.conditions.purchase_amount.active,
      cantidadHV: props.planAdmin.auto_update.conditions.visits.required_amount,
      cantidadHP: props.planAdmin.auto_update.conditions.points.required_amount,
      cantidadHC: props.planAdmin.auto_update.conditions.purchase_amount.required_amount,
      cantidadBV: props.planAdmin.auto_degrade.conditions.visits.required_amount,
      cantidadBP: props.planAdmin.auto_degrade.conditions.points.required_amount,
      cantidadBC: props.planAdmin.auto_degrade.conditions.purchase_amount.required_amount,
      rangoDiasH: props.planAdmin.auto_update.days,
      rangoDiasB: props.planAdmin.auto_degrade.days,
      idPlanH: props.planAdmin.auto_update.id_plan_update,
      idPlanB: props.planAdmin.auto_degrade.id_plan_degrade || props.planAdmin._id,
      errors: {},
      errorSubmit: '',
      actualizando: false,
      disableMax: false,
      disableMin: false,
      disableStatusVisit: true,
      disableStatusPoint: true,
      disableStatusBuy: true,
      disableStatus: true

    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.isSubmitting = this.isSubmitting.bind(this);
    this.volver = this.volver.bind(this);
  }

  componentDidMount() {
    const token = JSON.parse(localStorage.getItem('token'));
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const idEmpresa = loginData._id;
    this.props.consultarPlanesAdminAction(idEmpresa, token).then(
      response => {
        let minValue = 0;
        let maxValue = 0;
        let percents = [];
        for (let i = 0; i < response.payload.length; i++) {
          if (i < response.payload.length) {
            percents.push(parseFloat(response.payload[i].percent));
          }
        }
        minValue = Math.min(...percents);
        maxValue = Math.max(...percents);

        if (parseFloat(this.props.planAdmin.percent) === minValue) {
          this.setState({ disableMin: true });
        }
        if (parseFloat(this.props.planAdmin.percent) === maxValue) {
          this.setState({ disableMax: true });
        }
      }
    );

  }

  handleSubmit(event) {
    event.preventDefault();

    const loginData = JSON.parse(localStorage.getItem('loginData'));

    let values = {};

    if (this.state.disableMin && this.state.disableMax) {
      values = {
        _id: this.state.idPlan,
        name: this.state.name,
        percent: this.state.percent,
        id_company: loginData._id
      };
    } else if (this.state.disableMin && !this.state.disableMax) {
      values = {
        _id: this.state.idPlan,
        name: this.state.name,
        percent: this.state.percent,
        id_company: loginData._id,
        auto_update: {
          active: this.state.actualizarPlanAuto,
          days: this.state.rangoDiasH,
          id_plan_update: this.state.idPlanH,
          conditions: {
            points: {
              active: this.state.puntosH,
              required_amount: this.state.cantidadHP
            },
            purchase_amount: {
              active: this.state.comprasH,
              required_amount: this.state.cantidadHC
            },
            visits: {
              active: this.state.visitasH,
              required_amount: this.state.cantidadHV
            }
          }
        }
      };
    } else if (!this.state.disableMin && this.state.disableMax) {
      values = {
        _id: this.state.idPlan,
        name: this.state.name,
        percent: this.state.percent,
        id_company: loginData._id,
        auto_degrade: {
          active: this.state.actualizarPlanAuto,
          days: this.state.rangoDiasB,
          id_plan_degrade: this.state.idPlanB,
          conditions: {
            points: {
              active: this.state.puntosH,
              required_amount: this.state.cantidadBP
            },
            purchase_amount: {
              active: this.state.comprasH,
              required_amount: this.state.cantidadBC
            },
            visits: {
              active: this.state.visitasH,
              required_amount: this.state.cantidadBV
            }
          }
        }
      };
    } else {
      values = {
        _id: this.state.idPlan,
        name: this.state.name,
        percent: this.state.percent,
        id_company: loginData._id,
        auto_update: {
          active: this.state.actualizarPlanAuto,
          days: this.state.rangoDiasH,
          id_plan_update: this.state.idPlanH,
          conditions: {
            points: {
              active: this.state.puntosH,
              required_amount: this.state.cantidadHP
            },
            purchase_amount: {
              active: this.state.comprasH,
              required_amount: this.state.cantidadHC
            },
            visits: {
              active: this.state.visitasH,
              required_amount: this.state.cantidadHV
            }
          }
        },
        auto_degrade: {
          active: this.state.actualizarPlanAuto,
          days: this.state.rangoDiasB,
          id_plan_degrade: this.state.idPlanB,
          conditions: {
            points: {
              active: this.state.puntosH,
              required_amount: this.state.cantidadBP
            },
            purchase_amount: {
              active: this.state.comprasH,
              required_amount: this.state.cantidadBC
            },
            visits: {
              active: this.state.visitasH,
              required_amount: this.state.cantidadBV
            }
          }
        }
      };
    }

    this.validate(event, values);
  }

  validate(event, values) {

    let errors = {};
    if (!values.percent || values.percent === '') {
      errors.percent = 'Por favor, introduce el porcentaje.';
    } else if (values.percent > 100 || values.percent < 0) {
      errors.percent = 'Por favor, introduce un porcentaje válido.';
    }

    if (!values.name || values.name === '') {
      errors.name = 'Por favor, introduce el nombre del plan.';
    }

    if (!this.state.disableMax && !this.state.disableMin) {
      if (values.auto_degrade.active || values.auto_update.active) {
        if (!values.auto_update.days || values.auto_update.days === '' || values.auto_update.days === 0 || values.auto_update.days === '0') {
          errors.rangoDiasH = 'Por favor, introduce el rango de días.';
        } else if (values.auto_update.days < 0) {
          errors.rangoDiasH = 'Por favor, introduce una cantidad válida.';
        }

        if (!values.auto_degrade.days || values.auto_degrade.days === '' || values.auto_degrade.days === 0 || values.auto_degrade.days === '0') {
          errors.rangoDiasB = 'Por favor, introduce el rango de días.';
        } else if (values.auto_degrade.days < 0) {
          errors.rangoDiasB = 'Por favor, introduce una cantidad válida.';
        }

        if (!values.auto_update.id_plan_update || values.auto_update.id_plan_update === '' || values.auto_update.id_plan_update === undefined) {
          errors.idPlanH = 'Por favor, selecciona un plan.';
        }

        if (!values.auto_degrade.id_plan_degrade || values.auto_degrade.id_plan_degrade === '' || values.auto_degrade.id_plan_degrade === undefined) {
          errors.idPlanB = 'Por favor, selecciona un plan.';
        }

        if (values.auto_update.conditions.visits.required_amount !== ''
          && values.auto_update.conditions.visits.required_amount !== '0'
          && values.auto_update.conditions.visits.required_amount !== 0
          && values.auto_degrade.conditions.visits.required_amount !== ''
          && values.auto_degrade.conditions.visits.required_amount !== '0'
          && values.auto_degrade.conditions.visits.required_amount !== 0) {
          if (!values.auto_update.conditions.visits.active || !values.auto_degrade.conditions.visits.active) {
            this.setState({ visitasH: true });
            if (values.auto_update.conditions.visits.required_amount === '') {
              errors.cantidadHV = 'Por favor, introduce una cantidad.';
            }
            if (values.auto_degrade.conditions.visits.required_amount === '') {
              errors.cantidadBV = 'Por favor, introduce una cantidad.';
            }
          }
        }

        if (values.auto_update.conditions.visits.active || values.auto_degrade.conditions.visits.active) {
          if (values.auto_update.conditions.visits.required_amount === ''
            || values.auto_update.conditions.visits.required_amount === '0'
            || values.auto_update.conditions.visits.required_amount === 0) {
            errors.cantidadHV = 'Por favor, introduce una cantidad.';
          }
          if (values.auto_degrade.conditions.visits.required_amount === ''
            || values.auto_degrade.conditions.visits.required_amount === '0'
            || values.auto_degrade.conditions.visits.required_amount === 0) {
            errors.cantidadBV = 'Por favor, introduce una cantidad.';
          }
        }

        if (values.auto_update.conditions.points.required_amount !== ''
          && values.auto_update.conditions.points.required_amount !== '0'
          && values.auto_update.conditions.points.required_amount !== 0
          && values.auto_degrade.conditions.points.required_amount !== ''
          && values.auto_degrade.conditions.points.required_amount !== '0'
          && values.auto_degrade.conditions.points.required_amount !== 0) {
          if (!values.auto_update.conditions.points.active || !values.auto_degrade.conditions.points.active) {
            this.setState({ puntosH: true });
            if (values.auto_update.conditions.points.required_amount === '') {
              errors.cantidadHP = 'Por favor, introduce una cantidad.';
            }
            if (values.auto_degrade.conditions.points.required_amount === '') {
              errors.cantidadBP = 'Por favor, introduce una cantidad.';
            }
          }
        }

        if (values.auto_update.conditions.points.active || values.auto_degrade.conditions.points.active) {
          if (values.auto_update.conditions.points.required_amount === ''
            || values.auto_update.conditions.points.required_amount === '0'
            || values.auto_update.conditions.points.required_amount === 0) {
            errors.cantidadHP = 'Por favor, introduce una cantidad.';
          }
          if (values.auto_degrade.conditions.points.required_amount === ''
            || values.auto_degrade.conditions.points.required_amount === '0'
            || values.auto_degrade.conditions.points.required_amount === 0) {
            errors.cantidadBP = 'Por favor, introduce una cantidad.';
          }
        }

        if (values.auto_update.conditions.purchase_amount.required_amount !== ''
          && values.auto_update.conditions.purchase_amount.required_amount !== '0'
          && values.auto_update.conditions.purchase_amount.required_amount !== 0
          && values.auto_degrade.conditions.purchase_amount.required_amount !== ''
          && values.auto_degrade.conditions.purchase_amount.required_amount !== '0'
          && values.auto_degrade.conditions.purchase_amount.required_amount !== 0) {
          if (!values.auto_update.conditions.purchase_amount.active || !values.auto_degrade.conditions.purchase_amount.active) {
            this.setState({ comprasH: true });
            if (values.auto_update.conditions.purchase_amount.required_amount === '') {
              errors.cantidadHC = 'Por favor, introduce una cantidad.';
            }
            if (values.auto_degrade.conditions.purchase_amount.required_amount === '') {
              errors.cantidadBC = 'Por favor, introduce una cantidad.';
            }
          }
        }

        if (values.auto_update.conditions.purchase_amount.active || values.auto_degrade.conditions.purchase_amount.active) {
          if (values.auto_update.conditions.purchase_amount.required_amount === ''
            || values.auto_update.conditions.purchase_amount.required_amount === '0'
            || values.auto_update.conditions.purchase_amount.required_amount === 0) {
            errors.cantidadHC = 'Por favor, introduce una cantidad.';
          }
          if (values.auto_degrade.conditions.purchase_amount.required_amount === ''
            || values.auto_degrade.conditions.purchase_amount.required_amount === '0'
            || values.auto_degrade.conditions.purchase_amount.required_amount === 0) {
            errors.cantidadBC = 'Por favor, introduce una cantidad.';
          }
        }
      }
    } else if (!this.state.disableMax && this.state.disableMin) {
      if (values.auto_update.active) {
        if (!values.auto_update.days || values.auto_update.days === '' || values.auto_update.days === 0 || values.auto_update.days === '0') {
          errors.rangoDiasH = 'Por favor, introduce el rango de días.';
        } else if (values.auto_update.days < 0) {
          errors.rangoDiasH = 'Por favor, introduce una cantidad válida.';
        }

        if (!values.auto_update.id_plan_update || values.auto_update.id_plan_update === '' || values.auto_update.id_plan_update === undefined) {
          errors.idPlanH = 'Por favor, selecciona un plan.';
        }


        if (values.auto_update.conditions.visits.required_amount !== ''
          && values.auto_update.conditions.visits.required_amount !== '0'
          && values.auto_update.conditions.visits.required_amount !== 0) {
          if (!values.auto_update.conditions.visits.active) {
            this.setState({ visitasH: true });
            if (values.auto_update.conditions.visits.required_amount === '') {
              errors.cantidadHV = 'Por favor, introduce una cantidad.';
            }
          }
        }

        if (values.auto_update.conditions.visits.active) {
          if (values.auto_update.conditions.visits.required_amount === ''
            || values.auto_update.conditions.visits.required_amount === '0'
            || values.auto_update.conditions.visits.required_amount === 0) {
            errors.cantidadHV = 'Por favor, introduce una cantidad.';
          }
        }

        if (values.auto_update.conditions.points.required_amount !== ''
          && values.auto_update.conditions.points.required_amount !== '0'
          && values.auto_update.conditions.points.required_amount !== 0) {
          if (!values.auto_update.conditions.points.active) {
            this.setState({ puntosH: true });
            if (values.auto_update.conditions.points.required_amount === '') {
              errors.cantidadHP = 'Por favor, introduce una cantidad.';
            }
          }
        }

        if (values.auto_update.conditions.points.active) {
          if (values.auto_update.conditions.points.required_amount === ''
            || values.auto_update.conditions.points.required_amount === '0'
            || values.auto_update.conditions.points.required_amount === 0) {
            errors.cantidadHP = 'Por favor, introduce una cantidad.';
          }
        }

        if (values.auto_update.conditions.purchase_amount.required_amount !== ''
          && values.auto_update.conditions.purchase_amount.required_amount !== '0'
          && values.auto_update.conditions.purchase_amount.required_amount !== 0) {
          if (!values.auto_update.conditions.purchase_amount.active) {
            this.setState({ comprasH: true });
            if (values.auto_update.conditions.purchase_amount.required_amount === '') {
              errors.cantidadHC = 'Por favor, introduce una cantidad.';
            }

          }
        }

        if (values.auto_update.conditions.purchase_amount.active) {
          if (values.auto_update.conditions.purchase_amount.required_amount === ''
            || values.auto_update.conditions.purchase_amount.required_amount === '0'
            || values.auto_update.conditions.purchase_amount.required_amount === 0) {
            errors.cantidadHC = 'Por favor, introduce una cantidad.';
          }

        }
      }
    } else if (this.state.disableMax && !this.state.disableMin) {
      if (values.auto_degrade.active) {

        if (!values.auto_degrade.days || values.auto_degrade.days === '' || values.auto_degrade.days === 0 || values.auto_degrade.days === '0') {
          errors.rangoDiasB = 'Por favor, introduce el rango de días.';
        } else if (values.auto_degrade.days < 0) {
          errors.rangoDiasB = 'Por favor, introduce una cantidad válida.';
        }

        if (!values.auto_degrade.id_plan_degrade || values.auto_degrade.id_plan_degrade === '' || values.auto_degrade.id_plan_degrade === undefined) {
          errors.idPlanB = 'Por favor, selecciona un plan.';
        }

        if (values.auto_degrade.conditions.visits.required_amount !== ''
          && values.auto_degrade.conditions.visits.required_amount !== '0'
          && values.auto_degrade.conditions.visits.required_amount !== 0) {
          if (!values.auto_degrade.conditions.visits.active) {
            this.setState({ visitasH: true });
            if (values.auto_degrade.conditions.visits.required_amount === '') {
              errors.cantidadBV = 'Por favor, introduce una cantidad.';
            }
          }
        }

        if (values.auto_degrade.conditions.visits.active) {

          if (values.auto_degrade.conditions.visits.required_amount === ''
            || values.auto_degrade.conditions.visits.required_amount === '0'
            || values.auto_degrade.conditions.visits.required_amount === 0) {
            errors.cantidadBV = 'Por favor, introduce una cantidad.';
          }
        }

        if (values.auto_degrade.conditions.points.required_amount !== ''
          && values.auto_degrade.conditions.points.required_amount !== '0'
          && values.auto_degrade.conditions.points.required_amount !== 0) {
          if (!values.auto_degrade.conditions.points.active) {
            this.setState({ puntosH: true });

            if (values.auto_degrade.conditions.points.required_amount === '') {
              errors.cantidadBP = 'Por favor, introduce una cantidad.';
            }
          }
        }

        if (values.auto_degrade.conditions.points.active) {

          if (values.auto_degrade.conditions.points.required_amount === ''
            || values.auto_degrade.conditions.points.required_amount === '0'
            || values.auto_degrade.conditions.points.required_amount === 0) {
            errors.cantidadBP = 'Por favor, introduce una cantidad.';
          }
        }

        if (values.auto_degrade.conditions.purchase_amount.required_amount !== ''
          && values.auto_degrade.conditions.purchase_amount.required_amount !== '0'
          && values.auto_degrade.conditions.purchase_amount.required_amount !== 0) {
          if (!values.auto_degrade.conditions.purchase_amount.active) {
            this.setState({ comprasH: true });

            if (values.auto_degrade.conditions.purchase_amount.required_amount === '') {
              errors.cantidadBC = 'Por favor, introduce una cantidad.';
            }
          }
        }

        if (values.auto_degrade.conditions.purchase_amount.active) {

          if (values.auto_degrade.conditions.purchase_amount.required_amount === ''
            || values.auto_degrade.conditions.purchase_amount.required_amount === '0'
            || values.auto_degrade.conditions.purchase_amount.required_amount === 0) {
            errors.cantidadBC = 'Por favor, introduce una cantidad.';
          }
        }
      }
    }

    this.setState({ errors });
    this.isSubmitting(event, errors, values);

  }

  isSubmitting(event, errors, values) {
    event.preventDefault();
    this.setState({
      actualizando: true
    });
    if (Object.keys(errors).length === 0) {

      const token = JSON.parse(localStorage.getItem('token'));
      const data = (JSON.stringify(values));
      return actualizarPlanAdminAction(data, values._id, token).then(r => {
        if (r.ok) {

          toast.success(<div><IoIosCheckmarkCircle className='icon-toast' />{'Plan actualizado correctamente.'}</div>, {
            position: toast.POSITION.TOP_RIGHT
          });

          this.setState({
            errors: {},
            errorSubmit: '',
            actualizando: false
          });
        } else
        if (!r.ok) {
          if (r.error) {
            if (r.error === 401) {
              this.props.history.push('/admin/logout/true');
            }
          } else if (r.error.message) {
            this.setState({
              errorSubmit: r.error.message,
              actualizando: false
            });
          }
        }
      });
    }
  }

  volver() {
    this.props.history.push('/admin/planes');
  }

  componentDidUpdate(_prevProps, prevstate) {
    if (equal(this.state.disableStatusVisit, prevstate.disableStatusVisit)) {
      if (this.state.visitasH && this.state.disableStatusVisit) {
        this.setState({ disableStatusVisit: false, disableStatus: false });
      }
    }
    if (equal(this.state.disableStatusPoint, prevstate.disableStatusPoint)) {
      if (this.state.puntosH && this.state.disableStatusPoint) {
        this.setState({ disableStatusPoint: false, disableStatus: false });
      }
    }
    if (equal(this.state.disableStatusBuy, prevstate.disableStatusBuy)) {
      if (this.state.comprasH && this.state.disableStatusBuy) {
        this.setState({ disableStatusBuy: false, disableStatus: false });
      }
    }

    if (!equal(this.state.visitasH, prevstate.visitasH)) {
      if (this.state.visitasH) {
        this.setState({ disableStatusVisit: false });
      } else {
        this.setState({
          disableStatusVisit: true,
          cantidadHV: '0',
          cantidadBV: '0'
        });
      }
    }
    if (!equal(this.state.puntosH, prevstate.puntosH)) {
      if (this.state.puntosH) {
        this.setState({ disableStatusPoint: false });
      } else {
        this.setState({
          disableStatusPoint: true,
          cantidadHP: '0',
          cantidadBP: '0'
        });
      }
    }
    if (!equal(this.state.comprasH, prevstate.comprasH)) {
      if (this.state.comprasH) {
        this.setState({ disableStatusBuy: false });
      } else {
        this.setState({
          disableStatusBuy: true,
          cantidadHC: '0',
          cantidadBC: '0'
        });
      }
    }
    if (!equal(this.state.visitasH, prevstate.visitasH) ||
      !equal(this.state.puntosH, prevstate.puntosH) ||
      !equal(this.state.comprasH, prevstate.comprasH)) {
      if (this.state.visitasH || this.state.puntosH || this.state.comprasH) {
        this.setState({
          disableStatus: false
        });
      }
      if (!this.state.visitasH && !this.state.puntosH && !this.state.comprasH) {
        this.setState({
          disableStatus: true,
          rangoDiasB: '0',
          rangoDiasH: '0'
        });
      }
    }


  }

  render() {

    let { active } = this.props;
    let defaultValueH = this.state.idPlanH;
    let defaultValueB = this.state.idPlanB;

    if (this.props.planesAdminList.length > -1) {
      for (let i = 0; this.props.planesAdminList.length > i; i++) {
        if (this.props.planesAdminList[i]._id === this.state.idPlanH) {
          defaultValueH = this.props.planesAdminList[i]._id;
        }
        if (this.props.planesAdminList[i]._id === this.state.idPlanB) {
          defaultValueB = this.props.planesAdminList[i]._id;
        }
      }
    }

    return (
      <div className="row">
        <div className="col-11">
          <div className="m-t-2-planes-SA">
            <form method="post" className="formUpdatePlanes-SA m-b-15-planes-SA" id="formUpdatePlanes-SA" onSubmit={this.handleSubmit}>

              <div className="row justify-content-start">
                <div className="col-xl-12 col-lg-12 col-12">
                  <div className="form-group">
                    <div className="input-group input-group-addPlan-SA m-b-2rem-planes-SA">
                      <input type="text" id="name" name="name" value={this.state.name}
                        onChange={event => this.setState({ name: event.target.value })}
                        className={this.state.errors.name && ('input-errorLine')} />
                      <label>Plan</label>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      {this.state.errors.name && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.name}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-start">
                <div className="col-xl-6 col-lg-6 col-12">
                  <div className="form-group">
                    <div className="input-group input-group-addPlan-SA m-b-8-planes-SA">
                      <input type="number" id="percent" name="percent" value={this.state.percent}
                        onChange={event => this.setState({ percent: event.target.value })}
                        className={this.state.errors.percent && ('input-errorLine')} />
                      <label>% de puntos</label>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      {this.state.errors.percent && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.percent}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-start align-items-center">
                <div className="col-xl-5 col-lg-5 col-sm-10">
                  <FormGroup row className="input-group-checkbox-addPlan-SA">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.actualizarPlanAuto}
                          onClick={event => this.setState({ actualizarPlanAuto: event.target.checked })}
                          name="actualizarPlanAuto"
                        />
                      }
                      label="Actualizar plan automáticamente"
                    />
                  </FormGroup>
                </div>
              </div>

              {
                this.state.actualizarPlanAuto ?
                  (
                    <div className="row justify-content-between">
                      <div className="col-xl-7 col-lg-7 col-md-12 col-12">
                        <div className="row">
                          <div className="col-12 m-b-2rem-planes-SA">
                            <div className="contentPlanAuto-planes-SA">
                              <span className="textPlanAuto-planes-SA">Habilitar por:</span>
                            </div>
                          </div>
                        </div>
                        <div className="m-b-2rem-planes-SA">
                          <div className="row">
                            <div className="col-md-4">
                              <FormGroup row className="input-group-checkbox-addPlan-SA">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.visitasH}
                                      onClick={event => this.setState({ visitasH: event.target.checked })}
                                      name="visitasH"
                                    />
                                  }
                                  label="Visitas"
                                />
                              </FormGroup>
                            </div>
                            {!this.state.disableMax ? (
                              <div className="col-md-8">
                                <div className="input-group input-group-addPlan-SA custom-fontSize">
                                  <input type="number" id="cantidadHV" name="cantidadHV" value={this.state.cantidadHV}
                                    onChange={event => this.setState({ cantidadHV: event.target.value })}
                                    className={this.state.errors.cantidadHV && ('input-errorLine')} disabled={this.state.disableStatusVisit} />
                                  <label>Cantidad</label>
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                  {this.state.errors.cantidadHV && (
                                    <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.cantidadHV}</span>
                                  )}
                                </div>
                              </div>
                            ) : ('')}
                          </div>
                        </div>

                        <div className="m-b-2rem-planes-SA">
                          <div className="row">
                            <div className="col-md-4">
                              <FormGroup row className="input-group-checkbox-addPlan-SA">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.puntosH}
                                      onClick={event => this.setState({ puntosH: event.target.checked })}
                                      name="puntosH"
                                    />
                                  }
                                  label="Puntos"
                                />
                              </FormGroup>
                            </div>
                            {!this.state.disableMax ? (
                              <div className="col-md-8">
                                <div className="input-group input-group-addPlan-SA custom-fontSize">
                                  <input type="number" id="cantidadHP" name="cantidadHP" value={this.state.cantidadHP}
                                    onChange={event => this.setState({ cantidadHP: event.target.value })}
                                    className={this.state.errors.cantidadHP && ('input-errorLine')} disabled={this.state.disableStatusPoint} />
                                  <label>Cantidad</label>
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                  {this.state.errors.cantidadHP && (
                                    <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.cantidadHP}</span>
                                  )}
                                </div>
                              </div>
                            ) : ('')}

                          </div>
                        </div>


                        <div className="m-b-2rem-planes-SA">
                          <div className="row">
                            <div className="col-md-4">
                              <FormGroup row className="input-group-checkbox-addPlan-SA">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.comprasH}
                                      onClick={event => this.setState({ comprasH: event.target.checked })}
                                      name="comprasH"
                                    />
                                  }
                                  label="Compras"
                                />
                              </FormGroup>
                            </div>
                            {!this.state.disableMax ? (
                              <div className="col-md-8">
                                <div className="input-group input-group-addPlan-SA custom-fontSize">
                                  <input type="number" id="cantidadHC" name="cantidadHC" value={this.state.cantidadHC}
                                    onChange={event => this.setState({ cantidadHC: event.target.value })}
                                    className={this.state.errors.cantidadHC && ('input-errorLine')} disabled={this.state.disableStatusBuy} />
                                  <label>Cantidad</label>
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                  {this.state.errors.cantidadHC && (
                                    <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.cantidadHC}</span>
                                  )}
                                </div>
                              </div>
                            ) : ('')}
                          </div>
                        </div>

                        {!this.state.disableMax ? (
                          <div >
                            <div className="m-b-2rem-planes-SA">
                              <div className="row justify-content-end">
                                <div className="col-md-8">
                                  <div className="input-group input-group-addPlan-SA custom-fontSize">
                                    <input type="number" id="rangoDiasH" name="rangoDiasH" value={this.state.rangoDiasH}
                                      onChange={event => this.setState({ rangoDiasH: event.target.value })}
                                      className={this.state.errors.rangoDiasH && ('input-errorLine')} disabled={this.state.disableStatus} />
                                    <label>Rango días</label>
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    {this.state.errors.rangoDiasH && (
                                      <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.rangoDiasH}</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="m-t-3rem-planes-SA">
                              <div className="row justify-content-end">
                                <div className="col-md-8">
                                  <div className="input-group input-group-addPlan-SA custom-fontSize input-group-select-SA m-b-4-planes-SA">
                                    <select id="inputState" value={defaultValueH || ''}
                                      onChange={event => this.setState({ idPlanH: event.target.value })}
                                      className={this.state.errors.idPlanH && ('input-errorLine')} disabled={this.state.disableStatus}>
                                      <option value={''} disabled> Selecciona un plan </option>
                                      {
                                        this.props.planesAdminList ?
                                          (
                                            this.props.planesAdminList.length > -1 ?
                                              (
                                                this.props.planesAdminList.filter(option => option._id !== this.state.idPlan).map((option, index) =>
                                                  (<option key={index} value={option._id}>{option.name}</option>)
                                                )
                                              ) :
                                              (
                                                <option></option>
                                              )
                                          ) :
                                          (
                                            <option></option>
                                          )
                                      }
                                    </select>
                                    <label htmlFor="inputState">Plan a actualizar</label>
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    {this.state.errors.idPlanH && (
                                      <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.idPlanH}</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : ('')}
                      </div>
                      {!this.state.disableMin ? (
                        <div className="col-xl-5 col-lg-5 col-md-12 col-12">
                          <div className="row justify-content-end">
                            <div className="col-12 col-lg-11 m-b-2rem-planes-SA">
                              <div className="contentPlanAuto-planes-SA">
                                <span className="textPlanAuto-planes-SA">Baja:</span>
                              </div>
                            </div>
                          </div>

                          <div className="m-b-2rem-planes-SA">

                            <div className="row justify-content-end">
                              <div className="col-md-8 col-lg-11">
                                <div className="input-group input-group-addPlan-SA custom-fontSize">
                                  <input type="number" id="cantidadBV" name="cantidadBV" value={this.state.cantidadBV}
                                    onChange={event => this.setState({ cantidadBV: event.target.value })}
                                    className={this.state.errors.cantidadBV && ('input-errorLine')} disabled={this.state.disableStatusVisit} />
                                  <label>Cantidad</label>
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                  {this.state.errors.cantidadBV && (
                                    <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.cantidadBV}</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="m-b-2rem-planes-SA">
                            <div className="row justify-content-end">
                              <div className="col-md-8 col-lg-11">
                                <div className="input-group input-group-addPlan-SA custom-fontSize">
                                  <input type="number" id="cantidadBP" name="cantidadBP" value={this.state.cantidadBP}
                                    onChange={event => this.setState({ cantidadBP: event.target.value })}
                                    className={this.state.errors.cantidadBP && ('input-errorLine')} disabled={this.state.disableStatusPoint} />
                                  <label>Cantidad</label>
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                  {this.state.errors.cantidadBP && (
                                    <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.cantidadBP}</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="m-b-2rem-planes-SA">
                            <div className="row justify-content-end">
                              <div className="col-md-8 col-lg-11">
                                <div className="input-group input-group-addPlan-SA custom-fontSize">
                                  <input type="number" id="cantidadBC" name="cantidadBC" value={this.state.cantidadBC}
                                    onChange={event => this.setState({ cantidadBC: event.target.value })}
                                    className={this.state.errors.cantidadBC && ('input-errorLine')} disabled={this.state.disableStatusBuy} />
                                  <label>Cantidad</label>
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                  {this.state.errors.cantidadBC && (
                                    <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.cantidadBC}</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="m-b-2rem-planes-SA">
                            <div className="row justify-content-end">
                              <div className="col-md-8 col-lg-11">
                                <div className="input-group input-group-addPlan-SA custom-fontSize">
                                  <input type="number" id="rangoDiasB" name="rangoDiasB" value={this.state.rangoDiasB}
                                    onChange={event => this.setState({ rangoDiasB: event.target.value })}
                                    className={this.state.errors.rangoDiasB && ('input-errorLine')} disabled={this.state.disableStatus} />
                                  <label>Rango días</label>
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                  {this.state.errors.rangoDiasB && (
                                    <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.rangoDiasB}</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="m-t-3rem-planes-SA">
                            <div className="row justify-content-end">
                              <div className="col-md-8 col-lg-11">
                                <div className="input-group input-group-addPlan-SA custom-fontSize input-group-select-SA">
                                  <select id="inputState" value={defaultValueB || ''}
                                    onChange={event => this.setState({ idPlanB: event.target.value })}
                                    className={this.state.errors.idPlanB && ('input-errorLine')} disabled={this.state.disableStatus}>
                                    {
                                      this.props.planesAdminList ?
                                        (
                                          this.props.planesAdminList.length > -1 ?
                                            (
                                              this.props.planesAdminList.map((option, index) =>
                                                (<option key={index} value={option._id} >{option.name}</option>)
                                              )
                                            ) :
                                            (
                                              <option></option>
                                            )
                                        ) :
                                        (
                                          <option></option>
                                        )
                                    }
                                  </select>
                                  <label htmlFor="inputState">Plan a dregradar</label>
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                  {this.state.errors.idPlanB && (
                                    <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.idPlanB}</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      ) : ('')}
                    </div>
                  ) :
                  (
                    <div></div>
                  )
              }

            </form>
          </div>
          <div className="fixed-bottom bottom-planes-SA p-b-3-planes-SA p-t-2-planes-SA">
            <div className="row align-items-end justify-content-end">
              <div className="col-xl-5 col-lg-6 col-md-8">
                <button type="button" className="btn-secondaryReward btn-addPlanForm-SA m-r-5-planes-SA" onClick={this.volver}> Volver </button>
                {
                  this.state.actualizando ?
                    (
                      <button type="submit" form="formUpdatePlanes-SA" className="btn-primaryReward btn-addPlanForm-SA" disabled> Actualizar </button>
                    ) :
                    (
                      <button type="submit" form="formUpdatePlanes-SA" className="btn-primaryReward btn-addPlanForm-SA" disabled={!active}> Actualizar </button>
                    )
                }
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={5000} />
      </div>
    );
  }
}

FormActualizarPlanAdmin.propTypes = {
  history: PropTypes.object,
  planAdmin: PropTypes.object.isRequired,
  planesAdminList: PropTypes.array.isRequired,
  consultarPlanesAdminAction: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

FormActualizarPlanAdmin.defaultProps = {
  planesAdminList: []
};

const mapStateToProps = state => ({
  planesAdminList: consultPlanesAdmin(state)
});

export default withRouter(connect(mapStateToProps, { consultarPlanesAdminAction })(FormActualizarPlanAdmin));
