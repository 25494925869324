import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import {getPuntosGastadosAction} from './../actions/getPuntosGastadosAction';
import exportToExcel from './../../home/components/exportToExcel';

import { TiInfoOutline } from 'react-icons/ti';
import { IoIosCheckmarkCircle, IoMdInformationCircleOutline } from 'react-icons/io';
import { ReactComponent as ExportarIcon } from './../../resources/Exportar-01.svg';
import './../stylePointsMonthExcel.css';

class FormPuntosGastados extends Component {
  constructor(props){
    super(props);

    this.state = {
      firstDate: '',
      lastDate: '',
      errors: {},
      errorSubmit: '',
      buscando: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.isSubmitting = this.isSubmitting.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    let values = {
      firstDate: this.state.firstDate,
      lastDate: this.state.lastDate
    };

    this.validate(event, values);
  }

  validate(event, values) {
    let errors = {};

    if (!values.firstDate || values.firstDate==='' ) {
      errors.firstDate = 'Por favor, introduce la fecha inicial.';
    }

    if (!values.lastDate || values.lastDate==='' ) {
      errors.lastDate = 'Por favor, introduce la fecha final.';
    }

    this.setState({ errors });
    this.isSubmitting(event, errors, values);
  }

  isSubmitting(event, errors, values){
    event.preventDefault();
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    this.setState({
      buscando: true
    });
    if (Object.keys(errors).length === 0 ) {
      const token = JSON.parse(localStorage.getItem('token'));
      const idEmpresa = loginData._id;

      const data = {
        firstDate: new Date(values.firstDate),
        lastDate: new Date(values.lastDate),
      };

      return getPuntosGastadosAction(data, idEmpresa, token).then(r => {
        if (r.ok) {

          let data = r.data;
          let informacion = [];
          let objetoInfo = {
            Nombre: '',
            Puntos: 0,
            Numero_de_Tarjeta: '',
            Fecha_inicial: this.state.firstDate,
            Fecha_final: this.state.lastDate,
          };
          for ( let i = 0; data.length > i; i++ ) {

            objetoInfo.Nombre = data[ i ].name + ' ' + data[ i ].lastname;
            objetoInfo.Puntos = data[ i ].points;
            objetoInfo.Numero_de_Tarjeta = data[ i ].card_number;

            informacion.push(objetoInfo);
          }

          if(data.length > 0){
            exportToExcel(informacion, 'Puntos gastados de '+this.state.firstDate+' a '+this.state.lastDate);
            toast.success(<div><IoIosCheckmarkCircle className='icon-toast' />{'Reporte generado correctamente.'}</div>, {
              position: toast.POSITION.TOP_RIGHT
            });
          } else {
            toast.warn(<div><IoMdInformationCircleOutline className='icon-toast-inf-warn inf-warn-top' />
              {'No se encontro información en esas fechas'}</div>, {
              position: toast.POSITION.TOP_RIGHT
            });
          }


          this.setState({
            firstDate: '',
            lastDate: '',
            errors: {},
            errorSubmit: '',
            buscando: false,
          });
        } else
        if (!r.ok) {
          if(r.error){
            if(r.error === 401){
              this.props.history.push('/admin/logout/true');
            }
          } else if(r.error.message) {
            this.setState({
              errorSubmit: r.error.message,
              buscando: false
            });
          }
        }
      });
    }
    this.setState({
      buscando: false
    });


  }

  render() {
    let today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();
    if(dd<10){
      dd='0'+dd;
    }
    if(mm<10){
      mm='0'+mm;
    }

    today = yyyy+'-'+mm+'-'+dd;

    return (
      <div className="col-11 align-self-center">
        <form method="post" className="formPuntosGastados" id="formPuntosGastados" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-12 align-self-start">
              <div className="form-group">
                <div className="input-group input-group-pointsMonth-SA m-b-2rem-planes-SA">
                  <input type="date" id="firstDate" name="firstDate" value={this.state.firstDate}
                    onChange={event => this.setState({ firstDate: event.target.value })}
                    className={this.state.errors.firstDate && ('input-errorLine')}
                    max={today}
                  />
                  <label>De: </label>
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  {this.state.errors.firstDate && (
                    <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.firstDate}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-12 align-self-start">
              <div className="form-group">
                <div className="input-group input-group-pointsMonth-SA m-b-8-planes-SA">
                  <input type="date" id="lastDate" name="lastDate" value={this.state.lastDate}
                    onChange={event => this.setState({ lastDate: event.target.value })}
                    className={this.state.errors.lastDate && ('input-errorLine')}
                    max={today}/>
                  <label>A:</label>
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  {this.state.errors.lastDate && (
                    <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.lastDate}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-12 align-self-start">
              {
                this.state.buscando ?
                  <button
                    disabled
                    type="submit"
                    className='export-btn-pointsMonth'>
                    <p className='dp-inline '>Descargar Excel</p>
                    <ExportarIcon className='icon-nav inactive-icon-nav dp-inline margin-icon-exp' />
                  </button>
                  :
                  <button
                    type="submit"
                    className='export-btn-pointsMonth'>
                    <p className='dp-inline '>Descargar Excel</p>
                    <ExportarIcon className='icon-nav inactive-icon-nav dp-inline margin-icon-exp' />
                  </button>
              }

            </div>
          </div>
        </form>
        <ToastContainer autoClose={5000} />
      </div>
    );
  }
}

FormPuntosGastados.propTypes = {
  history: PropTypes.object,
};

export default withRouter(FormPuntosGastados);
