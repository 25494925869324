import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TiInfoOutline } from 'react-icons/ti';

class ErrorMessages extends Component {
  render() {
    let { errors, errorLogin } = this.props;

    return (
      <div>
        {
          ( Object.keys(errors).length > 0) ?
            (
              <div className="col-md-12 m-b-10-login mensajesError-login">
                {
                  (errors.email) ?
                    (
                      <p><TiInfoOutline className="iconError-login"></TiInfoOutline> {(errors.email)}</p>
                    ) :
                    (
                      <div></div>
                    )
                }
                {
                  (errors.username) ?
                    (
                      <p><TiInfoOutline className="iconError-login"></TiInfoOutline> {(errors.username)}</p>
                    ) :
                    (
                      <div></div>
                    )
                }
                {
                  (errors.password) ?
                    (
                      <p><TiInfoOutline className="iconError-login"></TiInfoOutline> {(errors.password)}</p>
                    ) :
                    (
                      <div></div>
                    )
                }
                {
                  (errors.nickEmpresa) ?
                    (
                      <p><TiInfoOutline className="iconError-login"></TiInfoOutline> {(errors.nickEmpresa)}</p>
                    ) :
                    (
                      <div></div>
                    )
                }
              </div>
            ) :
            (
              <div></div>
            )
        }
        {
          (errorLogin !== '') ?
            (
              (errorLogin !== undefined ) ?
                (
                  <div className="col-md-12 m-b-12-login mensajesError-login">
                    <p><TiInfoOutline className="iconError-login"></TiInfoOutline> {(errorLogin)}</p>
                  </div>
                ) :
                (<div></div>)
            ) :
            (
              <div></div>
            )
        }
      </div>
    );
  }
}

ErrorMessages.propTypes = {
  errors: PropTypes.object.isRequired,
  errorLogin: PropTypes.string,
};

export default ErrorMessages;
