export const listarUsersAdminService = (url, token) => () =>

  fetch(url + 'company', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': '*/*',
      'Authorization': token
    }
  }).then(function (response) {
    let status = response.status;
    let res = {ok: response.ok, error: status};
    if(status === 200){
      return response.json();
    } else if(status === 401){
      return res;
    }
  }).then(function (responser) {
    let data = [];
    if( !responser.ok ) {
      data.push(responser);
      return data;
    } else
    if( responser.ok ) {
      return responser.data;
    }
  }).catch((error) => {
    return error;
  });

