import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormLoginAdmin from './../components/formLoginAdmin';
import AvisoPrivacidad from './../../components/avisoPrivacidad';

import './../../components/login.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  IoMdInformationCircleOutline
} from 'react-icons/io';

class LoginContainerAdmin extends Component {

  constructor(props) {
    super(props);
    var classCycle = ['fondo1', 'fondo2', 'fondo3'];

    var randomNumber = Math.floor(Math.random() * classCycle.length);

    this.state = {
      classToAdd: classCycle[randomNumber]
    };

  }

  componentDidMount() {
    if (!localStorage.getItem('ok')) {
      if (this.props.match.params.tokenStatus === 'true') {
        this.notifySession();
        localStorage.setItem('ok', 'true');
      }
      if (this.props.match.params.policies === 'true') {
        this.notifyPolicies();
        localStorage.setItem('ok', 'true');
      }
    }
  }

  notifySession = () => {
    toast.warn(<div className='sess-not-toast'><div className='div-icon-toast-custm'><IoMdInformationCircleOutline className='icon-toast-inf-warn inf-warn-top' /></div> Tu sesi&oacute;n ha expirado</div>, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  notifyPolicies = () => {
    toast.warn(<div className='sess-not-toast toast-policies'><div className='div-icon-toast-custm'><IoMdInformationCircleOutline className='icon-toast-inf-warn inf-warn-top' /></div> Para poder usar NUBENTUM, debes aceptar nuestros terminos y condiciones</div>, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 8000
    });
  };

  render() {
    return (
      <div className={'login-content-' + this.state.classToAdd}>
        <ToastContainer autoClose={8000} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 m-t-3-login">
              <div className="logotipo-login m-l-10-login">
                <img src="/nube_nubentum2.png" alt="logotipo"></img>
              </div>
            </div>
          </div>
          <div className="row m-t-7-login justify-content-end">
            <div className="col-xl-4 col-lg-5 col-md-7">
              <div className="texto-form-login">
                Iniciar sesión
              </div>
              <div className="component-form-login m-t-3-login">
                <FormLoginAdmin></FormLoginAdmin>
              </div>
            </div>
          </div>
        </div>

        <AvisoPrivacidad></AvisoPrivacidad>

      </div>
    );
  }
}

LoginContainerAdmin.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tokenStatus: PropTypes.string,
      policies: PropTypes.string,
    })
  })
};
export default LoginContainerAdmin;
