import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';

import {eliminarUserAdminAction} from './../actions/eliminarUserAdminAction';

class ModalError extends Component {

  constructor(props) {
    super(props);

    this.eliminarUserAdmin = this.eliminarUserAdmin.bind(this);
  }

  eliminarUserAdmin(){
    const token = JSON.parse(localStorage.getItem('token'));

    return eliminarUserAdminAction(token, this.props.idAEliminar).then(r => {
      if (r.ok) {
        this.props.close();
        this.props.listarUsersAdminAction(token);
      } else
      if (!r.ok) {
        if(r.status) {
          if(r.status === 401)
            this.props.history.push('/admin/logout/true');
        }
      }
    });
  }

  render() {

    const modalStyle = {
      overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)'
      },
      modal: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        color: '#000000',
        boxShadow: '0 0 0 rgba(0, 0, 0, 0.25)',
        borderTop: '5px solid #950024',
        borderBottom: '5px solid #950024',
        textAlign: 'center'
      }
    };

    return (
      <Modal open={this.props.open} showCloseIcon={false} onClose={this.props.close} center styles={modalStyle}>
        <div className="container">
          <div className="content-ModalError-SA">
            {'¿Seguro que desea eliminar al usuario '}<br />
            <span className="parrafoName-users-SA">{'"' + this.props.nameAEliminar +'"'}</span>{'?'}
          </div>
          {
            this.props.acceptText ? (
              <div className="row justify-content-md-center m-t-15-users-SA">
                <div className="col-5">
                  <button onClick={this.props.onclickClose} className="btn-secondaryReward btn-modalError-SA">{this.props.closeText}</button>
                </div>
                <div className="col-5">
                  <button onClick={this.eliminarUserAdmin} className="btn-redReward btn-modalError-SA">{this.props.acceptText}</button>
                </div>
              </div>
            ) : ('')
          }
        </div>
      </Modal>
    );
  }
}

ModalError.propTypes = {
  nameAEliminar: PropTypes.string.isRequired,
  idAEliminar: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.any.isRequired,
  onclickClose: PropTypes.any.isRequired,
  acceptText: PropTypes.string,
  closeText: PropTypes.string,
  listarUsersAdminAction:PropTypes.func.isRequired,
  history: PropTypes.object,
};

export default withRouter(ModalError);
