import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import './style.css';
import { getMenuState } from './selector';
import { ReactComponent as Usersicon } from './resources/Usuarios_off-06.svg';
//import { ReactComponent as NewsIcon } from './resources/Noticias_off-08.svg';
import { ReactComponent as LogoutIcon } from './resources/Log out_off-10.svg';
import { ReactComponent as HomeIcon } from './resources/Inicio-12.svg';
import { ReactComponent as AdminsIcon } from './resources/Admin_off-17.svg';
import { ReactComponent as Optionsicon } from './resources/Opciones_off-19.svg';
import { ReactComponent as PlanesIcon } from './resources/Planes_off-08.svg';

import { CurrentUserIs } from './../../helpers/capabilitiesControl';

function optionHome(option) {
  if (option === 'home' || !option) {
    return 'activeIcon';
  }
  return 'inactiveIcon';
}
function optionAdmins(option) {
  if (option === 'admins') {
    return 'activeIcon';
  }
  return 'inactiveIcon';
}
function optionUsers(option) {
  if (option === 'users') {
    return 'activeIcon';
  }
  return 'inactiveIcon';
}
function optionOptions(option) {
  if (option === 'Options') {
    return 'activeIcon';
  }
  return 'inactiveIcon';
}
function optionPlanes(option) {
  if (option === 'planes') {
    return 'activeIcon';
  }
  return 'inactiveIcon';
}

function optionRootUser(option) {
  if (option === 'rootuser' ) {
    return 'activeIconRoot';
  }
  return 'inactiveIcon';
}
/*function optionRootNews(option) {
  if (option === 'news') {
    return 'activeIconRoot';
  }
  return 'inactiveIcon';
}*/

class SideBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      menuState: true,
      height: props.height,
      width: props.width,
    };
    this.ocultarMenu = this.ocultarMenu.bind(this);
    this.changeMenu = this.changeMenu.bind(this);
    this.menuSize = this.menuSize.bind(this);
  }
  componentDidMount() {
    this.setState({ height: window.innerHeight + 'px' });
    this.setState({ width: window.innerWidth + 'px' });
    this.setState({ menuState: !this.props.stateMenu[0] });
  }
  componentDidUpdate() {
    if (this.props.stateMenu[0] !== undefined) {
      if (this.state.menuState.toString() !== this.props.stateMenu[0].toString()) {
        this.setState({ menuState: this.props.stateMenu[0] });
      }
    }
  }
  changeMenu() {
    this.setState({ menuState: !this.state.menuState });
  }

  ocultarMenu() {
    this.setState({ menuState: false });
  }
  menuSize() {
    return 'sidebar-expanded d-none d-md-block box-shadow-r';

  }

  render() {
    const classNameDivs = this.state.menuState ? 'sidebar-expanded sidebar-show' : 'sidebar-expanded sidebar-hide';
    const classSize = this.state.menuState ? 'a-link ' : 'a-link-s ';
    const { option } = this.props;
    return (
      <div id='sidebar-container' className={'custom-sidebar ' + classNameDivs}>

        <ul className='list-group custom-list-group'>
          <li className='list-group-item text-muted d-flex no-border'> </li>
          <li className='list-group-item text-muted d-flex no-border'> </li>
          <li className='list-group-item text-muted d-flex no-border'> </li>

          {
            CurrentUserIs('root') ? (<div className='side-menu-w'>

              <div className={classSize + ' center'}>
                <Link to={'/admin/usuarios'} className='custom-link'>
                  <div className=''>
                    <Usersicon className={'icon ' + optionRootUser(option)} />
                    <span className='d-block'>Usuarios</span>
                  </div>
                </Link>
              </div>
              <li className='list-group-item text-muted d-flex no-border'> </li>

              {/*<div className={classSize + ' center'}>
                <Link to='/' className='custom-link'>
                  <div className=''>
                    <NewsIcon className={'icon ' + optionRootNews(option)} />
                    <span className='d-block'>Noticias</span>
                  </div>
                </Link>
                </div>
              <li className='list-group-item text-muted d-flex no-border'> </li>*/}
            </div>) : ('')
          }

          {
            CurrentUserIs('admin') ? (
              <div className='side-menu-w'>
                <div className={classSize + ' center'}>
                  <Link to='/admin/home' className='custom-link'>
                    <div className=''>
                      <HomeIcon className={'icon ' + optionHome(option)} />
                      <span className='d-block'>Home</span>
                    </div>
                  </Link>
                </div>
                <li className='list-group-item text-muted d-flex no-border'> </li>

                <div className={classSize + ' center'}>
                  <Link to='/admin/list_users' className='custom-link'>
                    <div className=''>
                      <AdminsIcon className={'icon ' + optionAdmins(option)} />
                      <span className='d-block'>Admins</span>
                    </div>
                  </Link>
                </div>
                <li className='list-group-item text-muted d-flex no-border'> </li>

                <div className={classSize + ' center'}>
                  <Link to='/admin/list_customers' className='custom-link'>
                    <div className=''>
                      <Usersicon className={'icon ' + optionUsers(option)} />
                      <span className='d-block'>Usuarios</span>
                    </div>
                  </Link>
                </div>
                <li className='list-group-item text-muted d-flex no-border'> </li>

                <div className={classSize + ' center'}>
                  <Link to='/admin/planes' className='custom-link'>
                    <div className=''>
                      <PlanesIcon className={'icon ' + optionPlanes(option)} />
                      <span className='d-block'>Planes</span>
                    </div>
                  </Link>
                </div>
                <li className='list-group-item text-muted d-flex no-border'> </li>

                <div className={classSize + ' center'}>
                  <Link to='/admin/profile' className='custom-link'>
                    <div className=''>
                      <Optionsicon className={'icon ' + optionOptions(option)} />
                      <span className='d-block'>Opciones</span>
                    </div>
                  </Link>
                </div>
                <li className='list-group-item text-muted d-flex no-border'> </li>
              </div>) : ('')
          }
          <div className={classSize + ' center'}>
            <Link to='/admin/logout/' className='custom-link'>
              <div className=''>
                <LogoutIcon className='icon' />
                <span className='d-block'>Log out</span>
              </div>
            </Link>
          </div>
          <li className='list-group-item text-muted d-flex no-border'> </li>

        </ul>
      </div>
    );
  }
}

SideBar.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  option: PropTypes.string,
  stateMenu: PropTypes.array.isRequired,
  history: PropTypes.object,
};

SideBar.defaultProps = {
  height: '500px',
  width: '500px',
  stateMenu: [false],
};
const mapStateToProps = state => ({
  stateMenu: getMenuState(state)
});

export default withRouter(connect(mapStateToProps)(SideBar));
