import {
  URL_API,
  LIST_CUSTOMERS_ADMIN,
  DELETE_CUSTOMER
} from './../../../constants';
import {
  getCustomers,
  deleteCustomerService
} from './../services';
import { createAction } from 'redux-actions';

export const listCustomersAction = createAction(LIST_CUSTOMERS_ADMIN,
  (token, id) => getCustomers(URL_API, token, id)());

export const deleteCustomerAction = createAction(DELETE_CUSTOMER,
  (token, idCustom) => deleteCustomerService(URL_API, token, idCustom)());
