import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {listarUsersAdminAction} from '../actions/listarUsersAdminAction';
import Nav from '../../menu/navBar';
import SideBar from '../../menu/sideBar';
import TablaUsersAdmin from '../components/tablaUsersAdmin';
import {listUsersAdmin} from './../selectors';


import './usersAdmin.css';

class ListarUsersAdminContainer extends Component {

  constructor(props){
    super(props);

    this.state = {
      search: ''
    };

    this.nuevoUser = this.nuevoUser.bind(this);

  }

  componentDidMount() {
    const token = JSON.parse(localStorage.getItem('token'));
    this.props.listarUsersAdminAction(token).then(
      response => {
        if (response.payload[0].error === 401) {
          this.props.history.push('/admin/logout/true');
        }
      });
  }

  nuevoUser() {
    this.props.history.push('usuarios/nuevo');
  }

  render() {
    let usersAdminList = [];

    if(this.props.usersAdminList.length > 0){
      if(this.props.usersAdminList[0].error === 401) {
        this.props.history.push('/admin/logout/true');
      } else {
        usersAdminList = this.props.usersAdminList;
      }
    }

    var demoData = {
      thead: [
        'Usuarios maximos', 'Nombre', 'Acciones'
      ],
      tbody: [
        usersAdminList
      ]
    };

    return (
      <div>
        <Nav />
        <div className='row custom-row' id='body-row'>
          <SideBar option={'rootuser'}/>
        </div>
        <div className='col-11 float-right'>
          <div className="row">
            <div className="col-11">
              <div className="row justify-content-between m-t-5-users-SA">
                <div className="col-md-5 content-buttonAddUsers-SA">
                  <button type="button" className="btn-primaryReward btn-addPlan-SA" onClick={this.nuevoUser}>+ Nuevo usuario</button>
                </div>
                <div className='col-md-5 col-sm-12'>
                  <div className='row'>
                    <div className='d-inline div-search'>
                      <input type="text" id="search" name="search" placeholder="Buscar" value={this.state.search || ''}
                        onChange={event => this.setState({ search: event.target.value })}
                        className='input-form input-search input-search-SA' />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                    </div>
                    <svg role="img" title="icon1" className="icon-search-planes-SA iconBuscar-planes-SA">
                      <use href="/assets/tablas/icons8-search.svg#Capa_1"/>
                    </svg>
                  </div>
                </div>
              </div>
              <hr className="linea-users-SA"></hr>
              <div className='row'>
                {
                  Object.keys(this.props.usersAdminList).length > 0 ?
                    (
                      <TablaUsersAdmin demoData={demoData} listarUsersAdminAction={this.props.listarUsersAdminAction} search={this.state.search}></TablaUsersAdmin>
                    ) :
                    (
                      <div></div>
                    )
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListarUsersAdminContainer.propTypes = {
  history: PropTypes.object,
  usersAdminList: PropTypes.array.isRequired,
  listarUsersAdminAction: PropTypes.func.isRequired,
};


ListarUsersAdminContainer.defaultProps = {
  usersAdminList: []
};

const mapStateToProps = state => ({
  usersAdminList: listUsersAdmin(state)
});

export default withRouter(connect(mapStateToProps, { listarUsersAdminAction })(ListarUsersAdminContainer));
