import React, { PureComponent } from 'react';
import ButtonPrimary from './globalComponents/buttons/containers/primary/buttonPrimary';
import ButtonSecondary from './globalComponents/buttons/containers/secondary/buttonSecondary';
import ButtonRed from './globalComponents/buttons/containers/red/buttonRed';
import TableRender from './globalComponents/table';
import { CurrentUserCan } from './helpers/capabilitiesControl';
import ModalCustom from './globalComponents/modal/modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  IoIosCheckmarkCircle,
  IoIosCloseCircle,
  IoMdInformationCircleOutline
} from 'react-icons/io';


export default class democomponent extends PureComponent {

  constructor(props) {
    super(props);
    this.state = { open: false, iconmodal: false };
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);

  }
  onOpenModal() {
    this.setState({ open: true });
  }

  onCloseModal() {
    this.setState({ open: false });
  }


  notify = () => {
    //toast("Default Notification !");
    let texto = <div><IoIosCheckmarkCircle className='icon-toast' /> Success Notification !</div>;

    toast.success(texto, {
      position: toast.POSITION.TOP_RIGHT,
    });

    toast.error(<div><IoIosCloseCircle className='icon-toast' /> Error Notification !</div>, {
      position: toast.POSITION.TOP_RIGHT
    });

    toast.warn(<div><div className='div-icon-toast-custm'><IoMdInformationCircleOutline className='icon-toast-inf-warn'/></div> Warning Notification !</div>, {
      position: toast.POSITION.TOP_RIGHT
    });

    toast.info(<div><div className='div-icon-toast-custm'><IoMdInformationCircleOutline className='icon-toast-inf-inf'/></div> Info Notification !</div>, {
      position: toast.POSITION.TOP_RIGHT
    });

  };

  render() {

    var demoData = {
      thead: [
        'NAME', 'EMAIL', 'PHONE'
      ],
      tbody: [
        ['dato 1', 'dato 2', 'dato 3'],
        ['dato 4', 'dato 5', 'dato 6'],
        ['dato 7', 'dato 8', 'dato 9']
      ]
    };

    return (
      <div>

        <TableRender data={demoData} />
        {CurrentUserCan('MANAGE') ? ('puede gestionar') : ('sin perminsos suficientes')}
        <br></br>
        {CurrentUserCan('EDIT') ? ('puede editar') : ('sin perminsos suficientes')}
        <br></br>
        <button onClick={this.onOpenModal} className="btn-secondaryReward">Open modal</button>

        <ModalCustom
          open={this.state.open}
          close={this.onCloseModal}
          tittle={'Modal simple'}
          contentModal={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pulvinar risus non risus hendrerit venenatis. Pellentesque sit amet hendrerit risus, sed porttitor quam.'}
          onclickClose={this.onCloseModal}
          onclickAccept={this.onCloseModal}
          closeText={'Cancelar'}
          acceptText={'Eliminar'}
        />
        <div className="container">
          <br></br>
          <div className="row">
            <ButtonPrimary title={'Prueba'}></ButtonPrimary>
            <ButtonSecondary title={'Prueba'}></ButtonSecondary>
            <ButtonRed title={'Prueba'}></ButtonRed>
          </div>
          <br></br>
          <button onClick={this.notify}>Notify !</button>
          <ToastContainer autoClose={5000} />

          <br></br>
        </div>
      </div>
    );
  }
}
/*
//rafc
import React from 'react'

export const democomponent = () => {
  return (
    <div>

    </div>
  )
}
//rafce
import React from 'react'

const democomponent = () => {
  return (
    <div>

    </div>
  )
}
//rafcp
export default democomponent

import React from 'react'
import PropTypes from 'prop-types'

const democomponent = props => {
  return (
    <div>

    </div>
  )
}

democomponent.propTypes = {

}

export default democomponent

//rcc 2
import React, { Component } from 'react';

class democomponent extends Component {
  render() {
    return (
      <div>

      </div>
    );
  }
}

export default democomponent;

//rccp 2
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class democomponent extends Component {
  render() {
    return (
      <div>

      </div>
    );
  }
}

democomponent.propTypes = {

};

export default democomponent;

//rce
import React, { Component } from 'react'

export class democomponent extends Component {
  render() {
    return (
      <div>

      </div>
    )
  }
}

export default democomponent

//recp
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class democomponent extends Component {
  static propTypes = {

  }

  render() {
    return (
      <div>

      </div>
    )
  }
}

export default democomponent

//rcfc
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class democomponent extends Component {
  constructor(props) {
    super(props);

  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {

  }

  shouldComponentUpdate(nextProps, nextState) {

  }

  componentWillUpdate(nextProps, nextState) {

  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {

  }

  render() {
    return (
      <div>

      </div>
    );
  }
}

democomponent.propTypes = {

};

export default democomponent;

//rconst
constructor(props) {
  super(props)

  this.state = {

  }
}


//rcreduxp
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export class democomponent extends Component {
  static propTypes = {
    prop: PropTypes
  }

  render() {
    return (
      <div>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(democomponent)

//reduxmap
const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

//ren 2
render() {
  return (
    <div>

    </div>
  );
}

//rfc
import React from 'react'

export default function democomponent() {
  return (
    <div>

    </div>
  )
}

//rfce
import React from 'react'

function democomponent() {
  return (
    <div>

    </div>
  )
}

export default democomponent

//rfcp
import React from 'react'
import PropTypes from 'prop-types'

function democomponent(props) {
  return (
    <div>

    </div>
  )
}

democomponent.propTypes = {

}

export default democomponent

//rmcp
import React, { memo } from 'react'
import PropTypes from 'prop-types'

const democomponent = memo(function democomponent(props) {
  return (
    <div>

    </div>
  )
})

democomponent.propTypes = {

}

export default democomponent

//rnce
import React, { Component } from 'react'
import { Text, View } from 'react-native'

export class democomponent extends Component {
  render() {
    return (
      <View>
        <Text> textInComponent </Text>
      </View>
    )
  }
}

export default democomponent

//rpc
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class democomponent extends PureComponent {
  render() {
    return (
      <div>

      </div>
    );
  }
}

democomponent.propTypes = {

};

export default democomponent;

//rscpm
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const democomponent = memo((props) => {
  return (
    <div>

    </div>
  );
});

democomponent.propTypes = {

};

export default democomponent;

//rxconst
export const constantName = 'constantName'

//rxselect
import { createSelector } from 'reselect'

export const selectorName = state => state.selector
*/
