import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { TiInfoOutline } from 'react-icons/ti';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/*
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries
} from 'react-vis';
*/
import './../style.css';

import { getPointsAction, clearPointsAction } from './../actions';
import { getPointsState } from './../selectors';
import equal from 'fast-deep-equal';

class checkCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: '',
      cardvalue: '',
      showData: false,
      name: 'pr',
      aviablePoints: '1',
      expire: '*Vencen el 2 de mayo del 2020'
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.asignar = this.asignar.bind(this);
  }
  componentDidMount() {
    localStorage.removeItem('dataP');
    localStorage.removeItem('dataR');
    localStorage.removeItem('infoD');
  }
  handleSubmit(event) {
    event.preventDefault();
    this.props.clearPointsAction();
    localStorage.removeItem('dataP');
    localStorage.removeItem('dataR');
    localStorage.removeItem('infoD');
    this.props.getPointsAction(this.state.cardvalue).then(resp => {
      if (resp.payload[0]) {
        if (resp.payload[0]._id) {
          this.setState({
            error: '',
            showData: true
          });
        } else {
          this.setState({
            error: 'No se encontraron datos'
          });
        }
      } else {
        this.setState({
          error: 'No se encontraron datos o hubo un error de conexion'
        });
      }

    });
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.pointsState, prevProps.pointsState)) {
      if (this.props.pointsState[0]) {
        this.asignar(this.props.pointsState);
      }
    }
  }
  asignar(data) {
    this.setState({
      name: data[0].name,
      aviablePoints: data[0].available_points
    });
    let infoD = {
      name: data[0].name,
      available_points: data[0].available_points,
      card_number: data[0].card_number
    };
    localStorage.setItem('dataP', JSON.stringify(data[0].points));
    localStorage.setItem('dataR', JSON.stringify(data[0].redems));
    localStorage.setItem('infoD', JSON.stringify(infoD));

  }

  render() {

    return (
      <div className='custom-absolute'>
        <div className='container-fluid font-style'>
          {this.state.showData > 0 ? (
            <div className='row'>
              <div className='col-12   height-cont-up'>
                <h1 className='title-p color-2e396a '><img className='logo-img' src='/nube_nubentum2.png' alt='Nubentum'></img></h1>
              </div>
              <div className='col-12  height-cont'>
                <div className='middle adjust-md'>
                  <div className='col'>
                    <div className='col-12'>
                      <h2 className='w-m '>Bienvenido(a), {this.state.name}</h2>
                    </div>
                    <div className='col-12'>
                      <h4 className='w-m2'>Cuentas con:</h4>
                    </div>
                    <div className='col-12 padding-b-10p'>
                      <h1 className='color-2e396a'>{this.state.aviablePoints} puntos*</h1>
                      <abbr className='color-006e95'>{this.state.expire}</abbr>
                    </div>
                    <div className='col-12'>
                      <Link to='/points/hystory' className='w-m2 h-l'>Ver historial &gt;</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12  height-cont-down background-foot-p'></div>
            </div>

          ) : (
            <div className='row '>
              <div className='col-12 height-up'>
                <h1 className='title-points'><img className='logo-img' src='/nube_nubentum2.png' alt='Nubentum'></img></h1>
              </div>
              <div className='col-12 height-down back-color-p content-form'>
                <div className='col '>
                  <h4 className='sub-title-points'>Consulta tus puntos</h4>
                  <div className='form-points'>
                    <div className='custom-space-row'></div>
                    <div className='custom-space-row'></div>
                    <div className='custom-space-row'></div>
                    {this.state.error ? (
                      <span className="span-errorMessage span-error-m-p"><TiInfoOutline></TiInfoOutline><p className='error-label-text text-sp'>{this.state.error}</p></span>
                    ) : ('')
                    }
                    <form onSubmit={this.handleSubmit}>
                      <input type="text"
                        className={' input-p-2 '}
                        value={this.state.cardvalue}
                        onChange={event => this.setState({ cardvalue: event.target.value })}
                        name="tarjeta" pattern='{4,16}'
                        placeholder='0000 0000 0000 0000' required></input>
                      <label className={(this.state.error && ('error-mov-lavel')) + ' custom-label-card label-card-n no-error-mov-lavel'}>Ingresa el numero de tarjeta</label>
                      <button type='submit' className='btn-primaryReward btn-custom-size float-right btn-point  back-btn'> Continuar &#32; &#32; &#62;</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>)
          }
        </div>
      </div>
    );
  }
}

checkCard.propTypes = {
  getPointsAction: PropTypes.func.isRequired,
  clearPointsAction: PropTypes.func.isRequired,
  pointsState: PropTypes.object.isRequired,
};

checkCard.defaultProps = {
  pointsState: {}
};

const mapStateToProps = state => ({
  pointsState: getPointsState(state)
});
export default withRouter(connect(mapStateToProps, { getPointsAction, clearPointsAction })(checkCard));
