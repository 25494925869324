/* eslint-disable no-dupe-else-if */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';

import FormDemo from './globalComponents/form/containers';
import LoginContainerAdmin from './login/loginAdmin/containers';
//import LoginContainerUser from './login/loginUser/containers';
//import FormLoginUserEmpresaContainer from './login/loginUser/containers/formLoginUserEmpresaContainer';
import ResetPasswordContainer from './login/resetPassword/containers';
import ResetPasswordTokenContainer from './login/resetPassword/containers/resetPasswordTokenContainer';

import HomeContainerAdmin from './management/home/containers';
import createUser from './management/createUserEmp/container';
import listUsers from './management/listUserEmp/container';
import democomponent from './democomponent';
import editUser from './management/editUserEmp/container';
import SeeUser from './management/seeUserEmp/container';

import ListarUsersAdminContainer from './management/usersAdmin/containers/listarUsersAdminContainer';
import AgregarUserAdminContainer from './management/usersAdmin/containers/agregarUserAdminContainer';
import EditarUserAdminContainer from './management/usersAdmin/containers/editarUserAdminContainer';
import VerUserAdminContainer from './management/usersAdmin/containers/verUserAdminContainer';
import ListarPlanesAdminContainer from './management/planesAdmin/containers/listarPlanesAdminContainer';
import AgregaPlanAdminContainer from './management/planesAdmin/containers/agregaPlanAdminContainer';
import ActualizarPlanAdminContainer from './management/planesAdmin/containers/actualizarPlanAdminContainer';
import VerPlanAdminContainer from './management/planesAdmin/containers/verPlanAdminContainer';

import 'bootstrap/dist/css/bootstrap.min.css';

import checkCard from './checkPoints/content/checkCard';
import checkPoints from './checkPoints/content/checkPoints';
import ListCustomers from './management/listCustomers/container';
import AddCustomer from './management/addCustomer/container';
import EditCustomer from './management/editCustomer/container';
import SeeCustomer from './management/seeCustomer/container';
import logoutCont from './management/logout/container';
import profileAdminEdit from './management/adminProfile/container';
import {
  PrivateRouteRoot, // only ROOt
  PrivateRouteAdmin, // only ADMIN
  PrivateRouteManagement, // for ROOT or ADMIN
  //PrivateRouteUser, // only user
  //PrivateRoute // only need session
} from './helpers/accessControl';

function App() {

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={LoginContainerAdmin} />
          {
            /*
            <Route exact path="/login" component={LoginContainerUser} />
            <Route exact path="/login/empresa/:username" component={FormLoginUserEmpresaContainer} />
            */
          }
          <Route exact path="/login/admin/:tokenStatus?/:policies?" component={LoginContainerAdmin} />
          <Route exact path="/reset-password" component={ResetPasswordContainer} />
          <Route exact path="/reset/:token" component={ResetPasswordTokenContainer} />
          <Route path="/admin/logout/:tokenStatus?/:policies?" component={logoutCont} />

          <Route exact path="/demo-form" component={FormDemo} />
          <Route exact path="/demo" component={democomponent} />
          <Route exact path="/points" component={checkCard} />
          <Route exact path="/puntos" component={checkCard} />
          <Route exact path="/points/hystory" component={checkPoints} />

          <PrivateRouteManagement exact path="/admin/home" component={HomeContainerAdmin} />

          <PrivateRouteRoot exact path="/admin/usuarios" component={ListarUsersAdminContainer} />
          <PrivateRouteRoot exact path="/admin/usuarios/nuevo" component={AgregarUserAdminContainer} />
          <PrivateRouteRoot exact path="/admin/usuarios/edit/:userName" component={EditarUserAdminContainer} />
          <PrivateRouteRoot exact path="/admin/usuarios/see/:userName" component={VerUserAdminContainer} />

          <PrivateRouteAdmin exact path="/admin/profile" component={profileAdminEdit} />
          <PrivateRouteAdmin exact path="/admin/add_user" component={createUser} />
          <PrivateRouteAdmin exact path="/admin/list_users" component={listUsers} />
          <PrivateRouteAdmin exact path="/admin/edit_user/:userId" component={editUser} />
          <PrivateRouteAdmin exact path="/admin/SeeUser/:userId" component={SeeUser} />
          <PrivateRouteAdmin exact path="/admin/list_customers" component={ListCustomers} />
          <PrivateRouteAdmin exact path="/admin/add_customers" component={AddCustomer} />
          <PrivateRouteAdmin exact path="/admin/edit_customer/:customId" component={EditCustomer} />
          <PrivateRouteAdmin exact path="/admin/customer/:customId" component={SeeCustomer} />
          <PrivateRouteAdmin exact path="/admin/planes" component={ListarPlanesAdminContainer} />
          <PrivateRouteAdmin exact path="/admin/planes/nuevo" component={AgregaPlanAdminContainer} />
          <PrivateRouteAdmin exact path="/admin/planes/edit/:idPlan" component={ActualizarPlanAdminContainer} />
          <PrivateRouteAdmin exact path="/admin/planes/see/:idPlan" component={VerPlanAdminContainer} />

          <Route component={HomeContainerAdmin} />

        </Switch>
      </Router>
    </div>
  );

}

export default App;
