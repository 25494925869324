import {
  URL_API,
  LIST_USERS,
  DELETE_USER,
} from './../../../constants';
import {
  getUserByIDADmin,
  deleteUserService
} from './../services';
import { createAction } from 'redux-actions';

export const listUsersAction = createAction(LIST_USERS,
  (token, id) => getUserByIDADmin(URL_API, token, id)());

export const deleteUserAction = createAction(DELETE_USER,
  (token, idAdmin, idUser) => deleteUserService(URL_API, token, idAdmin, idUser)());
