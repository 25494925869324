import { handleActions } from 'redux-actions';
import { GET_STATISTICS, GET_STATISTICS_PRODUCTS, GET_STATISTICS_PERHOUR } from './../constants';

export const getPStatisticsState = handleActions({
  [GET_STATISTICS]: (state, action) => {
    return { ...action.payload };
  },
}, {});

export const getPStatisticsProductsState = handleActions({
  [GET_STATISTICS_PRODUCTS]: (state, action) => {
    return { ...action.payload };
  },
}, {});

export const getStatisticsPerHourState = handleActions({
  [GET_STATISTICS_PERHOUR]: (state, action) => {
    return { ...action.payload };
  },
}, {});
