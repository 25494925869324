
export const getPoinstsServices = (url, cardNumber) => () =>
  fetch(url + 'customers/statement/' + cardNumber, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  }).then(function (response) {
    return response.json();
  }).then(function (responser) {
    if (responser.ok) {
      return responser.data;
    }
    let error=[];
    error.push(responser);
    return error;
  }).catch((error) => {
    return error;
  });
export function clearPoinstsServices() {
  return {};
}
