import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Nav from '../../menu/navBar';
import SideBar from '../../menu/sideBar';
import FormNewPlanAdmin from './../components/formNewPlanAdmin';

import './planesAdmin.css';

class AgregaPlanAdminContainer extends Component {
  render() {
    return (
      <div>
        <Nav />
        <div className='row custom-row' id='body-row'>
          <SideBar option={'planes'}/>
        </div>
        <div className='col-11 float-right'>
          <div className="row">
            <div className="col-11">
              <div className="row justify-content-start m-t-5-planes-SA">
                <div className="col-md-12 content-planes-SA">
                  <span>
                    <Link to='/admin/planes' className='link-planes-SA'>Planes</Link>
                    { ' / ' }
                    <Link to='/admin/planes/nuevo' className='link-planes-SA'>Nuevo Plan</Link>
                  </span>
                </div>
              </div>
              <div className="row justify-content-start m-t-2-planes-SA">
                <div className="col-md-12 content-planes-SA">
                  <span className="text-nuevoPlan-SA">
                    Ingresa los datos del nuevo plan que deseas ofrecer:
                  </span>
                </div>
              </div>
            </div>
          </div>
          <FormNewPlanAdmin></FormNewPlanAdmin>
        </div>
      </div>
    );
  }
}

AgregaPlanAdminContainer.propTypes = {
  history: PropTypes.object,
};

export default withRouter(AgregaPlanAdminContainer);
