import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import { resetPasswordAction } from './../actions/resetPasswordAction';
import ErrorMessages from './../../components/errorMessages';

import 'react-toastify/dist/ReactToastify.css';

class FormCorreoRecuperacion extends Component {

  constructor(props){
    super(props);

    this.state = {
      email: '',
      errors: {},
      errorLogin: '',
      isSubmitting: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.isSubmitting = this.isSubmitting.bind(this);
  }

  handleSubmit(event){
    event.preventDefault();

    let values = {
      email: this.state.email
    };
    this.validate(event, values);
  }

  validate(event, values) {
    let errors = {};

    if (!values.email || values.email==='') {
      errors.email = 'Por favor, introduce tu correo.';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Lo sentimos, correo inválido. Por favor intenta nuevamente';
    }


    this.setState({ errors });
    this.isSubmitting(event, errors, values);
  }

  isSubmitting(event, errors, values){
    event.preventDefault();

    if (Object.keys(errors).length === 0 ) {
      this.setState({
        isSubmitting: true
      });
      return resetPasswordAction(values).then(r => {
        if (r.ok) {

          toast.warn(<div><IoIosCheckmarkCircle className='icon-toast' />&nbsp;&nbsp;&nbsp;&nbsp;{'Correo enviado. Por favor, revisa tu correo electrónico.'}</div>, {
            position: toast.POSITION.TOP_RIGHT
          });

          this.setState({
            errorLogin: ''
          });
        } else
        if (!r.ok) {
          this.setState({
            errorLogin: r.message
          });
        }
      });
    }

  }

  render() {
    return (
      <div className="content-form-login">
        <form id="formCorreoRecuperacion"  method="post" onSubmit={this.handleSubmit}>
          <div className="row">
            <ErrorMessages errors={this.state.errors} errorLogin={this.state.errorLogin}></ErrorMessages>
            <div className="col-md-12">
              <div className="form-froup">
                <div className="input-group mb-3">
                  <input type="email" id="email" name="email" placeholder="correo eléctronico" value={this.state.email}
                    onChange={event => this.setState({ email: event.target.value })}
                    className={this.state.errors.email && ('input-errorLine')}/>
                  <label>Por favor, ingresa tu correo de recuperación</label>
                  <div className="input-group-append-login">
                    <img src="/assets/login/Usuario_on-01.svg" className="iconUser-login" alt="iconUser"></img>
                  </div>
                  <span className="highlight"></span>
                  <span className="bar"></span>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="row m-t-10-login justify-content-end">
          <div className="col-md-7 col-12 m-b-7-login">
            {(!this.state.isSubmitting) ? (
              <button type="submit" form="formCorreoRecuperacion" className="btn-primaryReward" >{'Recuperar >'}</button>
            ) : (
              <div></div>
            )}

          </div>
        </div>
        <ToastContainer autoClose={10000}/>
      </div>
    );
  }
}

FormCorreoRecuperacion.propTypes = {

};

export default FormCorreoRecuperacion;
