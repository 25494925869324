
export const getCustomerService = (url, token, idCustomer) => () =>
  fetch(url + 'customers/' + idCustomer, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': token
    }
  }).then(function (response) {
    return response.json();
  }).then(function (responser) {
    if (responser.ok) {
      return responser.data;
    }
    const datos = [];
    datos.push(responser.error);
    return datos;
  }).catch((error) => {
    return error;
  });

export function clearCustomerService() {
  return {};
}
