import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import FormPuntosGastados from './../components/formPuntosGastados';

import './../stylePointsMonthExcel.css';

class PuntosGastadosContainer extends Component {
  render() {
    return (
      <div className="row justify-content-md-center">
        <div className="col-11 align-self-center span-pointsMonth-SA">
          <span >Puntos gastados</span>
        </div>
        <FormPuntosGastados></FormPuntosGastados>
      </div>
    );
  }
}

PuntosGastadosContainer.propTypes = {

};

export default withRouter(PuntosGastadosContainer);
