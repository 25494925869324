import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './style.css';
import { ReactComponent as MenuIcon } from './resources/menu.svg';
import { ReactComponent as Usericon } from './resources/Usuario_on-01.svg';
import { ReactComponent as NotificIcon } from './resources/Notificaciones_on-15.svg';
import { ReactComponent as HomeIcon } from './resources/Inicio-12.svg';
import { changeMenuState } from './action';
import { getMenuState } from './selector';
import { CurrentUserIs } from './../../helpers/capabilitiesControl';

class Nav extends Component {
  constructor() {
    super();
    this.state = {
      menuState: true,
      showMenu: false,
      showNotif: false,
      error: null,
    };
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.showNotif = this.showNotif.bind(this);
    this.closeNotif = this.closeNotif.bind(this);
    this.activeOptionPerfil = this.activeOptionPerfil.bind(this);
    this.changeMenu = this.changeMenu.bind(this);
    this.toProfile = this.toProfile.bind(this);
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    if (!this.dropdownMenu.contains(event.target)) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.closeMenu);
      });
    }
  }
  showNotif(event) {
    event.preventDefault();
    this.setState({ showNotif: true }, () => {
      document.addEventListener('click', this.closeNotif);
    });
  }

  closeNotif(event) {
    event.preventDefault();
    try {
      if (!this.dropdownNotif.contains(event.target)) {
        this.setState({ showNotif: false }, () => {
          document.removeEventListener('click', this.closeNotif);
        });
      }
    } catch (error) {
      this.setState({error: error});
    }
  }

  activeOptionNotif() {
    if (this.state.showNotif) {
      return 'nav-active active-icon-nav';
    }
    return '';
  }
  activeOptionPerfil() {
    if (this.state.showMenu) {
      return 'nav-active active-icon-nav';
    }
    return '';
  }

  changeMenu() {
    this.props.changeMenuState(this.state.menuState);
    this.setState({ menuState: !this.state.menuState });
  }
  toProfile() {
    this.props.history.push('/admin/profile');
  }

  render() {
    return (
      <nav className='navbar navbar-expand-lg navbar-expand-sm custom-nav fixed-top z-index-nav'>
        <div className='collapse navbar-collapse'>
          <ul className='navbar-nav mr-auto'>
            <li className='nav-item li-menu custom-icon-menu-li' onClick={this.changeMenu}>
              <MenuIcon className={'icon-nav icon-menu'} />
            </li>
            <li className='nav-item text-muted li-muted'></li>
            {
              CurrentUserIs('root') ?
                (<li className='nav-item active'>
                  <Link className='nav-link btn-nav link-home' to='/admin/home'><HomeIcon className='nav-link dp-inline icon-nav-home' /><p className='dp-inline icon-nav-home-text'>Home</p></Link>
                </li>) : ('')
            }

          </ul>
          {
            CurrentUserIs('admin') ? (
              <ul className='navbar-nav ml-auto nav-custom-h'>
                <li className='nav-item dropdown custom-menu-down '>
                  <button className={'nav-link btn-nav ' + this.activeOptionNotif()} /*onClick={this.showNotif}*/>
                    <NotificIcon className={'icon-nav inactive-icon-nav' + this.activeOptionNotif()} />
                  </button>
                  {
                    this.state.showNotif ? (
                      <div className='dropdown-menu nav-item dropdown show custom-menu-down-options' aria-labelledby='navbarDropdown'
                        ref={(element) => {
                          this.dropdownNotif = element;
                        }}>
                        <a className='dropdown-item item-option' href='/#'>notif</a>
                        <a className='dropdown-item item-option' href='//'>Another action</a>
                        <a className='dropdown-item item-option' href='//'>Something else here</a>
                      </div>
                    ) : (
                      <div className='dropdown-menu custom-menu-down-options' aria-labelledby='navbarDropdown'>
                        <a className='dropdown-item item-option' href='/#'>Action</a>
                        <a className='dropdown-item item-option' href='//'>Another action</a>
                        <a className='dropdown-item item-option' href='//'>Something else here</a>
                      </div>
                    )
                  }
                </li>
                <li className='nav-item dropdown custom-menu-down'>
                  <button className={'nav-link btn-nav ' + this.activeOptionPerfil()} onClick={this.toProfile}>
                    <Usericon className={'icon-nav inactive-icon-nav ' + this.activeOptionPerfil()} />
                  </button>

                </li>
                <li className='nav-item text-muted li-muted'></li>
              </ul>

            ):(

              CurrentUserIs('root') ?
                (
                  <ul className='navbar-nav ml-auto nav-custom-h'>
                    <li className='nav-item dropdown custom-menu-down '>
                      <button className={'nav-link btn-nav ' + this.activeOptionNotif()}>
                        <NotificIcon className={'icon-nav inactive-icon-nav' + this.activeOptionNotif()} />
                      </button>
                    </li>
                    <li className='nav-item dropdown custom-menu-down'>
                      <button className={'nav-link btn-nav ' + this.activeOptionPerfil()} >
                        <Usericon className={'icon-nav inactive-icon-nav ' + this.activeOptionPerfil()} />
                      </button>
                    </li>
                    <li className='nav-item text-muted li-muted'></li>
                  </ul>
                ) :
                (
                  <ul className='navbar-nav ml-auto nav-custom-h'>
                    <li className='nav-item dropdown custom-menu-down '>
                      <button className={'nav-link btn-nav ' + this.activeOptionNotif()} /*onClick={this.showNotif}*/>
                        <NotificIcon className={'icon-nav inactive-icon-nav' + this.activeOptionNotif()} />
                      </button>
                      {
                        this.state.showNotif ? (
                          <div className='dropdown-menu nav-item dropdown show custom-menu-down-options' aria-labelledby='navbarDropdown'
                            ref={(element) => {
                              this.dropdownNotif = element;
                            }}>
                            <a className='dropdown-item item-option' href='/#'>notif</a>
                            <a className='dropdown-item item-option' href='//'>Another action</a>
                            <a className='dropdown-item item-option' href='//'>Something else here</a>
                          </div>
                        ) : (
                          <div className='dropdown-menu custom-menu-down-options' aria-labelledby='navbarDropdown'>
                            <a className='dropdown-item item-option' href='/#'>Action</a>
                            <a className='dropdown-item item-option' href='//'>Another action</a>
                            <a className='dropdown-item item-option' href='//'>Something else here</a>
                          </div>
                        )
                      }
                    </li>
                    <li className='nav-item dropdown custom-menu-down'>
                      <button className={'nav-link btn-nav ' + this.activeOptionPerfil()} >
                        <Usericon className={'icon-nav inactive-icon-nav ' + this.activeOptionPerfil()} />
                      </button>

                    </li>
                    <li className='nav-item text-muted li-muted'></li>
                  </ul>
                )
            )
          }
        </div>
      </nav>
    );
  }
}

Nav.propTypes = {
  changeMenuState: PropTypes.func.isRequired,
  stateMenu: PropTypes.any,
  history: PropTypes.object,
};

Nav.defaultProps = {
  data: [],
  height: '500px',
  width: '500px',
};

const mapStateToProps = state => ({
  stateMenu: getMenuState(state)
});

export default withRouter(connect(mapStateToProps, { changeMenuState })(Nav));
