import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
//import PropTypes from 'prop-types';
import Nav from './../../menu/navBar';
import SideBar from './../../menu/sideBar';
import './../style.css';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
//Col3, Col9
import { TiInfoOutline } from 'react-icons/ti';
import { addNewUser } from './../actions';
//import ModalCustom from './../../../globalComponents/modal/modal';
import { CurrentUserIsActive } from './../../../helpers/capabilitiesControl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  IoIosCheckmarkCircle,
  IoIosCloseCircle
} from 'react-icons/io';

class createUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errors: '',
      message: 'message',
      modal: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.isSubmitting = this.isSubmitting.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  openModal() {
    this.setState({ modal: true });
  }
  closeModal() {
    this.setState({ modal: false });
  }
  handleSubmit(event) {
    event.preventDefault();

    let values = {
      username: this.state.username,
      password: this.state.password
    };

    this.validate(event, values);
  }

  validate(event, values) {
    let errors = {};
    if (!values.username || values.username === '') {
      errors.username = 'Por favor, introduce tu usuario.';
    }

    if (!values.password || values.password === '') {
      errors.password = 'Por favor, introduce tu contraseña';
    }

    this.setState({ errors });
    this.isSubmitting(event, errors, values);
  }

  isSubmitting(event, errors, values) {
    event.preventDefault();

    if (Object.keys(errors).length === 0) {
      const loginData = JSON.parse(localStorage.getItem('loginData'));
      const token = JSON.parse(localStorage.getItem('token'));

      return addNewUser(token, values, loginData._id).then(r => {

        if (r.code === 404) {
          let errors = {};
          errors.add = 'Lo sentimos, ah ocurrido un error al registrar los datos';
          this.setState({
            errorLogin: errors,
            message: 'Lo sentimos, ah ocurrido un error al registrar los datos'
          });
          this.notifyError();

        } else if (r.ok) {
          //this.openModal();
          this.setState({
            errors: '',
            username: '',
            password: ''
          });
          this.notifySuccess();

        } else if (r.errors) {
          let errors = {};
          errors.add = 'Error al registrar, verifique los datos';
          this.setState({
            errors: errors,
            message: 'Error al registrar, verifique los datos'
          });
          this.notifyError();

        } else if (r.error.name === 'TokenExpiredError') {
          // eslint-disable-next-line react/prop-types
          this.props.history.push('/admin/logout/true');
        } else if (r.error) {
          let errors = {};
          errors.add = r.error.name;
          this.setState({
            errors: errors,
            message: r.error.name
          });
          this.notifyError();

        }
      }).catch(error => {
        if (error.name === 'TokenExpiredError') {
          // eslint-disable-next-line react/prop-types
          this.props.history.push('/admin/logout/true');
        }
      });
    }
  }

  notifySuccess = () => {
    toast.success(<div><IoIosCheckmarkCircle className='icon-toast' />Usuario agregado correctamente.</div>, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  notifyError = () => {
    toast.error(<div><IoIosCloseCircle className='icon-toast' />{this.state.message}</div>, {
      position: toast.POSITION.TOP_RIGHT
    });
  };


  render() {

    return (
      <div>
        <Nav />
        <div className='row custom-row' id='body-row'>
          <SideBar option='admins' />
        </div>
        <div className='col-11 float-right max-w-body'>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='row'>
            <p className=' position-link'><Link to='/admin/list_users' className='position-link'>Usuarios</Link> / <Link to='/admin/add_user' className='position-link'>Nuevo Usuario</Link></p>
          </div>
          <div className='row'>
            <p className='info-text'>Ingresa los datos del nuevo usuario para crearlo</p>
          </div>

          <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} />

          <Container fluid>
            <div className='row'>
              <div className='col-11'>
                <form className="formReward formReward-add" id="formAddDemo" onSubmit={this.handleSubmit}>
                  <Form.Row>
                    <Col>
                      {this.state.errors.add && (
                        <span className="span-errorMessage error-message-custom"><TiInfoOutline></TiInfoOutline> {this.state.errors.add}</span>
                      )}
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xl length='6'>
                      <Form.Group className="group margin-l-40p">
                        <input type="text" id="empresa" name="empresa" value={this.state.username || ''}
                          onChange={event =>
                            this.setState({ username: event.target.value })}
                          className={(this.state.errors.username && ('input-errorLine')) + ' input-form'}
                          minLength="3" maxLength="40" pattern="[A-Za-z0-9]+" />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Nombre de Usuario</Form.Label>
                        {this.state.errors.username && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.username}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl length='6'>
                      <Form.Group className="group margin-l-40p">
                        <input type="password" id="contrasena" name="contrasena" value={this.state.password || ''}
                          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                          onChange={event =>
                            this.setState({ password: event.target.value })}
                          className={(this.state.errors.password && ('input-errorLine')) + ' input-form'} />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Contraseña</Form.Label>
                        {this.state.errors.password && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.password}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col></Col>
                  </Form.Row>
                  <Form.Row>
                    <Col></Col>
                    <Col xl length='6'><p className='text-justify margin-l-40p'>La contraseña debe tener minimo 8 caracteres, al menos un dígito y al menos una mayúscula, se aceptan caracteres especiales.</p></Col>
                    <Col></Col>
                  </Form.Row>

                  <Form.Row className="m-t-4rem">
                    <Col lg={7} xs={7} md={4} sm={2}></Col>
                    {CurrentUserIsActive() ? (
                      <Col>
                        <div className=''>
                          <div className='fixed-bottom bottom-planes-SA p-b-3-planes-SA p-t-2-planes-SA'>
                            <div className='row align-items-end justify-content-end'>
                              <div className='col-xl-5 col-lg-6 col-md-8'>
                                <Col className='d-inline'>
                                  <Link to='/admin/list_users' className='btn-secondaryReward btn-custom-size back-btn'>Volver</Link>
                                </Col>
                                <Col className='d-inline'>
                                  <button className='btn-primaryReward btn-custom-size' type='submit'>Añadir</button>
                                </Col>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ) : ('')}

                  </Form.Row>
                </form>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

createUser.propTypes = {
};
export default withRouter(createUser);
