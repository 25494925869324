import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ModalError from './modalError';

class TablaUsersAdmin extends Component {

  constructor(props) {
    super(props);

    this.state={
      eliminado: false,
      idAEliminar: '',
      nameAEliminar: ''
    };

    this.eliminarUser = this.eliminarUser.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.editarUser = this.editarUser.bind(this);
    this.verUser = this.verUser.bind(this);
  }

  eliminarUser(id, name){
    this.setState({
      eliminado: true,
      idAEliminar: id,
      nameAEliminar: name
    });
  }

  onCloseModal(){
    this.setState({
      eliminado: false
    });
  }

  editarUser(username){
    this.props.history.push('/admin/usuarios/edit/'+username);
  }

  verUser(username){
    this.props.history.push('/admin/usuarios/see/'+username);
  }

  render() {
    let {demoData, search} = this.props;
    return (
      <div className='col-12'>
        <div className="scroll-table m-b-10-users-SA">
          <table className="tabla-users-SA">
            <thead className="tablaHead-users-SA">
              <tr className="tr-head">
                {
                  demoData ? (
                    demoData.thead.length > -1 ? (
                      demoData.thead.map((th, index) =>
                        <th key={index + 'th-table'}>{th}</th>
                      )
                    ) : (<th>no data</th>)
                  ) : ('')
                }
              </tr>
            </thead>
            <tbody className="tablaBody-users-SA">
              {
                demoData ? (
                  demoData.tbody.length > -1 ? (
                    demoData.tbody[0].sort((x, y)=>(x.active.active === y.active.active)? 0 : x.active.active? -1 : 1).map((tr, index) =>
                      search ?
                        (
                          (tr.name.toLowerCase().includes(search.toLowerCase()) || tr.max_users.toLowerCase().includes(search.toLowerCase())) ?
                            (
                              tr.active.active ?
                                (
                                  <tr key={index + 'th-table'}>
                                    <td key={'td1-'+tr._id}>{tr.max_users}</td>
                                    <td key={'td2-'+tr._id}>{tr.name}</td>
                                    <td key={'td3-'+tr._id}>
                                      <img src="/assets/tablas/ver.svg" className="iconTabla-users-SA" alt="iconVer" onClick={this.verUser.bind(this, tr.username)}/>
                                      <img src="/assets/tablas/editar.svg" className="iconTabla-users-SA" alt="iconVer" onClick={this.editarUser.bind(this, tr.username)}/>
                                      <img src="/assets/tablas/eliminar.svg" className="iconTabla-users-SA" alt="iconVer" onClick={this.eliminarUser.bind(this, tr._id, tr.name)}/>
                                    </td>
                                  </tr>
                                ):
                                (
                                  <tr key={index + 'th-table'} className="tr-inactive-SA">
                                    <td key={'td1-'+tr._id}>{tr.max_users}</td>
                                    <td key={'td2-'+tr._id}>{tr.name}</td>
                                    <td key={'td3-'+tr._id}>
                                      <img src="/assets/tablas/ver.svg" className="iconTabla-users-SA" alt="iconVer"/>
                                      <img src="/assets/tablas/editar.svg" className="iconTabla-users-SA" alt="iconVer"/>
                                      <img src="/assets/tablas/eliminar.svg" className="iconTabla-users-SA" alt="iconVer"/>
                                    </td>
                                  </tr>
                                )
                            ):
                            (
                              <tr key={index + 'th-table'}></tr>
                            )
                        ) :
                        (
                          tr.active.active ?
                            (
                              <tr key={index + 'th-table'}>
                                <td key={'td1-'+tr._id}>{tr.max_users}</td>
                                <td key={'td2-'+tr._id}>{tr.name}</td>
                                <td key={'td3-'+tr._id}>
                                  <img src="/assets/tablas/ver.svg" className="iconTabla-users-SA" alt="iconVer" onClick={this.verUser.bind(this, tr.username)}/>
                                  <img src="/assets/tablas/editar.svg" className="iconTabla-users-SA" alt="iconVer" onClick={this.editarUser.bind(this, tr.username)}/>
                                  <img src="/assets/tablas/eliminar.svg" className="iconTabla-users-SA" alt="iconVer" onClick={this.eliminarUser.bind(this, tr._id, tr.name)}/>
                                </td>
                              </tr>
                            ):
                            (
                              <tr key={index + 'th-table'} className="tr-inactive-SA">
                                <td key={'td1-'+tr._id}>{tr.max_users}</td>
                                <td key={'td2-'+tr._id}>{tr.name}</td>
                                <td key={'td3-'+tr._id}>
                                  <img src="/assets/tablas/ver.svg" className="iconTabla-users-SA" alt="iconVer"/>
                                  <img src="/assets/tablas/editar.svg" className="iconTabla-users-SA" alt="iconVer"/>
                                  <img src="/assets/tablas/eliminar.svg" className="iconTabla-users-SA" alt="iconVer"/>
                                </td>
                              </tr>
                            )
                        )
                    )
                  ) : (<tr>no existen datos</tr>)
                ) : ('')
              }
            </tbody>
          </table>
        </div>

        <ModalError
          open={this.state.eliminado}
          close={this.onCloseModal}
          onclickClose={this.onCloseModal}
          acceptText={'Aceptar'}
          closeText={'Cancelar'}
          idAEliminar={this.state.idAEliminar}
          nameAEliminar={this.state.nameAEliminar}
          listarUsersAdminAction={this.props.listarUsersAdminAction}
        />

      </div>
    );
  }
}

TablaUsersAdmin.propTypes = {
  history: PropTypes.object,
  demoData: PropTypes.object.isRequired,
  listarUsersAdminAction: PropTypes.func.isRequired,
  search: PropTypes.string
};

export default withRouter(TablaUsersAdmin);
