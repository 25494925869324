import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Nav from '../../menu/navBar';
import SideBar from '../../menu/sideBar';
import FormActualizarPlanAdmin from './../components/formActualizarPlanAdmin';
import { getPlanAdminAction } from './../actions/getPlanAdminAction';
import { getPlanAdmin } from './../selectors';

class ActualizarPlanAdminContainer extends Component {

  componentDidMount() {
    const data = {
      idPlan: this.props.match.params.idPlan
    };
    const token = JSON.parse(localStorage.getItem('token'));
    this.props.getPlanAdminAction(data.idPlan, token).then(
      response => {
        if (response.payload[0].error === 401) {
          this.props.history.push('/admin/logout/true');
        }
      });
  }

  render() {

    const loginData = JSON.parse(localStorage.getItem('loginData'));
    let active = loginData.active.active;
    const idPlan = this.props.match.params.idPlan;
    const planAdmin = this.props.planAdmin;
    let planAdminActual = {};
    if(planAdmin.length > 0) {
      for(let i = 0; planAdmin.length > i; i++){
        if(planAdmin[i]._id === idPlan) {
          planAdminActual = planAdmin[i];
        }
      }
    }

    return (
      <div>
        <Nav />
        <div className='row custom-row' id='body-row'>
          <SideBar option={'planes'} stateMenu={[false]}/>
        </div>
        <div className='col-11 float-right'>
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-start m-t-5-planes-SA">
                <div className="col-md-12 content-planes-SA">
                  <span>
                    <Link to='/admin/planes' className='link-planes-SA'>Planes</Link>
                    { ' / ' }
                    <Link to={'/admin/planes/edit/'+idPlan} className='link-planes-SA'>Editar Plan</Link>
                  </span>
                </div>
              </div>
              <div className="row justify-content-start m-t-2-planes-SA">
                <div className="col-md-12 content-planes-SA">
                  <span className="text-nuevoPlan-SA">
                    Aquí puedes hacer los ajustes que desees a tu plan.
                  </span>
                </div>
              </div>
            </div>
          </div>
          {
            Object.keys(planAdminActual).length > 0 ?
              (
                <FormActualizarPlanAdmin planAdmin={planAdminActual} active={active}></FormActualizarPlanAdmin>
              ) :
              (
                <div></div>
              )
          }
        </div>
      </div>
    );
  }
}

ActualizarPlanAdminContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      idPlan: PropTypes.string.isRequired,
    })
  }),
  planAdmin: PropTypes.array.isRequired,
  getPlanAdminAction: PropTypes.func.isRequired,
  history: PropTypes.object,
};

ActualizarPlanAdminContainer.defaultProps = {
  planAdmin: []
};

const mapStateToProps = state => ({
  planAdmin: getPlanAdmin(state)
});

export default withRouter(connect(mapStateToProps, { getPlanAdminAction })(ActualizarPlanAdminContainer));
