import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';

import { deleteCustomerAction, listCustomersAction } from './../actions';
import { getCustomersList } from './../selectors';

//import ModalCustom from '../../../globalComponents/modal/modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  IoIosCheckmarkCircle,
  IoIosCloseCircle
} from 'react-icons/io';

class ModalError extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalTitle: '',
      modalContent: '',
      modalAcceptText: '',
      modal: false,
    };
    this.eliminarUserAdmin = this.eliminarUserAdmin.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }
  openModal() {
    this.setState({ modal: true });
  }
  onCloseModal() {
    this.setState({
      modalDel: false
    });
  }
  closeModal() {
    this.setState({
      modal: false
    });
  }
  eliminarUserAdmin() {
    const token = JSON.parse(localStorage.getItem('token'));
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    this.props.close();

    this.props.deleteCustomerAction(token, this.props.idAEliminar).then(
      (resp) => {
        if (resp.payload[0]) {
          if (resp.payload[0].ok) {
            this.setState({
              modalContent: 'El usuario se ha eliminado correctamente'
            });
            this.notifySuccess();

            this.props.listCustomersAction(token, loginData._id);
          } else if (resp.payload[0].error.name === 'TokenExpiredError') {
            // eslint-disable-next-line react/prop-types
            this.props.history.push('/admin/logout/true');
          } else {
            this.setState({
              modalContent: 'Error al eliminar, verifique su conexion'
            });
            this.notifyError();

          }
        } else {
          this.setState({
            modalContent: 'Error al eliminar, verifique su conexion'
          });
          this.notifyError();

        }
      }
    ).catch(error => {
      if (error.name === 'TokenExpiredError') {
        // eslint-disable-next-line react/prop-types
        this.props.history.push('/admin/logout/true');
      }
    });

  }

  notifySuccess = () => {
    toast.success(<div><IoIosCheckmarkCircle className='icon-toast' />&nbsp;&nbsp;&nbsp;&nbsp;{this.state.modalContent}</div>, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  notifyError = () => {
    toast.error(<div><IoIosCloseCircle className='icon-toast' />&nbsp;&nbsp;&nbsp;&nbsp;{this.state.modalContent}</div>, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  render() {

    const modalStyle = {
      overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)'
      },
      modal: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        color: '#000000',
        boxShadow: '0 0 0 rgba(0, 0, 0, 0.25)',
        borderTop: '5px solid #950024',
        borderBottom: '5px solid #950024',
        textAlign: 'center'
      }
    };

    return (
      <div>
        <ToastContainer autoClose={5000} />

        <Modal open={this.props.open} showCloseIcon={false} onClose={this.props.close} center styles={modalStyle}>
          <div className="container">
            <div className="content-ModalError-SA">
              {'¿Seguro que desea eliminarlo?'}<br />
              {/*
            <span className="parrafoName-users-SA">{'"' + this.props.nameAEliminar + '"'}</span>{'?'}
            */}
            </div>
            {
              this.props.acceptText ? (
                <div className="row justify-content-md-center m-t-15-users-SA">
                  <div className="col-5 space-btn-r">
                    <button onClick={this.props.onclickClose} className="btn-secondaryReward btn-modalError-SA">{this.props.closeText}</button>
                  </div>
                  <div className="col-5 space-btn-l">
                    <button onClick={this.eliminarUserAdmin} className="btn-redReward btn-modalError-SA">{this.props.acceptText}</button>
                  </div>
                </div>
              ) : ('')
            }
          </div>
        </Modal>
      </div>
    );
  }
}

ModalError.propTypes = {
  nameAEliminar: PropTypes.string.isRequired,
  idAEliminar: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.any.isRequired,
  onclickClose: PropTypes.any.isRequired,
  acceptText: PropTypes.string,
  closeText: PropTypes.string,
  history: PropTypes.object,
  deleteCustomerAction: PropTypes.func.isRequired,
  listCustomersAction: PropTypes.func.isRequired,
};

ModalError.defaultProps = {
  customersListrRefresh: []
};

const mapStateToProps = state => ({
  customersListrRefresh: getCustomersList(state)
});

export default withRouter(
  connect(
    mapStateToProps, {
      listCustomersAction,
      deleteCustomerAction
    })(ModalError));
