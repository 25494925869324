import {
  URL_API,
  GET_CUSTOMER,
} from '../../../constants';
import {
  getCustomerService,
  clearCustomerService
} from '../services';
import { createAction } from 'redux-actions';

export const getCustomerAction = createAction(GET_CUSTOMER,
  (token, idCustomer) => getCustomerService(URL_API, token, idCustomer)());

export const clearCustomerAction = createAction(GET_CUSTOMER,
  () => clearCustomerService());

