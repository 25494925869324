import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { actualizarUserAdminAction } from './../actions/actualizarUserAdminAction';
import { ToastContainer, toast } from 'react-toastify';
import { TiInfoOutline } from 'react-icons/ti';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import 'react-toastify/dist/ReactToastify.css';

class FormEditUsersAdmin extends Component {

  constructor(props){

    super(props);

    this.state = {
      idEmpresa: props.userAdmin._id,
      email: props.userAdmin.email,
      username: props.userAdmin.username,
      name: props.userAdmin.name,
      maxUsers: props.userAdmin.max_users,
      errors: {},
      errorSubmit: {},
      actualizando: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.isSubmitting = this.isSubmitting.bind(this);
    this.volver = this.volver.bind(this);

  }

  handleSubmit(event){
    event.preventDefault();

    let values = {
      email: this.state.email,
      username: this.state.username,
      name: this.state.name,
      max_users: this.state.maxUsers,
    };

    this.validate(event, values);
  }

  validate(event, values) {
    let errors = {};
    if (!values.email || values.email==='') {
      errors.email = 'Por favor, introduce tu correo.';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Lo sentimos, correo inválido.';
    }

    if (!values.username || values.username==='' ) {
      errors.username = 'Por favor, introduce el nombre de usuario que tendrá la empresa.';
    }

    if (!values.name || values.name==='' ) {
      errors.name = 'Por favor, introduce el nombre de la empresa.';
    }

    if (!values.max_users || values.max_users==='' ) {
      errors.max_users = 'Por favor, introduce la cantidad máxima de usuarios.';
    } else if (values.max_users < 1) {
      errors.max_users = 'Por favor, introduce numeros mayores que 0.';
    }

    this.setState({ errors });
    this.isSubmitting(event, errors, values);
  }

  isSubmitting(event, errors, values){
    event.preventDefault();
    this.setState({
      actualizando: true
    });

    if (Object.keys(errors).length === 0 ) {
      const token = JSON.parse(localStorage.getItem('token'));
      return actualizarUserAdminAction(values, this.state.idEmpresa, token).then(r => {
        if(r.ok){
          if(r.data.ok){
            toast.success(<div><IoIosCheckmarkCircle className='icon-toast' />{'Usuario actualizado correctamente'}</div>, {
              position: toast.POSITION.TOP_RIGHT
            });
            this.setState({
              actualizando: false
            });
          } else
          if( ! r.data.ok){
            if(Object.keys(r.data).length === 1) {
              if( r.data.message ) {
                this.setState({
                  actualizando: false,
                  errors: {
                    email: 'Este correo ya existe para otro usuario.'
                  }
                });
              }
              if( r.data.message.errors.username ) {
                this.setState({
                  actualizando: false,
                  errors: {
                    username: 'Este nickname ya existe para otro usuario.'
                  }
                });
              }
            } else {
              this.setState({
                actualizando: false
              });
            }
          }
        } else if(r.status) {
          if(r.status === 401)
            this.props.history.push('/admin/logout/true');
        }
      });
    }

  }

  volver(){
    this.props.history.push('/admin/usuarios');
  }

  render() {
    return (
      <div className="row">
        <div className="col-11">
          <div className="m-t-5-users-SA">
            <form method="post" className="formEditUsers-SA" id="formEditUsers-SA" onSubmit={this.handleSubmit}>

              <div className="row justify-content-start">
                <div className="col-xl-4 col-lg-5 col-sm-6">
                  <div className="form-froup">
                    <div className="input-group input-group-editUser-SA m-b-15-users-SA">
                      <input type="email" id="email" name="email" value={this.state.email}
                        onChange={event => this.setState({ email: event.target.value })}
                        className={this.state.errors.email && ('input-errorLine')}/>
                      <label>Correo electrónico</label>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      {this.state.errors.email && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.email}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-sm-6">
                  <div className="form-froup">
                    <div className="input-group input-group-editUser-SA m-b-15-users-SA">
                      <input type="text" id="name" name="name" value={this.state.name}
                        onChange={event => this.setState({ name: event.target.value })}
                        className={this.state.errors.name && ('input-errorLine')}/>
                      <label>Nombre de la empresa</label>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      {this.state.errors.name && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.name}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-start">
                <div className="col-xl-4 col-lg-5 col-sm-6">
                  <div className="form-froup">
                    <div className="input-group input-group-editUser-SA m-b-15-users-SA">
                      <input type="text" id="username" name="username" value={this.state.username}
                        onChange={event => this.setState({ username: event.target.value })}
                        className={this.state.errors.username && ('input-errorLine')}/>
                      <label>Nickname de la empresa</label>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      {this.state.errors.username && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.username}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-sm-6">
                  <div className="form-froup">
                    <div className="input-group input-group-editUser-SA m-b-15-users-SA">
                      <input type="number" id="maxUsers" name="maxUsers" value={this.state.maxUsers}
                        onChange={event => this.setState({ maxUsers: event.target.value })}
                        className={this.state.errors.max_users && ('input-errorLine')}/>
                      <label>Usuarios máximos</label>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      {this.state.errors.max_users && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.max_users}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-sm-6">
                  <div className="form-froup">
                    <div className="input-group input-group-editUser-SA m-b-15-users-SA">
                      <input type="password" id="password" name="password" disabled={true} value={'demoPass'}/>
                      <label>Contraseña</label>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div className="fixed-bottom bottom-users-SA p-b-3-users-SA p-t-2-users-SA">
              <div className="row align-items-end justify-content-end">
                <div className="col-xl-5 col-lg-6 col-md-8">
                  <button type="button" onClick={this.volver} className="btn-secondaryReward btn-editUserForm-SA m-r-5-users-SA"> Volver </button>
                  {
                    this.state.actualizando ?
                      (
                        <button type="submit" form="formEditUsers-SA" className="btn-primaryReward btn-editUserForm-SA" disabled> Actualizar </button>
                      ) :
                      (
                        <button type="submit" form="formEditUsers-SA" className="btn-primaryReward btn-editUserForm-SA"> Actualizar </button>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
          <ToastContainer autoClose={5000}/>
        </div>
      </div>
    );
  }
}

FormEditUsersAdmin.propTypes = {
  userAdmin: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withRouter(FormEditUsersAdmin);
