import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { loginAdminAction } from './../actions/loginAdminAction';
import ErrorMessages from './../../components/errorMessages';

import './../../../globalComponents/buttons/containers/primary/buttonPrimary.css';

class FormLoginAdmin extends Component {

  constructor(props){
    super(props);

    this.state = {
      email: '',
      password: '',
      errors: {},
      errorLogin: '',
      visibleHelp: false
    };

    this.isSubmitting = this.isSubmitting.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.ayuda = this.ayuda.bind(this);
    this.cerrar = this.cerrar.bind(this);
    this.olvidePassword = this.olvidePassword.bind(this);
  }

  isSubmitting(event, errors, values){
    event.preventDefault();

    if (Object.keys(errors).length === 0 ) {
      return loginAdminAction(values).then(r => {
        if(r.code === 400) {
          this.setState({
            errorLogin: r.message
          });
        } else
        if (r.ok) {
          localStorage.setItem('loginData', JSON.stringify(r.data));
          localStorage.setItem('token', JSON.stringify(r.token));
          this.setState({
            errorLogin: ''
          });
          this.props.history.push('/admin/home');
        } else
        if (!r.ok) {
          this.setState({
            errorLogin: r.message
          });
        }
      });
    }

  }

  handleSubmit(event){
    event.preventDefault();

    let values = {
      email: this.state.email,
      password: this.state.password
    };
    this.validate(event, values);
  }

  validate(event, values) {
    let errors = {};
    if (!values.email || values.email==='') {
      errors.email = 'Por favor, introduce tu correo.';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Lo sentimos, correo inválido. Por favor intenta nuevamente';
    }

    if (!values.password || values.password==='' ) {
      errors.password = 'Por favor, introduce tu contraseña';
    }

    this.setState({ errors });
    this.isSubmitting(event, errors, values);
  }

  ayuda() {
    this.setState({
      visibleHelp: true
    });
  }

  cerrar() {
    this.setState({
      visibleHelp: false
    });
  }

  olvidePassword() {
    // eslint-disable-next-line react/prop-types
    this.props.history.push('/reset-password');
  }

  render() {
    return (
      <div className="content-form-login">
        <form id="formLoginAdmin"  method="post" onSubmit={this.handleSubmit}>
          <div className="row">
            <ErrorMessages errors={this.state.errors} errorLogin={this.state.errorLogin}></ErrorMessages>
            <div className="col-md-12">
              <div className="form-froup">
                <div className="input-group mb-3">
                  <input type="email" id="email" name="email" placeholder="correo eléctronico" value={this.state.email}
                    onChange={event => this.setState({ email: event.target.value })}
                    className={this.state.errors.email && ('input-errorLine')}/>
                  <label>Ingresa tu correo electrónico</label>
                  <div className="input-group-append-login">
                    <img src="/assets/login/Usuario_on-01.svg" className="iconUser-login" alt="iconUser"></img>
                  </div>
                  <span className="highlight"></span>
                  <span className="bar"></span>
                </div>
              </div>
            </div>
            <div className="col-md-12 m-t-5-login">
              <div className="form-froup">
                <div className="input-group mb-3">
                  <input type="password" id="password" name="password" placeholder="contraseña" value={this.state.password}
                    onChange={event => this.setState({ password: event.target.value })}
                    className={this.state.errors.password && ('input-errorLine')}/>
                  <label>Ingresa tu contraseña</label>
                  <div className="input-group-append-login">
                    <img src="/assets/login/Contrasena_on-03.svg" className="iconUser-login" alt="iconPass"></img>
                  </div>
                  <span className="highlight"></span>
                  <span className="bar"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-md-12 olvidePass-login" onClick={this.olvidePassword}>
              Olvide mi contraseña
            </div>
          </div>
        </form>
        <div className="row m-t-10-login">
          <div className="col-md-5 col-12 txtAyuda-login">
            <span onClick={this.ayuda}>Ayuda</span>
          </div>
          <div className="col-md-7 col-12 m-b-7-login">
            <button type="submit" form="formLoginAdmin" className="btn-primaryReward">{'Continuar >'}</button>
          </div>
        </div>
        {
          ( this.state.visibleHelp ) ?
            (
              <div className="row justify-content-end">
                <div className="col-md-12 contentTxtAyuda-login">
                  <span>
                    Si olvidaste tu contraseña, favor de dar click en el enlace de arriba,
                    si continuas con problemas favor de enviar un correo a <strong>hola@girasolo.com</strong>
                  </span>
                </div>
                <div className="col-md-1 contentTxtAyuda-cerrar-login">
                  <p onClick={this.cerrar}>X</p>
                </div>
              </div>
            ) :
            (
              <div></div>
            )
        }
      </div>
    );
  }
}

FormLoginAdmin.propTypes = {
  history: PropTypes.object,
};

export default withRouter(FormLoginAdmin);
