import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Nav from './../../menu/navBar';
import SideBar from './../../menu/sideBar';
import './../style.css';
import { listMembershipsAction } from './../../addCustomer/actions';
import { getCustomerAction, clearCustomerAction } from '../actions';
import { getMembershipsList } from './../../addCustomer/selectors';
import { getCustomerState } from './../selectors';

import FormCustomer from './../components/formCustomer';

class EditCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: ''
    };

  }

  componentDidMount() {
    this.props.clearCustomerAction();
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const token = JSON.parse(localStorage.getItem('token'));
    this.props.listMembershipsAction(token, loginData._id).then(
      response=> {
        if (response.payload[0].name === 'TokenExpiredError') {
          // eslint-disable-next-line react/prop-types
          this.props.history.push('/admin/logout/true');
        }
      }
    ).catch(error => {

      if (error.name === 'TokenExpiredError') {
      // eslint-disable-next-line react/prop-types
        this.props.history.push('/admin/logout/true');
      }
    });
    this.props.getCustomerAction(token, this.props.match.params.customId).then(
      response=>{
        if (response.payload[0].name === 'TokenExpiredError') {
          // eslint-disable-next-line react/prop-types
          this.props.history.push('/admin/logout/true');
        }
      }
    ).catch(error => {

      if (error.name === 'TokenExpiredError') {
      // eslint-disable-next-line react/prop-types
        this.props.history.push('/admin/logout/true');
      }
    });
  }

  render() {

    return (
      <div>
        <Nav />
        <div className='row custom-row' id='body-row'>
          <SideBar option='users' />
        </div>
        <FormCustomer
          customerInfo={this.props.customerInfo}
          memberships={this.props.memberships}
          customId={this.props.match.params.customId} />
      </div>
    );
  }
}

EditCustomer.propTypes = {
  listMembershipsAction: PropTypes.func.isRequired,
  getCustomerAction: PropTypes.func.isRequired,
  clearCustomerAction: PropTypes.func.isRequired,
  memberships: PropTypes.array.isRequired,
  customerInfo: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      customId: PropTypes.string.isRequired,
    })
  })
};
EditCustomer.defaultProps = {
  memberships: [],
  customerInfo: {}
};

const mapStateToProps = state => ({
  memberships: getMembershipsList(state),
  customerInfo: getCustomerState(state)
});
export default withRouter(connect(mapStateToProps,
  {
    listMembershipsAction,
    getCustomerAction,
    clearCustomerAction
  })(EditCustomer));
