/* eslint-disable no-dupe-else-if */
export default function validate(values) {
  let errors = {};
  //console.log(values);
  if (!values.lastName || values.lastName==='') {
    errors.lastName = 'lastName is required';
  }

  if (!values.email || values.email==='') {
    errors.email = 'Email address is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }

  if (!values.age || values.age==='' ) {
    errors.age = 'Age is required';
  } else if (values.age < 18) {
    errors.age = 'Age must be 18 or more';
  }

  //console.log(errors);
  return errors;
}
