import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './table-style.css';
class TableRender extends Component {

  render() {

    return (
      <div className="scroll-table">
        <table>
          <thead>
            <tr className="tr-head">
              {
                this.props.data ? (
                  this.props.data.thead ? (
                    this.props.data.thead.length > -1 ? (
                      this.props.data.thead.map((th, index) =>
                        <th key={index + 'th-table'}>{th}</th>
                      )
                    ) : (<th key='nodata1'>no data</th>)
                  ) : (<th key='nodata2'>no data</th>)
                ) : (<th key='nodata3'>no data</th>)
              }
            </tr>
          </thead>
          <tbody>
            {
              this.props.data ? (
                this.props.data.tbody ? (
                  this.props.data.tbody.length > -1 ? (
                    this.props.data.tbody.map((tr, index) =>
                      <tr key={index + 'th-table'}>
                        {
                          tr.map((td) =>
                            <td key={'td-' + td}>{td}</td>
                          )
                        }
                      </tr>
                    )
                  ) : (<tr key='nodata4'><td>no data</td></tr>)
                ) : (<tr key='nodata5'><td>no data</td></tr>)
              ) : (<tr key='nodata6'><td>no data</td></tr>)
            }
          </tbody>
        </table>
      </div>

    );
  }
}
TableRender.propTypes = {
  data: PropTypes.any.isRequired,
};
TableRender.defaultProps = {
  data: [],
};
export default TableRender;
