
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TableRender from './../../../globalComponents/table';
import { deleteUserAction, listUsersAction } from './../actions';
import { getUsersList } from './../selectors';
import ModalCustom from './../../../globalComponents/modal/modal';
import ModalError from './modalError';
import { CurrentUserIsActive } from './../../../helpers/capabilitiesControl';

class UsersTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalTitle: '',
      modalContent: '',
      modalAcceptText: '',
      modal: false,
      modalTitleDel: '',
      modalContentDel: '',
      modalAcceptTextDel: '',
      modalDel: false,
      idEliminar: ''
    };
    this.deleteUser = this.deleteUser.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModalDelete = this.openModalDelete.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  openModal() {
    this.setState({ modal: true });
  }
  onCloseModal() {
    this.setState({
      modalDel: false
    });
  }
  openModalDelete(idUser) {
    this.setState({
      modalDel: true,
      idEliminar: idUser
    });
  }

  closeModal() {
    this.setState({ modal: false });
  }

  deleteUser(idUser) {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const token = JSON.parse(localStorage.getItem('token'));

    this.props.deleteUserAction(token, loginData._id, idUser).then(
      (resp) => {
        if (resp.payload[0]) {
          if (resp.payload[0].data) {
            if (resp.payload[0].data.ok) {
              this.setState({
                modalTitle: 'Eliminado',
                modalContent: 'El usuario se ha eliminado correctamente',
                modalAcceptText: 'Aceptar'
              });
              this.openModal();

              this.props.listUsersAction(token, loginData._id);
            }
          } else if (resp.payload[0].error.name === 'TokenExpiredError') {
            // eslint-disable-next-line react/prop-types
            this.props.history.push('/admin/logout/true');
          }
        } else {
          this.setState({
            modalTitle: 'Error al eliminar',
            modalContent: 'verifique su conexion',
            modalAcceptText: 'Aceptar'
          });
          this.openModal();
        }
      }
    ).catch(error => {
      if (error.name === 'TokenExpiredError') {
        // eslint-disable-next-line react/prop-types
        this.props.history.push('/admin/logout/true');
      }
    });
  }
  render() {
    const { data, search } = this.props;

    let DATA = {
      thead: [
        'Nombre de Usuario', 'Rol', 'Accion'
      ],
      tbody: [

      ]
    };
    if (data[0]) {
      if (data[0].users) {

        data[0].users.forEach(element => {

          let actions = <div>
            <Link to={'/admin/SeeUser/' + element._id}>
              <img src="/assets/tablas/ver.svg" className="iconTabla-planes-SA" alt="iconVer" />
            </Link>
            <abbr>&nbsp;&nbsp;&nbsp;&nbsp;</abbr>
            <Link to={'/admin/edit_user/' + element._id}>
              <img src="/assets/tablas/editar.svg" className="iconTabla-planes-SA" alt="iconVer" />
            </Link><abbr>&nbsp;&nbsp;&nbsp;&nbsp;</abbr>
            <Link to='/admin/list_users' >
              <img onClick={() => this.openModalDelete(element._id)} src="/assets/tablas/eliminar.svg" className="iconTabla-planes-SA" alt="iconVer" />
            </Link> </div>;

          if (search) {
            if (element.username.includes(search.toLowerCase())) {
              if (CurrentUserIsActive()) {
                DATA.tbody.push([element.username, element.role, actions]);
              } else {
                DATA.tbody.push([element.username, element.role, '']);
              }
            }
          } else if (CurrentUserIsActive()) {
            DATA.tbody.push([element.username, element.role, actions]);
          } else {
            DATA.tbody.push([element.username, element.role, '']);
          }
        });
      }
    }


    return (
      <div>
        <ModalCustom
          open={this.state.modal}
          close={this.closeModal}
          tittle={this.state.modalTitle}
          contentModal={this.state.modalContent}
          onclickClose={this.closeModal}
          onclickAccept={this.closeModal}
          closeText={this.state.modalAcceptText}
        //acceptText={'Aceptar'}
        />
        <ModalError
          open={this.state.modalDel}
          close={this.onCloseModal}
          onclickClose={this.onCloseModal}
          acceptText={'Aceptar'}
          closeText={'Cancelar'}
          idAEliminar={this.state.idEliminar}
          nameAEliminar={'Desea eliminar'}
          consultarPlanesAdminAction={this.props.listUsersAction}
        />
        <TableRender data={DATA} />
      </div>
    );
  }
}
UsersTable.propTypes = {
  listUsersAction: PropTypes.func.isRequired,
  deleteUserAction: PropTypes.func.isRequired,
  usersList: PropTypes.array,
  data: PropTypes.array.isRequired,
  search: PropTypes.string
};
UsersTable.defaultProps = {
  usersList: []
};
const mapStateToProps = state => ({
  usersList: getUsersList(state)
});
export default withRouter(
  connect(
    mapStateToProps, {
      listUsersAction,
      deleteUserAction
    })(UsersTable));
