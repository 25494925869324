import { handleActions } from 'redux-actions';
import { LIST_CUSTOMERS_ADMIN, GET_CUSTOMER } from './../constants';

export const customersState = handleActions({
  [LIST_CUSTOMERS_ADMIN]: (state, action) => [...action.payload],
}, []);

export const getcustomerState = handleActions({
  [GET_CUSTOMER]: (state, action) => {
    return {...action.payload};
  },
}, {});
