import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './../stylePointsMonthExcel.css';

import FormPuntosGenerados from './../components/formPuntosGenerados';

class PuntosGeneradosContainer extends Component {
  render() {
    return (
      <div className="row justify-content-md-center">
        <div className="col-11 align-self-center span-pointsMonth-SA">
          <span >Puntos generados</span>
        </div>
        <FormPuntosGenerados></FormPuntosGenerados>
      </div>
    );
  }
}

PuntosGeneradosContainer.propTypes = {};

export default withRouter(PuntosGeneradosContainer);
