export const getUserAdminService = (url, data) => () => {
  var URLSearchParams = Object.keys(data).map((key) => {
    return (key) + '=' + (data[key]);
  }).join('&');

  return fetch(url + 'company/company', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': '*/*',
    },
    body: URLSearchParams
  }).then(function (response) {
    return response.json();
  }).then(function (responser) {
    if (responser.data) {
      return responser.data;
    }
    const datos = [];
    datos.push(responser.data);
    return datos;
  }).catch((error) => {
    return error;
  });
};



