import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {actualizarAdminPassAction} from './../actions/actualizarAdminPassAction';
import { ToastContainer, toast } from 'react-toastify';
import { TiInfoOutline } from 'react-icons/ti';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import 'react-toastify/dist/ReactToastify.css';
class VisualizarUserAdmin extends Component {

  constructor(props){
    super(props);

    this.state = {
      id: props.userAdmin._id,
      password: '',
      errors: {},
      errorSubmit: '',
      cambiar: false,
      actualizando: false
    };

    this.changePass = this.changePass.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.isSubmitting = this.isSubmitting.bind(this);
    this.volver = this.volver.bind(this);
  }

  changePass() {
    this.setState({
      cambiar: true
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    let values = {
      id: this.state.id,
      password: this.state.password
    };

    this.validate(event, values);
  }

  validate(event, values) {
    let errors = {};

    if (!values.password || values.password==='' ) {
      errors.password = 'Por favor, introduce tu contraseña.';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(values.password)) {
      errors.password = 'Por favor, introduce por lo menos 8 caracteres, con al menos una mayúscula, una minúscula y un número.';
    }

    this.setState({ errors });
    this.isSubmitting(event, errors, values);
  }

  isSubmitting(event, errors, values){
    event.preventDefault();
    this.setState({
      actualizando: true
    });
    if (Object.keys(errors).length === 0 ) {
      const token = JSON.parse(localStorage.getItem('token'));

      return actualizarAdminPassAction(values, token).then(r => {
        if(r.status) {
          if(r.status === 401)
            this.props.history.push('/admin/logout/true');
        } else if (r.ok) {
          toast.success(<div><IoIosCheckmarkCircle className='icon-toast' />{'Contraseña actualizada correctamente'}</div>, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({
            password: '',
            errors: {},
            errorSubmit: '',
            actualizando: false,
            cambiar: false
          });
        } else if (!r.ok) {
          this.setState({
            errorSubmit: r.error.message,
            actualizando: false,
            cambiar: false
          });
        }
      });
    }

  }

  volver(){
    this.props.history.push('/admin/usuarios');
  }

  render() {
    //let idEmpresa = this.props.userAdmin._id;
    let { username, email, name, max_users } = this.props.userAdmin;

    return (
      <div className="row">
        <div className="col-11">
          <div className="m-t-5-users-SA">
            <div className="contentVerUser-SA">

              <div className="row justify-content-start m-b-3-users-SA">
                <div className="col-xl-12 col-lg-12 col-sm-12">
                  <span className="txt-verUsuario-SA">Empresa: </span>
                  <span className="txt-verUsuario-SA"> { name || '' } </span>
                </div>
              </div>

              <div className="row justify-content-start m-b-3-users-SA">
                <div className="col-xl-4 col-lg-5 col-sm-6">
                  <span className="txt-verUsuario-SA">Admin: </span>
                  <span className="txt-verUsuario-SA"> { username || '' } </span>
                </div>
                <div className="col-xl-8 col-lg-7 col-sm-6">
                  <span className="txt-verUsuario-SA">Correo electrónico: </span>
                  <span className="txt-verUsuario-SA"> { email || '' } </span>
                </div>
              </div>

              <div className="row justify-content-start m-b-3-users-SA">
                {
                  this.state.cambiar ?
                    (
                      <div className="col-xl-12 col-lg-12 col-sm-12">
                        <form method="post" id="formChangePass-SA" onSubmit={this.handleSubmit}>
                          <div className="row">
                            <div className="col-4">
                              <div className="input-group input-group-addUser-SA">
                                <input type="password" id="password" name="password" value={this.state.password}
                                  onChange={event => this.setState({ password: event.target.value })}
                                  className={this.state.errors.password && ('input-errorLine')}/>
                                <label>Contraseña: </label>
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                {this.state.errors.password && (
                                  <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.password}</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    ) :
                    (
                      <div className="col-xl-12 col-lg-12 col-sm-12">
                        <span className="txt-verUsuario-SA">Contraseña: </span>
                        <span className="txt-verUsuario-SA"> {'••••••••••'} </span>
                        <span className="txtAction-verUsuario-SA" onClick={this.changePass}> {' [Cambiar]'} </span>
                      </div>
                    )
                }
              </div>

              <div className="row justify-content-start m-b-3-users-SA">
                <div className="col-xl-12 col-lg-12 col-sm-12">
                  <span className="txt-verUsuario-SA">Usuarios maximos: </span>
                  <span className="txt-verUsuario-SA"> { max_users || '' } </span>
                </div>
              </div>

            </div>

            <div className="fixed-bottom bottom-users-SA p-b-3-users-SA p-t-2-users-SA">
              <div className="row align-items-end justify-content-end">
                <div className="col-xl-5 col-lg-6 col-md-8">
                  <button type="button" onClick={this.volver} className="btn-secondaryReward btn-editUserForm-SA m-r-5-users-SA"> Volver </button>
                  {
                    this.state.cambiar ?
                      (
                        this.state.actualizando ?
                          (
                            <button type="submit" form="formChangePass-SA" className="btn-primaryReward btn-editUserForm-SA" disabled> Actualizar </button>
                          ) :
                          (
                            <button type="submit" form="formChangePass-SA" className="btn-primaryReward btn-editUserForm-SA"> Actualizar </button>
                          )
                      ) :
                      (
                        <button type="button" onClick={this.volver} className="btn-primaryReward btn-editUserForm-SA"> Aceptar </button>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
          <ToastContainer autoClose={5000}/>
        </div>
      </div>
    );
  }
}

VisualizarUserAdmin.propTypes = {
  history: PropTypes.object,
  userAdmin: PropTypes.object.isRequired,
};

export default withRouter(VisualizarUserAdmin);
