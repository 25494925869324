import { handleActions } from 'redux-actions';
import { LIST_USERS_ADMIN, DELETE_USERS_ADMIN, GET_USER_ADMIN } from './../constants';

export const usersAdminState = handleActions({
  [LIST_USERS_ADMIN]: (state, action) => [...action.payload],
  [GET_USER_ADMIN]: (state, action) => [...action.payload],
  [DELETE_USERS_ADMIN]: (state, action) => {
    return (state.filter(u => u._id !== action.payload));
  }
}, []);
