/* eslint-disable no-else-return */
export function agregarNuevoUserAdminService(url, data, token) {

  var URLSearchParams = Object.keys(data).map((key) => {
    return (key) + '=' + (data[key]);
  }).join('&');

  return new Promise((resolve, reject) => {
    var res = fetch(url + 'auth/register/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
        'Authorization': token
      },
      body: URLSearchParams
    }).then(function (response) {
      let status = response.status;
      let res = {ok: response.ok, status: status};
      if(status === 401){
        return res;
      } else {
        return response.json();
      }
    }).then(function (responser) {
      return responser;
    }).catch((error) => {
      return error;
    });
    res ? resolve(res) : reject('error-');
  });
}

export function buscarEmpresaPorUsernameService(url, data) {

  var URLSearchParams = Object.keys(data).map((key) => {
    return (key) + '=' + (data[key]);
  }).join('&');

  return new Promise((resolve, reject) => {
    var res = fetch(url + 'company/company', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
      },
      body: URLSearchParams
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (responser) {
        return responser;
      }).catch((error) => {
        return error;
      });
    res ? resolve(res) : reject('error-');
  });
}

