import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Nav from '../../menu/navBar';
import SideBar from '../../menu/sideBar';
import VisualizarPlanAdmin from './../components/visualizarPlanAdmin';
import { getPlanAdminAction } from './../actions/getPlanAdminAction';
import { consultarPlanesAdminAction } from './../actions/consultarPlanesAdminAction';
import { getPlanAdmin, consultPlanesAdmin } from './../selectors';

class VerPlanAdminContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      minValue: 0,
      maxValue: 0
    };
  }

  componentDidMount() {
    const data = {
      idPlan: this.props.match.params.idPlan
    };
    const token = JSON.parse(localStorage.getItem('token'));
    const loginData = JSON.parse(localStorage.getItem('loginData'));

    const idEmpresa = loginData._id;
    this.props.getPlanAdminAction(data.idPlan, token).then(
      response => {
        if (response.payload[0].error === 401) {
          this.props.history.push('/admin/logout/true');
        }
      });
    this.props.consultarPlanesAdminAction(idEmpresa, token).then(
      response => {
        if (response.payload[0].error === 401) {
          this.props.history.push('/admin/logout/true');
        }
      });
  }

  render() {
    const idPlan = this.props.match.params.idPlan;
    const planAdmin = this.props.planAdmin;
    let planAdminActual = {};

    if (planAdmin.length > 0) {
      for (let i = 0; planAdmin.length > i; i++) {
        if (planAdmin[i]._id === idPlan) {
          planAdminActual = planAdmin[i];
        }
      }
    }

    return (
      <div>
        <Nav />
        <div className='row custom-row' id='body-row'>
          <SideBar option={'planes'} stateMenu={[false]} />
        </div>
        <div className='col-11 float-right'>
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-start m-t-5-users-SA">
                <div className="col-md-12 content-users-SA">
                  <span>
                    <Link to='/admin/planes' className='link-users-SA'>Planes</Link>
                    {' / '}
                    <Link to={'/admin/planes/see/' + idPlan} className='link-users-SA'>Ver plan</Link>
                  </span>
                </div>
              </div>
              <div className="row justify-content-start m-t-2-users-SA">
                <div className="col-md-12 content-users-SA">
                  <span className="text-nuevoUser-SA">
                    Visualiza los datos de este plan.
                  </span>
                </div>
              </div>
            </div>
          </div>
          {
            Object.keys(planAdminActual).length > 0 ?
              (
                this.props.planesAdminList.length > 0 ?
                  (
                    <VisualizarPlanAdmin planAdmin={planAdminActual}
                      planesAdminList={this.props.planesAdminList} ></VisualizarPlanAdmin>
                  ) :
                  (
                    <div></div>
                  )
              ) :
              (
                <div></div>
              )
          }
        </div>
      </div>
    );
  }
}

VerPlanAdminContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      idPlan: PropTypes.string.isRequired,
    })
  }),
  history: PropTypes.object,
  planAdmin: PropTypes.array.isRequired,
  getPlanAdminAction: PropTypes.func.isRequired,
  planesAdminList: PropTypes.array.isRequired,
  consultarPlanesAdminAction: PropTypes.func.isRequired
};

VerPlanAdminContainer.defaultProps = {
  planAdmin: [],
  planesAdminList: []
};

const mapStateToProps = state => ({
  planAdmin: getPlanAdmin(state),
  planesAdminList: consultPlanesAdmin(state)
});

export default withRouter(connect(mapStateToProps, { getPlanAdminAction, consultarPlanesAdminAction })(VerPlanAdminContainer));
