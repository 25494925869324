export const getUser = (url, token, idAdmin, idUser) => () =>
  fetch(url + 'company/' + idAdmin + '/user/' + idUser, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': token
    }
  }).then(function (response) {
    return response.json();
  }).then(function (responser) {
    if (responser.ok) {
      return responser.data;
    }
    const datos = [];
    datos.push(responser.error);
    return datos;
  }).catch((error) => {
    return error;
  });


export function updateUserService(url, token, idAdmin, idUser, data) {
  const URLSearchParams = Object.keys(data).map((key) => {
    return (key) + '=' + (data[key]);
  }).join('&');

  return new Promise((resolve, reject) => {
    var res = fetch(url + 'company/' + idAdmin + '/user/' + idUser, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      },
      body: URLSearchParams
    }).then(function (response) {
      return response.json();
    })
      .then(function (responser) {
        var datos = [];
        datos.push(responser);
        return datos;
      }).catch((error) => {
        return error;
      });
    res ? resolve(res) : reject('error-');
  });
}
