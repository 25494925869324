import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import PropTypes from 'prop-types';

import Nav from '../../menu/navBar';
import SideBar from '../../menu/sideBar';
import FormAddUsersAdmin from './../components/formAddUsersAdmin';

class AgregarUserAdminContainer extends Component {
  render() {
    return (
      <div>
        <Nav />
        <div className='row custom-row' id='body-row'>
          <SideBar option={'rootuser'}/>
        </div>
        <div className='col-11 float-right'>
          <div className="row">
            <div className="col-11">
              <div className="row justify-content-start m-t-5-users-SA">
                <div className="col-md-12 content-users-SA">
                  <span>
                    <Link to='/admin/usuarios' className='link-users-SA'>Usuarios</Link>
                    { ' / ' }
                    <Link to='/admin/usuarios/nuevo' className='link-users-SA'>Nuevo Usuario</Link>
                  </span>
                </div>
              </div>
              <div className="row justify-content-start m-t-2-users-SA">
                <div className="col-md-12 content-users-SA">
                  <span className="text-nuevoUser-SA">
                    Ingresa los datos del nuevo usuario para crearlo.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <FormAddUsersAdmin></FormAddUsersAdmin>
        </div>
      </div>
    );
  }
}

AgregarUserAdminContainer.propTypes = {

};

export default AgregarUserAdminContainer;
