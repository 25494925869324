import {
  URL_API,
  GET_POINTS
} from './../../constants';
import {
  getPoinstsServices,
  clearPoinstsServices
} from '../services';
import { createAction } from 'redux-actions';

export const getPointsAction = createAction(GET_POINTS,
  (cardNumber) => getPoinstsServices(URL_API, cardNumber)());

export const clearPointsAction = createAction(GET_POINTS,
  () => clearPoinstsServices());
