export const getUserByIDADmin = (url, token, id) => () =>
  fetch(url + 'company/' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': token
    }
  }).then(function (response) {
    return response.json();
  }).then(function (responser) {
    if (responser.ok) {
      return responser.data;
    }
    const datos = [];
    datos.push(responser.error);
    return datos;
  }).catch((error) => {
    return error;
  });

export const deleteUserService = (url, token, idAdmin, idUser) => () =>
  fetch(url + 'company/' + idAdmin + '/user/' + idUser, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': token
    }
  }).then(function (response) {
    return response.json();
  }).then(function (responser) {
    var datos = [];
    datos.push(responser);
    return datos;
  }).catch((error) => {
    return error;
  });
