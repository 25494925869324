import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Nav from './../../menu/navBar';
import SideBar from './../../menu/sideBar';
import { withRouter, Link } from 'react-router-dom';
import './../style.css';

import { ReactComponent as SearchIcon } from './../../listUserEmp/resources/icons8-search.svg';
import { listCustomersAction } from './../actions';
import { getCustomersList } from './../selectors';
import CustomersTable from './../components/customersTable';
import { CurrentUserIsActive } from './../../../helpers/capabilitiesControl';

class ListCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ''
    };
    this.deleteCustomers = this.deleteCustomers.bind(this);
  }

  componentDidMount() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const token = JSON.parse(localStorage.getItem('token'));
    this.props.listCustomersAction(token, loginData._id).then(
      response => {
        if (response.payload[0].name === 'TokenExpiredError') {
          // eslint-disable-next-line react/prop-types
          this.props.history.push('/admin/logout/true');
        }
      }
    ).catch(error => {
      if (error.name === 'TokenExpiredError') {
        // eslint-disable-next-line react/prop-types
        this.props.history.push('/admin/logout/true');
      }
    });
  }

  deleteCustomers(id) {
    let idcustomer = id;
    if (idcustomer) {
      return true;
    }
    return false;
  }

  render() {

    return (
      <div>
        <Nav />
        <div className='row custom-row' id='body-row'>
          <SideBar option='users' />
        </div>
        <div className='col-11 float-right max-w-body'>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>

          <div className='row'>
            <div className='col-11 '>
              <div className='row'>
                <div className='col-5'>
                  {CurrentUserIsActive() ? (
                    <Link to='/admin/add_customers' className='btn-primaryReward btn-custom-size float-left btn-custom-size-accept'>+ Nuevo Ingreso</Link>
                  ) : ('')}
                </div>
                <div className='col cust-col-table'></div>
                <div className='col-lg-5 col-md-7 col-7'>
                  <div className='row'>
                    <div className='d-inline div-search'>
                      <input type="text" id="search" name="search" value={this.state.search || ''}
                        onChange={event => this.setState({ search: event.target.value })}
                        className=' input-form input-search' placeholder="Buscar" />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                    </div>

                    <SearchIcon className='icon-search' />
                  </div>
                </div>
              </div>

              <hr></hr>
              <div className='row'>
                <div className='col-12'>
                  <CustomersTable data={this.props.customersList} search={this.state.search} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListCustomers.propTypes = {
  listCustomersAction: PropTypes.func.isRequired,
  customersList: PropTypes.array.isRequired,
};

ListCustomers.defaultProps = {
  customersList: []
};

const mapStateToProps = state => ({
  customersList: getCustomersList(state)
});
export default withRouter(connect(mapStateToProps, { listCustomersAction })(ListCustomers));
