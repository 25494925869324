
export function insertCustomerService(url, token, id, obj) {

  return new Promise((resolve, reject) => {
    var respuesta = fetch(`${url}customers/company/${id}`, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: new Headers({ 'Content-type': 'application/json', 'Authorization': token })
    }).then(function (response) {
      return response.json();
    }).then(function (responser) {
      return responser;
    }).catch((error) => {
      return error;
    });
    respuesta ? resolve(respuesta) : reject('error-');
  });
}

export const getgetMembershipService = (url, token, id) => () =>
  fetch(url + 'plans/company/' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': token
    }
  }).then(function (response) {
    return response.json();
  }).then(function (responser) {
    if (responser.ok) {
      return responser.data;
    }
    let error=[];
    error.push(responser.error);
    return error;
  }).catch((error) => {
    return error;
  });
