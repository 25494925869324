import {
  URL_API,
  GET_USER,
} from './../../../constants';
import {
  getUser,
  updateUserService,
  updatePasswordUsersService
} from './../services';
import { createAction } from 'redux-actions';

export const getUsersAction = createAction(GET_USER,
  (token, idAdmin, idUser) => getUser(URL_API, token, idAdmin, idUser)());

export function updateUserAction(token, idAdmin, idUser, data) {
  return new Promise((resolve, reject) => {
    var response = updateUserService(URL_API, token, idAdmin, idUser, data);
    response ? resolve(response) : reject('error');
  });
}
export function updatePasswordUsersAction(token, idUser, data) {
  return new Promise((resolve, reject) => {
    var response = updatePasswordUsersService(URL_API, token, idUser, data);
    response ? resolve(response) : reject('error');
  });
}
