import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './../style.css';
import { TiInfoOutline } from 'react-icons/ti';

class logoutCont extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLogged: false,
      minutes: 0,
      seconds: 4
    };
  }

  componentDidMount() {
    localStorage.removeItem('loginData');
    localStorage.removeItem('token');
    localStorage.clear();

    if (this.props.match.params.tokenStatus !== 'true') {
      if(this.props.match.params.policies !== 'true'){
        // eslint-disable-next-line react/prop-types
        this.props.history.push('/login/admin');
      } else {
        // eslint-disable-next-line react/prop-types
        this.props.history.push('/login/admin/false/true');
      }
    } else {
      // eslint-disable-next-line react/prop-types
      this.props.history.push('/login/admin/true');
      /*this.myInterval = setInterval(() => {
        const { seconds, minutes } = this.state;
        if (seconds > 0) {
          this.setState(({ seconds }) => ({
            seconds: seconds - 1
          }));
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(this.myInterval);
          } else {
            this.setState(({ minutes }) => ({
              minutes: minutes - 1,
              seconds: 59
            }));
          }
        }
      }, 1000);*/
    }
  }

  componentDidUpdate() {
    localStorage.removeItem('loginData');
    localStorage.removeItem('token');
    localStorage.clear();

    if (this.props.match.params.tokenStatus !== 'true') {
      //clearInterval(this.myInterval);
      if(this.props.match.params.policies !== 'true'){
        // eslint-disable-next-line react/prop-types
        this.props.history.push('/login/admin');
      } else {
        // eslint-disable-next-line react/prop-types
        this.props.history.push('/login/admin/false/true');
      }
    } else if (this.state.seconds === 0) {
      // eslint-disable-next-line react/prop-types
      this.props.history.push('/login/admin');
    }
  }

  render() {

    return (
      <div className='col-12'>
        {this.props.match.params.tokenStatus ?
          (<h1 className='expired-message'><TiInfoOutline className='color-red' />Tu Sesión ha expirado</h1>) : ('')}
      </div>
    );
  }
}

logoutCont.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tokenStatus: PropTypes.string,
      policies: PropTypes.string,
    })
  })
};

export default withRouter(logoutCont);

