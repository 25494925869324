import {
  URL_API,
  GET_CUSTOMER,
} from '../../../constants';
import {
  getCustomerService,
  updateCustomerService,
  clearCustomerService
} from '../services';
import { createAction } from 'redux-actions';

export const getCustomerAction = createAction(GET_CUSTOMER,
  (token, idCustomer) => getCustomerService(URL_API, token, idCustomer)());

export const clearCustomerAction = createAction(GET_CUSTOMER,
  () => clearCustomerService());

export function updateCustomerAction(token, idCustomer, obj) {
  return new Promise((resolve, reject) => {
    var response = updateCustomerService(URL_API, token, idCustomer, obj);
    response ? resolve(response) : reject('error');
  });
}
