export const getPlanAdminService = (url, idPlan, token) => () => {

  return fetch(url + 'plans/' + idPlan, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': '*/*',
      'Authorization': token
    }
  }).then(function (response) {
    let status = response.status;
    let res = {ok: response.ok, error: status};
    if(status !== 401){
      return response.json();
    } else if(status === 401){
      return res;
    }
  }).then(function (responser) {
    let data = [];
    if( !responser.ok ) {
      data.push(responser);
      return data;
    } else
    if( responser.ok ) {
      data.push(responser.data);
      return data;
    }
  }).catch((error) => {
    return error;
  });
};
