import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {agregarNuevoUserAdminAction, buscarEmpresaPorUsernameAction} from './../actions/agregarNuevoUserAdminAction';
import { ToastContainer, toast } from 'react-toastify';
import { TiInfoOutline } from 'react-icons/ti';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import 'react-toastify/dist/ReactToastify.css';
class FormAddUsersAdmin extends Component {

  constructor(props){
    super(props);

    this.state = {
      email: '',
      password: '',
      username: '',
      name: '',
      maxUsers: '',
      errors: {},
      errorSubmit: '',
      agregando: false,
      visibleHelp: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.isSubmitting = this.isSubmitting.bind(this);
    this.volver = this.volver.bind(this);

  }

  handleSubmit(event){
    event.preventDefault();

    let values = {
      email: this.state.email,
      password: this.state.password,
      username: this.state.username,
      name: this.state.name,
      maxUsers: this.state.maxUsers,
    };
    this.validate(event, values);
  }

  validate(event, values) {
    let errors = {};
    if (!values.email || values.email==='') {
      errors.email = 'Por favor, introduce tu correo.';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Lo sentimos, correo inválido.';
    }

    if (!values.password || values.password==='' ) {
      errors.password = 'Por favor, introduce tu contraseña.';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(values.password)) {
      errors.password = 'Por favor, introduce por lo menos 8 caracteres, con al menos una mayúscula, una minúscula y un número.';
    }

    if (!values.username || values.username==='' ) {
      errors.username = 'Por favor, introduce el nombre de usuario que tendrá la empresa.';
    }

    if (!values.name || values.name==='' ) {
      errors.name = 'Por favor, introduce el nombre de la empresa.';
    }

    if (!values.maxUsers || values.maxUsers==='' ) {
      errors.maxUsers = 'Por favor, introduce la cantidad máxima de usuarios.';
    } else if (values.maxUsers < 1) {
      errors.maxUsers = 'Por favor, introduce numeros mayores que 0.';
    }

    this.setState({ errors });
    this.isSubmitting(event, errors, values);
  }

  isSubmitting(event, errors, values){
    event.preventDefault();

    this.setState({
      agregando: true
    });

    if (Object.keys(errors).length === 0 ) {
      const token = JSON.parse(localStorage.getItem('token'));
      const username = {
        username: values.username
      };
      return buscarEmpresaPorUsernameAction(username).then(response => {
        if(response.data.length === 0) {
          return agregarNuevoUserAdminAction(values, token).then(r => {
            if(r.status) {
              if(r.status === 401)
                this.props.history.push('/admin/logout/true');
            } else if(r.code === 500) {
              if(r.message.includes('company validation failed: email: Error')){
                this.setState({
                  errors: {
                    email: 'El correo ya existe, por favor introduce otro.'
                  },
                  agregando: false
                });
              }
            } else
            if (r._id) {
              toast.success(<div><IoIosCheckmarkCircle className='icon-toast' />{'Usuario agregado correctamente.'}</div>, {
                position: toast.POSITION.TOP_RIGHT
              });
              this.setState({
                email: '',
                password: '',
                username: '',
                name: '',
                maxUsers: '',
                errorSubmit: '',
                agregando: false
              });
            } else
            if (!r.ok) {
              this.setState({
                errorSubmit: r.error.message,
                agregando: false
              });
            }
          });
        } else
        if (response.data[0] !== undefined) {
          this.setState({
            errors: {
              username: 'El nickname de la empresa ya existe, por favor agrega otro.'
            },
            agregando: false
          });
        }
      });
    }

  }

  volver(){
    this.props.history.push('/admin/usuarios');
  }

  render() {

    return (
      <div className="row">
        <div className="col-11">
          <div className="m-t-5-users-SA">
            <form method="post" className="formAddUsers-SA m-b-15-users-SA" id="formAddUsers-SA" onSubmit={this.handleSubmit}>

              <div className="row justify-content-start">
                <div className="col-xl-4 col-lg-5 col-sm-6">
                  <div className="form-group margin-l-40p">
                    <div className="input-group input-group-addUser-SA m-b-15-users-SA">
                      <input type="email" id="email" name="email" value={this.state.email}
                        onChange={event => this.setState({ email: event.target.value })}
                        className={this.state.errors.email && ('input-errorLine')}/>
                      <label>Correo electrónico</label>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      {this.state.errors.email && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.email}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-sm-6">
                  <div className="form-group margin-l-40p">
                    <div className="input-group input-group-addUser-SA m-b-15-users-SA">
                      <input type="text" id="name" name="name" value={this.state.name}
                        onChange={event => this.setState({ name: event.target.value })}
                        className={this.state.errors.name && ('input-errorLine')}/>
                      <label>Nombre de la empresa</label>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      {this.state.errors.name && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.name}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-start">
                <div className="col-xl-4 col-lg-5 col-sm-6">
                  <div className="form-group margin-l-40p">
                    <div className="input-group input-group-addUser-SA m-b-15-users-SA">
                      <input type="text" id="username" name="username" value={this.state.username}
                        onChange={event => this.setState({ username: event.target.value })}
                        className={this.state.errors.username && ('input-errorLine')}/>
                      <label>Nickname de la empresa</label>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      {this.state.errors.username && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.username}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-sm-6">
                  <div className="form-group margin-l-40p">
                    <div className="input-group input-group-addUser-SA m-b-15-users-SA">
                      <input type="number" id="maxUsers" name="maxUsers" value={this.state.maxUsers}
                        onChange={event => this.setState({ maxUsers: event.target.value })}
                        className={this.state.errors.maxUsers && ('input-errorLine')}/>
                      <label>Usuarios máximos</label>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      {this.state.errors.maxUsers && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.maxUsers}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-sm-6">
                  <div className="form-group margin-l-40p">
                    <div className="input-group input-group-addUser-SA m-b-15-users-SA">
                      <input type="password" id="password" name="password" value={this.state.password}
                        onChange={event => this.setState({ password: event.target.value })}
                        className={this.state.errors.password && ('input-errorLine')}/>
                      <label>Contraseña</label>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      {this.state.errors.password && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.password}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div className="fixed-bottom bottom-users-SA p-b-3-users-SA p-t-2-users-SA">
              <div className="row align-items-end justify-content-end">
                <div className="col-xl-5 col-lg-6 col-md-8">
                  <button type="button" onClick={this.volver} className="btn-secondaryReward btn-addUserForm-SA m-r-5-users-SA"> Volver </button>
                  {
                    this.state.agregando ?
                      (
                        <button type="submit" form="formAddUsers-SA" className="btn-primaryReward btn-addUserForm-SA" disabled> Añadir </button>
                      ) :
                      (
                        <button type="submit" form="formAddUsers-SA" className="btn-primaryReward btn-addUserForm-SA"> Añadir </button>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
          <ToastContainer autoClose={5000}/>
        </div>
      </div>
    );
  }
}

FormAddUsersAdmin.propTypes = {
  history: PropTypes.object,
};

export default withRouter(FormAddUsersAdmin);
