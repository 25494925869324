export function agregarPlanAdminService(url, data, token) {

  return new Promise((resolve, reject) => {
    var res = fetch(url + 'plans/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': token
      },
      body: data
    })
      .then(function (response) {
        let status = response.status;
        let res = {ok: response.ok, error: status};
        if(status === 200){
          return response.json();
        } else if(status === 401){
          return res;
        }
      })
      .then(function (responser) {
        return responser;
      }).catch((error) => {
        return error;
      });
    res ? resolve(res) : reject('error-');
  });
}
