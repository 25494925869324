/* eslint-disable no-dupe-else-if */
import React from 'react';
//import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import useForm from './useForm';
import validate from './validate';

import Container from 'react-bootstrap/Container';

import { TiInfoOutline } from 'react-icons/ti';
import './form.css';

const DemoForm = () => {

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(login, validate);

  function login() {
    //No errors, submit callback called!
  }

  return (
    <Container>
      <form className="formReward" id="formAddDemo" onSubmit={handleSubmit}>
        <Form.Row>
          <Col>
            <Form.Group className="group">
              <input type="text" disabled id="name" name="name" value={'value default'}
                onChange={handleChange} className={errors.name && ('input-errorLine')}/>
              <span className="highlight"></span>
              <span className="bar"></span>
              <Form.Label>Name</Form.Label>
              {errors.name && (
                <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {errors.name}</span>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="group">
              <input type="text" id="lastName" name="lastName" value={values.lastName || ''}
                onChange={handleChange} className={errors.lastName && ('input-errorLine')}/>
              <span className="highlight"></span>
              <span className="bar"></span>
              <Form.Label>Last Name</Form.Label>
              {errors.lastName && (
                <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {errors.lastName}</span>
              )}
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group className="group">
              <input type="number" id="age" name="age" value={values.age || ''}
                onChange={handleChange} className={errors.age && ('input-errorLine')}/>
              <span className="highlight"></span>
              <span className="bar"></span>
              <Form.Label>Age</Form.Label>
              {errors.age && (
                <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {errors.age}</span>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="group">
              <input type="email" id="email" name="email" value={values.email || ''}
                onChange={handleChange} className={errors.email && ('input-errorLine')}/>
              <span className="highlight"></span>
              <span className="bar"></span>
              <Form.Label>Email</Form.Label>
              {errors.email && (
                <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {errors.email}</span>
              )}
            </Form.Group>
          </Col>
        </Form.Row>
        <Button className="btn-primaryReward" type="submit" form="formAddDemo">Submit</Button>
      </form>
    </Container>
  );
};

DemoForm.propTypes = {

};

export default DemoForm;
