import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Nav from './../../menu/navBar';
import SideBar from './../../menu/sideBar';
import './../style.css';
import { getUsersAction } from './../actions';
import { getUserState } from './../selectors';
import UpdateUser from './../components';


class editUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      errors: '',
      modal: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const token = JSON.parse(localStorage.getItem('token'));

    this.props.getUsersAction(token, loginData._id, this.props.match.params.userId).then(
      response => {
        if (response.payload[0].name === 'TokenExpiredError') {
          // eslint-disable-next-line react/prop-types
          this.props.history.push('/admin/logout/true');
        }
      }
    ).catch(error => {
      if (error.name === 'TokenExpiredError') {
      // eslint-disable-next-line react/prop-types
        this.props.history.push('/admin/logout/true');
      }
    });
  }

  openModal() {
    this.setState({ modal: true });
  }
  closeModal() {
    this.setState({ modal: false });
  }


  render() {
    return (
      <div>
        <Nav />
        <div className='row custom-row' id='body-row'>
          <SideBar option='admins' />
        </div>
        <UpdateUser data={this.props.data} />
      </div>
    );
  }
}

editUser.propTypes = {
  getUsersAction: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string.isRequired,
    })
  })
};
editUser.defaultProps = {
  data: [],
};
const mapStateToProps = (state, props) => ({
  data: getUserState(state, props),
});

export default withRouter(connect(mapStateToProps, {
  getUsersAction,
})(editUser));
