import React, { PureComponent } from 'react';
import TableRender from './../../globalComponents/table';

import { ReactComponent as SearchIcon } from './../../management/listUserEmp/resources/icons8-search.svg';

import { withRouter } from 'react-router-dom';

import { FaChevronCircleUp } from 'react-icons/fa';
import { AiOutlineDownCircle } from 'react-icons/ai';
import equal from 'fast-deep-equal';

function formatDate(date) {

  if (date.includes('T00:00:00.000Z')) {
    date = date.replace('T00:00:00.000Z', '');
    return date;
  }
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + (d.getDate()),
    year = (d.getFullYear());

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}
class checkPoints extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      name: '',
      cardN: '',
      points: '',
      tableDataOrigin: [],
      tableData: []
    };

  }

  componentDidMount() {
    let body = [];
    const point = JSON.parse(localStorage.getItem('dataP'));
    const redem = JSON.parse(localStorage.getItem('dataR'));
    const infoD = JSON.parse(localStorage.getItem('infoD'));

    point.forEach(element => {
      element.detail.forEach(element2 => {
        body.push([<FaChevronCircleUp className='CircleUp' key={element2.updatedAt+element2.name}/>, (element2.updatedAt), element2.name, infoD.card_number, element2.points]);
      });
    });
    redem.forEach(element => {
      element.detail.forEach(element2 => {
        body.push([<AiOutlineDownCircle className='DownCircle' key={element.updatedAt+element2.name}/>, (element.updatedAt), element2.name, infoD.card_number, element2.points]);
      });
    });

    body.sort(function compare(a, b) {
      var dateA = new Date(a[1]);
      var dateB = new Date(b[1]);
      return dateB - dateA;
    });

    body.forEach((element, ind) => {
      body[ind][1] = formatDate(element[1]);
    });

    this.setState({
      tableData: body,
      tableDataOrigin: body,
      name: infoD.name,
      points: infoD.available_points,
    });

  }

  componentDidUpdate(_prevProps, prevstate) {

    if (!equal(this.state.search, prevstate.search)) {

      let data = this.state.tableDataOrigin;
      let results = [];
      if (this.state.search !== '') {
        data.forEach(element => {
          if (
            element[1].includes(this.state.search.toLowerCase()) ||
            element[2].includes(this.state.search.toLowerCase()) ||
            element[3].includes(this.state.search.toLowerCase()) ||
            element[4].includes(this.state.search.toLowerCase())) {
            results.push(element);
          }
        });
        this.setState({ tableData: results });
      } else {
        this.setState({ tableData: this.state.tableDataOrigin });
      }

    }

  }

  render() {
    let demoData = {
      thead: [
        'Accion', 'Fecha', 'Descripción', 'Lugar', 'Puntos'
      ],
      tbody: this.state.tableData
    };
    return (
      <div className='custom-absolute'>
        <div className='container-fluid font-style max-w-points'>
          <div className='col-12 '>
            <h1 className='title-points-tb'><img className='logo-img' src='/nube_nubentum2.png' alt='Nubentum'></img></h1>
          </div>
          <div className='col-12 '>
            <h4 className='info-text-t'><strong>{this.state.name},</strong> aquí está el registro de tus movimientos</h4>
          </div>
          <div className='data-content'>
            <div className='row'>

              <div className='col'>
              </div>
              <div className='col-4 col-sm-5 col-ls-5 col-xl-5'>
                <div className='d-inline div-search'>
                  <input type="text" id="search" name="search" value={this.state.search || ''}
                    onChange={event => this.setState({ search: event.target.value })}
                    className=' input-form input-search' placeholder='Buscar' />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                </div>
              </div>
              <SearchIcon className='icon-search' />
            </div>
            <div className='row'>
              <div className='col-12'>
                <h3 className='col-6 d-inline text-l color-5bc8af'>Total</h3>
                <h3 className='col-6 d-inline text-r color-5bc8af'>{this.state.points} puntos</h3>
              </div>
              <div className='col-12'>
                <hr></hr>
                <TableRender data={demoData} className='custom-t-p' />
              </div>
            </div>
          </div>

          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
        </div>
      </div>
    );
  }
}

checkPoints.propTypes = {

};

export default withRouter(checkPoints);
