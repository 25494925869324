import React from 'react';
import {
  Route, Redirect
} from 'react-router-dom';
import PropTypes from 'prop-types';

const fakeAuthUser = {
  isAuthenticated: false,
  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 100);
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

//only rol user
const PrivateRouteUser = ({ component: Component, ...rest }) => {
  const loginData = JSON.parse(localStorage.getItem('loginData'));
  const token = JSON.parse(localStorage.getItem('token'));

  if (loginData !== null && token !== null) {
    if (token && loginData.role === 'user') {
      fakeAuthUser.authenticate();
    } else {
      fakeAuthUser.signout();
    }
  } else {
    fakeAuthUser.signout();
  }

  return (
    <Route {...rest} render={(props) => (
      fakeAuthUser.isAuthenticated === true
        ? <Component {...props} />
        : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
    )} />
  );
};

PrivateRouteUser.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
};


const fakeAuthAdm = {
  isAuthenticated: false,
  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 100);
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

//only rol admin
const PrivateRouteAdmin = ({ component: Component, ...rest }) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const data = JSON.parse(localStorage.getItem('loginData'));
  if (data !== null && token !== null) {
    if (token && data.role === 'admin') {
      fakeAuthAdm.authenticate();
    } else {
      fakeAuthAdm.signout();
    }
  } else {
    fakeAuthAdm.signout();
  }

  return (
    <Route {...rest} render={(props) => (
      fakeAuthAdm.isAuthenticated === true
        ? <Component {...props} />
        : <Redirect to={{
          pathname: '/admin/planes',
          state: { from: props.location }
        }} />
    )} />
  );
};

PrivateRouteAdmin.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
};

const fakeAuthRoot = {
  isAuthenticated: false,
  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 100);
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

//only rol Root
const PrivateRouteRoot = ({ component: Component, ...rest }) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const data = JSON.parse(localStorage.getItem('loginData'));
  if (data !== null && token !== null) {
    if (token && data.role === 'root') {
      fakeAuthRoot.authenticate();
    } else {
      fakeAuthRoot.signout();
    }
  } else {
    fakeAuthRoot.signout();
  }

  return (
    <Route {...rest} render={(props) => (
      fakeAuthRoot.isAuthenticated === true
        ? <Component {...props} />
        : <Redirect to={{
          pathname: '/login/admin',
          state: { from: props.location }
        }} />
    )} />
  );
};

PrivateRouteRoot.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
};


const fakeAuthManagement = {
  isAuthenticated: false,
  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 100);
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

//only managements rol
const PrivateRouteManagement = ({ component: Component, ...rest }) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const data = JSON.parse(localStorage.getItem('loginData'));
  if (data !== null && token !== null) {
    if (token && (data.role === 'root' || data.role === 'admin')) {
      fakeAuthManagement.authenticate();
    } else {
      fakeAuthManagement.signout();
    }
  } else {
    fakeAuthManagement.signout();
  }

  return (
    <Route {...rest} render={(props) => (
      fakeAuthManagement.isAuthenticated === true
        ? <Component {...props} />
        : <Redirect to={{
          pathname: '/login/admin',
          state: { from: props.location }
        }} />
    )} />
  );
};

PrivateRouteManagement.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
};


const fakeAuth = {
  isAuthenticated: false,
  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 100);
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};
const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const data = JSON.parse(localStorage.getItem('loginData'));
  if (data !== null && token) {
    if (token && data.rol) {
      fakeAuth.authenticate();
    } else {
      fakeAuth.signout();
    }
  } else {
    fakeAuth.signout();
  }

  return (
    <Route {...rest} render={(props) => (
      fakeAuth.isAuthenticated === true
        ? <Component {...props} />
        : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
    )} />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
};
/*
const fakeAuthPublic = {
  isAuthenticated: false,
  routeLog: '/',
  authenticateStd(cb) {
    this.isAuthenticated = true;
    this.routeLog = '/user/home';
    setTimeout(cb, 100);
  },
  authenticateAdm(cb) {
    this.isAuthenticated = true;
    this.routeLog = '/admin/students';
    setTimeout(cb, 100);
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

const PublicRoute = ({ component: Component, ...rest }) => {

  const data = JSON.parse(localStorage.getItem('loginData'));
  if (data !== null) {
    if (data.token && data.user) {
      if (data.user.role === 'user') {
        fakeAuthPublic.authenticateStd();
      } else if (data.user.role === 'admin') {
        fakeAuthPublic.authenticateAdm();
      } else {
        fakeAuthPublic.signout();
      }
    } else {
      fakeAuthPublic.signout();
    }
  } else {
    fakeAuthPublic.signout();
  }

  return (
    <Route {...rest} render={(props) => (
      fakeAuthPublic.isAuthenticated === true ?
        <Redirect to={{
          pathname: fakeAuthPublic.routeLog,
          state: { from: props.location }
        }} />
        : <Component {...props} />
    )} />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
};
*/

export { PrivateRoute, PrivateRouteUser, PrivateRouteAdmin, PrivateRouteRoot, PrivateRouteManagement };
