import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './../style.css';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { TiInfoOutline } from 'react-icons/ti';

import equal from 'fast-deep-equal';

function formatDate(date) {

  if (date.includes('T00:00:00.000Z')) {
    date = date.replace('T00:00:00.000Z', '');
    return date;
  }
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + (d.getDate()),
    year = (d.getFullYear());

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

class FormCustomerLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      lastname: '',
      birthday: '',
      gender: '',
      street: '',
      neigboorhood: '',
      number: '',
      county: '',
      _state: '',
      zip: '',
      telephone: '',
      email: '',
      card_number: 0,
      id_membership: 0,
      membership_percent: 0,
      membership_name: '',
      errors: '',
      modal: false,
      value: '',
      modalTitle: '',
      modalContent: '',
      modalAcceptText: '',
    };

    this.asignar = this.asignar.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.customerInfo, prevProps.customerInfo)) {
      this.asignar(this.props.customerInfo);
    }
  }

  asignar(data) {

    if (data._id) {
      this.setState({
        name: data.name,
        lastname: data.lastname,
        birthday: formatDate(data.birthday),
        gender: data.gender,
        street: data.address.street,
        neigboorhood: data.address.neigboorhood,
        number: data.address.number,
        county: data.address.county,
        _state: data.address.state,
        zip: data.address.zip,
        telephone: data.telephone,
        email: data.email,
        card_number: data.card_number,
        id_membership: data.membership.id_membership,
        membership_percent: data.membership.percent,
        membership_name: data.membership.name,
      });
    }
  }

  render() {

    return (

      <div className='col-11 float-right max-w-body'>
        <div className='custom-space-row'></div>
        <div className='custom-space-row'></div>
        <div className='custom-space-row'></div>
        <div className='row'>
          <p className=' position-link'><Link to='/admin/list_customers' className='position-link'>Clientes</Link> / <Link to={'/admin/edit_customer/' + this.props.customId} className='position-link'>Ver Cliente</Link></p>
        </div>
        <div className='custom-space-row'></div>
        <div className='custom-space-row'></div>
        <div className='custom-space-row'></div>
        <div className='custom-space-row'></div>

        <Container fluid className='info-color see-custom-conten'>
          <div className='row'>
            <div className='col-11'>
              <Form.Row>
                <Col>
                  {this.state.errors.add && (
                    <span className="span-errorMessage error-message-custom"><TiInfoOutline></TiInfoOutline> {this.state.errors.add}</span>
                  )}
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs='4' lg='4' md='8' sm='12'>
                  <Form.Group className="group">
                    <p className='custom-label-form'>Nombre:  {this.state.name}</p>
                  </Form.Group>
                </Col>
                <Col xs='6' lg='6' md='8' sm='12'>
                  <Form.Group className="group">
                    <p className='custom-label-form'>Apellidos:  {this.state.lastname}</p>
                  </Form.Group>
                </Col>
                <Col></Col>
              </Form.Row>
              <Form.Row>
                <Col xs='4' lg='4' md='8' sm='12'>
                  <Form.Group className="group">
                    <p className='custom-label-form'>Fecha de nacimiento:  {this.state.birthday}</p>
                  </Form.Group>
                </Col>
                <Col xs='4' lg='4' md='8' sm='12'>
                  <Form.Group className="group">
                    <p className='custom-label-form'>Genero:  {this.state.gender}</p>
                  </Form.Group>
                </Col>
                <Col xs='4' lg='4' md='8' sm='12'>
                  <Form.Group className="group">
                    <p className='custom-label-form'>Domicilio:  {this.state.street}</p>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs='4' lg='4' md='8' sm='12'>
                  <Form.Group className="group">
                    <p className='custom-label-form'>Número:  {this.state.number}</p>
                  </Form.Group>
                </Col>
                <Col xs='4' lg='4' md='8' sm='12'>
                  <Form.Group className="group">
                    <p className='custom-label-form'>Zona:  {this.state.neigboorhood}</p>
                  </Form.Group>
                </Col>
                <Col xs='4' lg='4' md='8' sm='12'>
                  <Form.Group className="group">
                    <p className='custom-label-form'>Pais:  {this.state.county}</p>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs='4' lg='4' md='8' sm='12'>
                  <Form.Group className="group">
                    <p className='custom-label-form'>Estado:  {this.state._state}</p>
                  </Form.Group>
                </Col>
                <Col xs='4' lg='4' md='8' sm='12'>
                  <Form.Group className="group">
                    <p className='custom-label-form'>Codigo postal:  {this.state.zip}</p>
                  </Form.Group>
                </Col>
                <Col xs='4' lg='4' md='8' sm='12'>
                  <Form.Group className="group">
                    <p className='custom-label-form'>Telefono:  {this.state.telephone}</p>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs='4' lg='4' md='8' sm='12'>
                  <Form.Group className="group">
                    <p className='custom-label-form'>Correo electronico:  {this.state.email}</p>
                  </Form.Group>
                </Col>
                <Col xs='4' lg='4' md='8' sm='12'>
                  <Form.Group className="group">
                    <p className='custom-label-form'>Numero de tarjeta:  {this.state.card_number}</p>
                  </Form.Group>
                </Col>
                <Col xs='4' lg='4' md='8' sm='12'>
                  <Form.Group className="group">
                    <p className='custom-label-form'>Plan:  {this.state.membership_name}</p>
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row className="m-t-8rem">
                <Col lg={7} xs={7} md={4} sm={2}></Col>
                <Col>
                  <div className=''>
                    <div className='fixed-bottom bottom-planes-SA p-b-3-planes-SA p-t-2-planes-SA'>
                      <div className='row align-items-end justify-content-end'>
                        <div className='col-xl-5 col-lg-6 col-md-8'>
                          <Col className='d-inline'> </Col>
                          <Col className='d-inline'>
                            <Link to='/admin/list_customers' className='btn-secondaryReward btn-custom-size back-btn btn-back-c'>Volver</Link>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Form.Row>

            </div>
          </div>

        </Container>
      </div>

    );
  }
}

FormCustomerLabel.propTypes = {
  customerInfo: PropTypes.object.isRequired,
  customId: PropTypes.string.isRequired,
};
FormCustomerLabel.defaultProps = {
  customerInfo: {},
  customId: 0,
};

export default withRouter(FormCustomerLabel);
