
export const getStatisticsServices = (url, token, id) => () =>
  fetch(url + 'statistics/points/admin/' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': token
    }
  }).then(function (response) {
    return response.json();
  }).then(function (responser) {
    if (responser.ok) {
      return responser.data;
    }
    let error = [];
    error.push(responser);
    return error;
  }).catch((error) => {
    return error;
  });

export const getStatisticsProductsServices = (url, token, id) => () =>
  fetch(url + 'statistics/points/products/' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': token
    }
  }).then(function (response) {
    return response.json();
  }).then(function (responser) {
    if (responser.ok) {
      return responser.data;
    }
    let error = [];
    error.push(responser);
    return error;
  }).catch((error) => {
    return error;
  });


export const getStatisticsPerHourServices = (url, token, id) => () =>
  fetch(url + 'statistics/points/hourly/' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': token
    }
  }).then(function (response) {
    return response.json();
  }).then(function (responser) {
    if (responser.ok) {
      return responser.data;
    }
    let error = [];
    error.push(responser);
    return error;
  }).catch((error) => {
    return error;
  });

export function acceptPolicyService(url, token, id, obj) {

  return new Promise((resolve, reject) => {
    var respuesta = fetch(`${url}company/terms/${id}`, {
      method: 'PUT',
      body: JSON.stringify(obj),
      headers: new Headers({ 'Content-type': 'application/json', 'Authorization': token })
    }).then(function (response) {
      return response.json();
    }).then(function (responser) {
      return responser;
    }).catch((error) => {
      return error;
    });
    respuesta ? resolve(respuesta) : reject('error-');
  });
}
