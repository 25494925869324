import { agregarNuevoUserAdminService, buscarEmpresaPorUsernameService } from './../services/agregarNuevoUserAdminService';
import { URL_API } from './../../../constants';

export function agregarNuevoUserAdminAction(data, token) {
  return new Promise((resolve, reject) => {
    var response = agregarNuevoUserAdminService(URL_API, data, token);
    response ? resolve(response) : reject('error');
  });
}

export function buscarEmpresaPorUsernameAction(data) {
  return new Promise((resolve, reject) => {
    var response = buscarEmpresaPorUsernameService(URL_API, data);
    response ? resolve(response) : reject('error');
  });
}
