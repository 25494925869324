
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TableRender from '../../../globalComponents/table';

import { deleteCustomerAction, listCustomersAction } from './../actions';
import { getCustomersList } from './../selectors';

import ModalCustom from '../../../globalComponents/modal/modal';
import ModalError from './modalError';
import { CurrentUserIsActive } from './../../../helpers/capabilitiesControl';


function formatDate(date) {

  if (date.includes('T00:00:00.000Z')) {
    date = date.replace('T00:00:00.000Z', '');
    return date;
  }
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + (d.getDate()),
    year = (d.getFullYear());

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

class CustomersTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalTitle: '',
      modalContent: '',
      modalAcceptText: '',
      modal: false,
      modalTitleDel: '',
      modalContentDel: '',
      modalAcceptTextDel: '',
      modalDel: false,
      idEliminar: '',
    };
    this.deleteCustomers = this.deleteCustomers.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModalDelete = this.openModalDelete.bind(this);
  }

  openModal() {
    this.setState({ modal: true });
  }
  onCloseModal() {
    this.setState({
      modalDel: false
    });
  }
  closeModal() {
    this.setState({ modal: false });
  }
  openModalDelete(id) {
    this.setState({
      modalDel: true,
      idEliminar: id
    });
  }

  deleteCustomers(idUser) {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const token = JSON.parse(localStorage.getItem('token'));

    this.props.deleteCustomerAction(token, idUser).then(
      (resp) => {
        if (resp.payload[0]) {
          if (resp.payload[0].ok) {
            this.setState({
              modalTitle: 'Eliminado',
              modalContent: 'El usuario se ha eliminado correctamente',
              modalAcceptText: 'Aceptar'
            });
            this.openModal();
            this.props.listCustomersAction(token, loginData._id);
          } else if (resp.payload[0].error.name === 'TokenExpiredError') {
            // eslint-disable-next-line react/prop-types
            this.props.history.push('/admin/logout/true');
          } else {
            this.setState({
              modalTitle: 'Error al eliminar1',
              modalContent: 'verifique su conexion',
              modalAcceptText: 'Aceptar'
            });
            this.openModal();
          }
        } else {
          this.setState({
            modalTitle: 'Error al eliminar',
            modalContent: 'verifique su conexion',
            modalAcceptText: 'Aceptar'
          });
          this.openModal();
        }


      }
    ).catch(error => {
      if (error.name === 'TokenExpiredError') {
        // eslint-disable-next-line react/prop-types
        this.props.history.push('/admin/logout/true');
      }
    });

  }
  render() {
    const { data, search } = this.props;

    let DATA = {
      thead: [
        'Nombre', 'Cumpleaños', 'Numero de Tarjeta', 'Plan', '% del plan', 'ACCION'
      ],
      tbody: []
    };

    if (data) {
      data.forEach(element => {
        if (element._id) {

          let actions = <div>
            <Link to={'/admin/customer/' + element._id}>
              <img src="/assets/tablas/ver.svg" className="iconTabla-planes-SA" alt="iconVer" />
            </Link>
            <abbr>&nbsp;&nbsp;&nbsp;&nbsp;</abbr>
            <Link to={'/admin/edit_customer/' + element._id}>
              <img src="/assets/tablas/editar.svg" className="iconTabla-planes-SA" alt="iconVer" />
            </Link><abbr>&nbsp;&nbsp;&nbsp;&nbsp;</abbr>
            <Link to='/admin/list_customers' >
              <img onClick={() => this.openModalDelete(element._id)} src="/assets/tablas/eliminar.svg" className="iconTabla-planes-SA" alt="iconVer" />
            </Link>
          </div>;
          let completeName = element.name + ' ' + element.lastname;

          if (search) {
            if (completeName.includes(search.toLowerCase()) ||
              formatDate(element.birthday).includes(search.toLowerCase()) ||
              element.card_number.includes(search) ||
              element.card_number.toLowerCase().includes(search.toLowerCase()) ||
              element.membership.name.includes(search.toLowerCase()) ||
              element.membership.percent.includes(search.toLowerCase())) {
              if (CurrentUserIsActive()) {
                DATA.tbody.push([
                  completeName,
                  formatDate(element.birthday),
                  element.card_number,
                  element.membership.name,
                  element.membership.percent,
                  actions]);
              } else {
                DATA.tbody.push([
                  completeName,
                  formatDate(element.birthday),
                  element.card_number,
                  element.membership.name,
                  element.membership.percent,
                  '']);
              }

            }
          } else if (CurrentUserIsActive()) {
            DATA.tbody.push([
              completeName,
              formatDate(element.birthday),
              element.card_number,
              element.membership.name,
              element.membership.percent,
              actions]);
          } else {
            DATA.tbody.push([
              completeName,
              formatDate(element.birthday),
              element.card_number,
              element.membership.name,
              element.membership.percent,
              '']);
          }
        }
      });

    }

    return (
      <div>
        <ModalCustom
          open={this.state.modal}
          close={this.closeModal}
          tittle={this.state.modalTitle}
          contentModal={this.state.modalContent}
          onclickClose={this.closeModal}
          onclickAccept={this.closeModal}
          closeText={this.state.modalAcceptText}
        //acceptText={'Aceptar'}
        />
        <ModalError
          open={this.state.modalDel}
          close={this.onCloseModal}
          onclickClose={this.onCloseModal}
          acceptText={'Aceptar'}
          closeText={'Cancelar'}
          idAEliminar={this.state.idEliminar}
          nameAEliminar={'Desea eliminar'}
          consultarPlanesAdminAction={this.props.listCustomersAction}
        />
        <TableRender data={DATA} />
      </div>
    );
  }
}

CustomersTable.propTypes = {
  customersList: PropTypes.array,
  customersListrRefresh: PropTypes.array,
  deleteCustomerAction: PropTypes.func.isRequired,
  listCustomersAction: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  search: PropTypes.string
};

CustomersTable.defaultProps = {
  customersListrRefresh: []
};
const mapStateToProps = state => ({
  customersListrRefresh: getCustomersList(state)
});
export default withRouter(
  connect(
    mapStateToProps, {
      listCustomersAction,
      deleteCustomerAction
    })(CustomersTable));
