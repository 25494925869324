/* eslint-disable no-dupe-else-if */
import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import { menuState } from './menuAdmin';
import { usersState, userState } from './users';
import { usersAdminState } from './usersAdmin';
import { customersState, getcustomerState } from './customerAdmin';
import { membershipState } from './membership';
import { planesAdminState, planAdminState } from './planesAdmin';
import { getPointsState } from './points';
import {
  getPStatisticsState,
  getPStatisticsProductsState,
  getStatisticsPerHourState
} from './statistics';

export default combineReducers({
  menuState,
  usersState,
  userState,
  usersAdminState,
  customersState,
  getcustomerState,
  membershipState,
  planesAdminState,
  planAdminState,
  getPointsState,
  getPStatisticsState,
  getPStatisticsProductsState,
  getStatisticsPerHourState,
  form: reduxForm
});
