import {
  getStatisticsServices,
  getStatisticsProductsServices,
  getStatisticsPerHourServices,
  acceptPolicyService
} from '../services';
import {
  URL_API,
  GET_STATISTICS,
  GET_STATISTICS_PRODUCTS,
  GET_STATISTICS_PERHOUR
} from './../../../constants';
import { createAction } from 'redux-actions';

export const getStatisticsAction = createAction(GET_STATISTICS,
  (token, id) => getStatisticsServices(URL_API, token, id)());

export const getStatisticsProductsAction = createAction(GET_STATISTICS_PRODUCTS,
  (token, id) => getStatisticsProductsServices(URL_API, token, id)());

export const getStatisticsPerHourAction = createAction(GET_STATISTICS_PERHOUR,
  (token, id) => getStatisticsPerHourServices(URL_API, token, id)());

export function acceptPolicyAction(token, id, obj) {
  return new Promise((resolve, reject) => {
    var response = acceptPolicyService(URL_API, token, id, obj);
    response ? resolve(response) : reject('error');
  });
}
