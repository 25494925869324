import {
  actualizarUserAdminService,
  updatePasswordAdminService
} from '../services/actualizarUserAdminService';
import { URL_API } from './../../../constants';

export function actualizarUserAdminAction(data, id, token) {
  return new Promise((resolve, reject) => {
    var response = actualizarUserAdminService(URL_API, data, id, token);
    response ? resolve(response) : reject('error');
  });
}

export function updatePasswordAction(token, data) {
  return new Promise((resolve, reject) => {
    var response = updatePasswordAdminService(URL_API, token, data);
    response ? resolve(response) : reject('error');
  });
}
