import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import { TiInfoOutline } from 'react-icons/ti';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import { agregarPlanAdminAction } from './../actions/agregarPlanAdminAction';

import 'react-toastify/dist/ReactToastify.css';

class FormNewPlanAdmin extends Component {

  constructor(props){
    super(props);

    this.state = {
      name: '',
      percent: '',
      errors: {},
      errorSubmit: '',
      agregando: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.isSubmitting = this.isSubmitting.bind(this);
    this.volver = this.volver.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    const loginData = JSON.parse(localStorage.getItem('loginData'));

    let values = {
      name: this.state.name,
      percent: this.state.percent,
      id_company: loginData._id
    };
    this.validate(event, values);
  }

  validate(event, values) {
    let errors = {};

    if (!values.percent || values.percent==='' ) {
      errors.percent = 'Por favor, introduce el porcentaje.';
    } else if (values.percent > 100 || values.percent < 0) {
      errors.percent = 'Por favor, introduce un porcentaje válido.';
    }

    if (!values.name || values.name==='' ) {
      errors.name = 'Por favor, introduce el nombre del plan.';
    }

    this.setState({ errors });
    this.isSubmitting(event, errors, values);
  }

  isSubmitting(event, errors, values){
    event.preventDefault();
    this.setState({
      agregando: true
    });
    if (Object.keys(errors).length === 0 ) {
      const token = JSON.parse(localStorage.getItem('token'));
      const data = (JSON.stringify(values));

      return agregarPlanAdminAction(data, token).then(r => {
        if (r.ok) {

          toast.success(<div><IoIosCheckmarkCircle className='icon-toast' />{'Plan agregado correctamente.'}</div>, {
            position: toast.POSITION.TOP_RIGHT
          });

          this.setState({
            name: '',
            percent: '',
            errors: {},
            errorSubmit: '',
            agregando: false
          });
        } else
        if (!r.ok) {
          if(r.error){
            if(r.error === 401){
              this.props.history.push('/admin/logout/true');
            }
          } else if(r.error.message) {
            this.setState({
              errorSubmit: r.error.message,
              agregando: false
            });
          }
        }
      });
    }

  }

  volver(){
    this.props.history.push('/admin/planes/');
  }

  render() {
    return (
      <div className="row">
        <div className="col-11">
          <div className="m-t-2-planes-SA">
            <form method="post" className="formAddPlanes-SA m-b-15-planes-SA" id="formAddPlanes-SA" onSubmit={this.handleSubmit}>

              <div className="row justify-content-start">
                <div className="col-xl-12 col-lg-12 col-12">
                  <div className="form-group">
                    <div className="input-group input-group-addPlan-SA m-b-2rem-planes-SA">
                      <input type="text" id="name" name="name" value={this.state.name}
                        onChange={event => this.setState({ name: event.target.value })}
                        className={this.state.errors.name && ('input-errorLine')}/>
                      <label>Plan</label>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      {this.state.errors.name && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.name}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-start">
                <div className="col-xl-6 col-lg-6 col-12">
                  <div className="form-group">
                    <div className="input-group input-group-addPlan-SA m-b-8-planes-SA">
                      <input type="number" id="percent" name="percent" value={this.state.percent}
                        onChange={event => this.setState({ percent: event.target.value })}
                        className={this.state.errors.percent && ('input-errorLine')}/>
                      <label>% de puntos</label>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      {this.state.errors.percent && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.percent}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="fixed-bottom bottom-planes-SA p-b-3-planes-SA p-t-2-planes-SA">
            <div className="row align-items-end justify-content-end">
              <div className="col-xl-5 col-lg-6 col-md-8">
                <button type="button" className="btn-secondaryReward btn-addPlanForm-SA m-r-5-planes-SA" onClick={this.volver}> Volver </button>
                {
                  this.state.agregando ?
                    (
                      <button type="submit" form="formAddPlanes-SA" className="btn-primaryReward btn-addPlanForm-SA" disabled> Agregar </button>
                    ):
                    (
                      <button type="submit" form="formAddPlanes-SA" className="btn-primaryReward btn-addPlanForm-SA"> Agregar </button>
                    )
                }
              </div>
            </div>
          </div>
          <ToastContainer autoClose={5000}/>
        </div>
      </div>
    );
  }
}

FormNewPlanAdmin.propTypes = {
  history: PropTypes.object,
};

export default withRouter(FormNewPlanAdmin);
