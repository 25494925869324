import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';

import { deleteUserAction, listUsersAction } from './../actions';
import { getUsersList } from './../selectors';
import ModalCustom from './../../../globalComponents/modal/modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  IoIosCheckmarkCircle,
  IoIosCloseCircle
} from 'react-icons/io';

class ModalError extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalTitle: '',
      modalContent: '',
      modalAcceptText: '',
      modal: false,
    };
    this.eliminarUserAdmin = this.eliminarUserAdmin.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ modal: true });
  }
  closeModal() {
    this.setState({
      modal: false
    });
  }
  eliminarUserAdmin() {
    const token = JSON.parse(localStorage.getItem('token'));
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    this.props.close();

    this.props.deleteUserAction(token, loginData._id, this.props.idAEliminar).then(
      (resp) => {
        if (resp.payload[0]) {
          if (resp.payload[0].data) {
            if (resp.payload[0].data.ok) {
              /*this.setState({
                modalTitle: 'Eliminado',
                modalContent: 'El usuario se ha eliminado correctamente',
                modalAcceptText: 'Aceptar'
              });
              this.openModal();*/
              this.notifySuccess();
              this.props.listUsersAction(token, loginData._id);
            }
          } else if (resp.payload[0].error.name === 'TokenExpiredError') {
            // eslint-disable-next-line react/prop-types
            this.props.history.push('/admin/logout/true');
          }
        } else {
          /*this.setState({
            modalTitle: 'Error al eliminar',
            modalContent: 'verifique su conexion',
            modalAcceptText: 'Aceptar'
          });
          this.openModal();*/
          this.notifyError();
        }
      }
    );

  }

  notifySuccess = () => {
    toast.success(<div><IoIosCheckmarkCircle className='icon-toast' /><p className='text-success-cust'>El usuario se ha eliminado correctamente</p></div>, {
      position: toast.POSITION.TOP_RIGHT,
      className: 'success-toast-custom'
    });
  };

  notifyError = () => {
    toast.error(<div><IoIosCloseCircle className='icon-toast' /> Error al eliminar el usuario</div>, {
      position: toast.POSITION.TOP_RIGHT
    });
  };


  render() {

    const modalStyle = {
      overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)'
      },
      modal: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        color: '#000000',
        boxShadow: '0 0 0 rgba(0, 0, 0, 0.25)',
        borderTop: '5px solid #950024',
        borderBottom: '5px solid #950024',
        textAlign: 'center'
      }
    };

    return (
      <div>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} />
        <ModalCustom
          open={this.state.modal}
          close={this.closeModal}
          tittle={this.state.modalTitle}
          contentModal={this.state.modalContent}
          onclickClose={this.closeModal}
          onclickAccept={this.closeModal}
          closeText={this.state.modalAcceptText}
        //acceptText={'Aceptar'}
        />
        <Modal open={this.props.open} showCloseIcon={false} onClose={this.props.close} center styles={modalStyle}>
          <div className="container">
            <div className="content-ModalError-SA">
              {'¿Seguro que desea eliminarlo'}<br />
              {/*
            <span className="parrafoName-users-SA">{'"' + this.props.nameAEliminar + '"'}</span>{'?'}
            */}
            </div>
            {
              this.props.acceptText ? (
                <div className="row justify-content-md-center m-t-15-users-SA">
                  <div className="col-5 space-btn-r">
                    <button onClick={this.props.onclickClose} className="btn-secondaryReward btn-modalError-SA">{this.props.closeText}</button>
                  </div>
                  <div className="col-5 space-btn-l">
                    <button onClick={this.eliminarUserAdmin} className="btn-redReward btn-modalError-SA">{this.props.acceptText}</button>
                  </div>
                </div>
              ) : ('')
            }
          </div>
        </Modal>
      </div>
    );
  }
}

ModalError.propTypes = {
  nameAEliminar: PropTypes.string.isRequired,
  idAEliminar: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.any.isRequired,
  onclickClose: PropTypes.any.isRequired,
  acceptText: PropTypes.string,
  closeText: PropTypes.string,
  history: PropTypes.object,
  deleteUserAction: PropTypes.func.isRequired,
  listUsersAction: PropTypes.func.isRequired,
};

ModalError.defaultProps = {
  usersList: []
};

const mapStateToProps = state => ({
  usersList: getUsersList(state)
});

export default withRouter(
  connect(
    mapStateToProps, {
      listUsersAction,
      deleteUserAction
    })(ModalError));

