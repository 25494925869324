import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Nav from '../../menu/navBar';
import SideBar from '../../menu/sideBar';
import { consultarPlanesAdminAction, clearPlanesAdminAction} from './../actions/consultarPlanesAdminAction';
import { consultPlanesAdmin } from './../selectors';
import TablaPlanesAdmin from './../components/tablaPlanesAdmin';

class ListarPlanesAdminContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      search: ''
    };

    this.agregarPlan = this.agregarPlan.bind(this);
  }

  componentDidMount() {
    this.props.clearPlanesAdminAction();
    const token = JSON.parse(localStorage.getItem('token'));
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const idEmpresa = loginData._id;
    this.props.consultarPlanesAdminAction(idEmpresa, token).then(
      response => {
        if (response.payload.length > 0)
          if (response.payload[0].error === 401) {
            this.props.history.push('/admin/logout/true');
          }
      });
  }

  agregarPlan() {
    this.props.history.push('/admin/planes/nuevo');
  }

  render() {
    let planesAdminList = [];
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    let active = loginData.active.active;

    if (this.props.planesAdminList.length > 0) {
      planesAdminList = this.props.planesAdminList;
    }
    var demoData = {
      thead: [
        'Nombre', '%', 'Acciones'
      ],
      tbody: [
        planesAdminList
      ]
    };
    //console.log(this.props.planesAdminList);
    return (
      <div>
        <Nav />
        <div className='row custom-row' id='body-row'>
          <SideBar option={'planes'} />
        </div>
        <div className='col-11 float-right max-w-body'>
          <div className="row">
            <div className="col-11">
              <div className="row justify-content-between m-t-5-users-SA">
                <div className="col-md-5 content-buttonAddUsers-SA">
                  <button type="button" className="btn-primaryReward btn-addPlan-SA" onClick={this.agregarPlan} disabled={!active}>+ Agregar plan</button>
                </div>
                <div className='col-md-5 col-sm-12'>
                  <div className='row'>
                    <div className='d-inline div-search'>
                      <input type="text" id="search" name="search" placeholder="Buscar" value={this.state.search || ''}
                        onChange={event => this.setState({ search: event.target.value })}
                        className='input-form input-search input-search-SA' />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                    </div>
                    <svg role="img" title="icon1" className="icon-search-planes-SA iconBuscar-planes-SA">
                      <use href="/assets/tablas/icons8-search.svg#Capa_1" />
                    </svg>
                  </div>
                </div>
              </div>
              <hr className="linea-users-SA"></hr>
              <div className="row">
                {
                  Object.keys(this.props.planesAdminList).length > 0 ?
                    (
                      <TablaPlanesAdmin demoData={demoData}
                        consultarPlanesAdminAction={this.props.consultarPlanesAdminAction}
                        search={this.state.search}
                        active={active}>
                      </TablaPlanesAdmin>
                    ) :
                    (
                      <div></div>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListarPlanesAdminContainer.propTypes = {
  history: PropTypes.object,
  planesAdminList: PropTypes.array.isRequired,
  consultarPlanesAdminAction: PropTypes.func.isRequired,
  clearPlanesAdminAction: PropTypes.func.isRequired,
};

ListarPlanesAdminContainer.defaultProps = {
  planesAdminList: []
};

const mapStateToProps = state => ({
  planesAdminList: consultPlanesAdmin(state)
});
export default withRouter(connect(mapStateToProps, { consultarPlanesAdminAction,clearPlanesAdminAction })(ListarPlanesAdminContainer));
