export function updateCustomerService(url, token, idCustomer, obj) {

  return new Promise((resolve, reject) => {
    var respuesta = fetch(`${url}customers/${idCustomer}`, {
      method: 'PUT',
      body: JSON.stringify(obj),
      headers: new Headers({ 'Content-type': 'application/json', 'Authorization': token })
    }).then(function (response) {
      return response.json();
    }).then(function (responser) {
      return responser;
    }).catch((error) => {
      return error;
    });
    respuesta ? resolve(respuesta) : reject('error-');
  });
}
export const getCustomerService = (url, token, idCustomer) => () =>
  fetch(url + 'customers/' + idCustomer, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': token
    }
  }).then(function (response) {
    return response.json();
  }).then(function (responser) {
    if (responser.ok) {
      return responser.data;
    }
    const datos = [];
    datos.push(responser.error);
    return datos;
  }).catch((error) => {
    return error;
  });

export function clearCustomerService() {
  return {};
}
