import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Nav from '../../menu/navBar';
import SideBar from '../../menu/sideBar';
import FormEditUsersAdmin from './../components/formEditUsersAdmin';
import { getUserAdminAction } from './../actions/getUserAdminAction';
import { getUserAdmin } from './../selectors';
import './../style.css';

class profileAdminEdit extends Component {

  componentDidMount() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));

    const data = {
      username: loginData.username
    };
    this.props.getUserAdminAction(data);
  }

  render() {
    const userAdmin = this.props.userAdmin;

    return (
      <div>
        <Nav />
        <div className='row custom-row' id='body-row'>
          <SideBar option={'rootuser'} />
        </div>

        <div className='col-11 float-right max-w-body'>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='row'>
            <p className=' position-link'>
              <Link to={'/admin/profile'} className='position-link'>Editar perfil</Link>
            </p>
          </div>
          <div className='row'>
            <p className='info-text'>Realiza los cambios que desees.</p>
          </div>

          {
            userAdmin.length === 1 ?
              (
                <FormEditUsersAdmin userAdmin={userAdmin[0]}></FormEditUsersAdmin>
              ) :
              (
                <div></div>
              )
          }

        </div>
      </div>
    );
  }
}

profileAdminEdit.propTypes = {
  userAdmin: PropTypes.array.isRequired,
  getUserAdminAction: PropTypes.func.isRequired,
};

profileAdminEdit.defaultProps = {
  userAdmin: []
};

const mapStateToProps = state => ({
  userAdmin: getUserAdmin(state)
});

export default withRouter(connect(mapStateToProps, { getUserAdminAction })(profileAdminEdit));
