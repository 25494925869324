import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Nav from './../../menu/navBar';
import SideBar from './../../menu/sideBar';
import './../style.css';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { TiInfoOutline } from 'react-icons/ti';
import { insertCustomerAction, listMembershipsAction } from './../actions';
import { getMembershipsList } from './../selectors';

//import ModalCustom from './../../../globalComponents/modal/modal';
import { CurrentUserIsActive } from './../../../helpers/capabilitiesControl';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  IoIosCheckmarkCircle,
  IoIosCloseCircle
} from 'react-icons/io';

class AddCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      lastname: '',
      birthday: '',
      gender: '',
      street: '',
      neigboorhood: '',
      number: '',
      county: '',
      _state: '',
      zip: '',
      telephone: '',
      email: '',
      card_number: '',
      id_membership: 0,
      membership_percent: 0,
      membership_name: '',
      errors: '',
      modal: false,
      value: '',
      modalContent: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.isSubmitting = this.isSubmitting.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeGender = this.handleChangeGender.bind(this);
  }

  componentDidMount() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const token = JSON.parse(localStorage.getItem('token'));
    this.props.listMembershipsAction(token, loginData._id).then(
      response => {
        if (response.payload[0].name === 'TokenExpiredError') {
          // eslint-disable-next-line react/prop-types
          this.props.history.push('/admin/logout/true');
        }
      }
    );
  }

  openModal() {
    this.setState({ modal: true });
  }
  closeModal() {
    this.setState({ modal: false });
  }
  handleSubmit(event) {
    event.preventDefault();

    let values = {
      name: this.state.name,
      lastname: this.state.lastname,
      birthday: this.state.birthday,
      gender: this.state.gender,
      card_number: this.state.card_number,
      id_membership: this.state.id_membership
    };

    this.validate(event, values);
  }

  validate(event, values) {
    let errors = {};
    if (!values.name || values.name === '') {
      errors.name = 'Por favor, introduce el nombre.';
    }

    if (!values.lastname || values.lastname === '') {
      errors.lastname = 'Por favor, introduce los apellidos';
    }
    if (!values.birthday || values.birthday === '') {
      errors.birthday = 'Por favor, una fecha valida';
    }
    if (!values.gender || values.gender === '') {
      errors.gender = 'Por favor, seleccione un genero';
    }
    if (!values.card_number || values.card_number === '') {
      errors.card_number = 'Por favor, introduce el numero de tarjeta';
    }
    if (values.card_number.length < 4) {
      errors.card_number = 'Por favor, introduce por lo menos 4 caracteres';
    }
    if (values.card_number.length > 16) {
      errors.card_number = 'Por favor, introduce menos de 17 caracteres';
    }
    if (!values.id_membership || values.id_membership === 0 || values.id_membership.length < 10) {
      errors.membership_name = 'Por favor, selecciona un plan valido';
    }

    this.setState({ errors });
    this.isSubmitting(event, errors);
  }

  isSubmitting(event, errors) {
    event.preventDefault();

    if (Object.keys(errors).length === 0) {
      const loginData = JSON.parse(localStorage.getItem('loginData'));
      const token = JSON.parse(localStorage.getItem('token'));
      let jsonData = {};
      if (this.state.email !== '') {
        jsonData = {
          'name': this.state.name,
          'lastname': this.state.lastname,
          'birthday': this.state.birthday,
          'gender': this.state.gender,
          'address': {
            'street': this.state.street,
            'neigboorhood': this.state.neigboorhood,
            'number': this.state.number,
            'county': this.state.county,
            'state': this.state._state,
            'zip': this.state.zip
          },
          'telephone': this.state.telephone,
          'email': this.state.email,
          'card_number': this.state.card_number,
          'membership': {
            'id_membership': this.state.id_membership,
            'percent': this.state.membership_percent,
            'name': this.state.membership_name
          },
          'id_company': loginData._id
        };
      } else {
        jsonData = {
          'name': this.state.name,
          'lastname': this.state.lastname,
          'birthday': this.state.birthday,
          'gender': this.state.gender,
          'address': {
            'street': this.state.street,
            'neigboorhood': this.state.neigboorhood,
            'number': this.state.number,
            'county': this.state.county,
            'state': this.state._state,
            'zip': this.state.zip
          },
          'telephone': this.state.telephone,
          'card_number': this.state.card_number,
          'membership': {
            'id_membership': this.state.id_membership,
            'percent': this.state.membership_percent,
            'name': this.state.membership_name
          },
          'id_company': loginData._id
        };
      }


      return insertCustomerAction(token, loginData._id, jsonData).then(r => {

        if (r.code === 404) {
          let errors = {};
          errors.add = 'Lo sentimos, ah ocurrido un error al registrar los datos';
          this.setState({
            errorLogin: errors,
            modalContent: 'Error al registrar los datos'
          });
          this.notifyError();

        } else if (r.ok) {
          this.setState({
            name: '',
            lastname: '',
            birthday: '',
            gender: '',
            street: '',
            neigboorhood: '',
            number: '',
            county: '',
            _state: '',
            zip: '',
            telephone: '',
            email: '',
            card_number: '',
            id_membership: '',
            membership_percent: '',
            membership_name: '',
            errors: '',
          });
          this.notifySuccess();

        } else if (r.errors) {
          let errors = {};
          errors.add = 'Error al registrar, verifique los datos';
          this.setState({
            errors: errors,
            modalContent: 'Error al registrar, verifique los datos'
          });
          this.notifyError();

        } else if (r.error.name === 'TokenExpiredError') {
          // eslint-disable-next-line react/prop-types
          this.props.history.push('/admin/logout/true');
        } else if (r.error) {
          let errors = {};
          errors.add = r.error.name;
          this.setState({
            errors: errors,
            modalContent: errors.add
          });
          this.notifyError();

        }
      });
    }
  }

  handleChange(event) {
    this.setState({ id_membership: event.target.value });
    this.props.memberships.forEach(element => {
      if (element._id === event.target.value) {
        this.setState({
          membership_name: element.name,
          membership_percent: element.percent
        });
      }
    });
  }

  handleChangeGender(event) {
    this.setState({ gender: event.target.value });
  }

  notifySuccess = () => {
    toast.success(<div><IoIosCheckmarkCircle className='icon-toast' />&nbsp;&nbsp;&nbsp;&nbsp;El usuario se agreg&oacute; correctamente</div>, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  notifyError = () => {
    toast.error(<div><IoIosCloseCircle className='icon-toast' />&nbsp;&nbsp;&nbsp;&nbsp;{this.state.modalContent}</div>, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  render() {

    return (
      <div>
        <Nav />
        <div className='row custom-row' id='body-row'>
          <SideBar option='users' />
        </div>
        <div className='col-11 float-right max-w-body'>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='custom-space-row'></div>
          <div className='row'>
            <p className=' position-link'><Link to='/admin/list_customers' className='position-link'>Clientes</Link> / <Link to='/admin/add_customers' className='position-link'>Nuevo Cliente</Link></p>
          </div>
          <div className='row'>
            <p className='info-text'>Ingresa los datos del nuevo cliente para crearlo</p>
          </div>

          <ToastContainer autoClose={5000} />

          <Container fluid>
            <div className='row'>
              <div className='col-11'>
                <form className="formReward formReward-add" id="formAddDemo" onSubmit={this.handleSubmit}>
                  <Form.Row>
                    <Col>
                      {this.state.errors.add && (
                        <span className="span-errorMessage error-message-custom"><TiInfoOutline></TiInfoOutline> {this.state.errors.add}</span>
                      )}
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs='4' lg='4' md='8' sm='12'>
                      <Form.Group className="group margin-l-40p">
                        <input type="text" id="nombre" name="nombre" value={this.state.name || ''}
                          onChange={event =>
                            this.setState({ name: event.target.value })}
                          className={(this.state.errors.name && ('input-errorLine')) + ' input-form'}
                          minLength="2" maxLength="40" />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Nombre*</Form.Label>
                        {this.state.errors.name && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.name}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs='6' lg='6' md='8' sm='12'>
                      <Form.Group className="group margin-l-40p">
                        <input type="text" id="apellidos" name="apellidos" value={this.state.lastname || ''}
                          onChange={event =>
                            this.setState({ lastname: event.target.value })}
                          className={(this.state.errors.lastname && ('input-errorLine')) + ' input-form'}
                          minLength="2" maxLength="40" />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Apellidos*</Form.Label>
                        {this.state.errors.lastname && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.lastname}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col></Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs='4' lg='4' md='8' sm='12'>
                      <Form.Group className="group margin-l-40p">
                        <input type="date" id="nacimiento" name="nacimiento" value={this.state.birthday || ''}
                          onChange={event =>
                            this.setState({ birthday: event.target.value })}
                          className={(this.state.errors.birthday && ('input-errorLine')) + ' input-form'}
                          minLength="2" maxLength="40" />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Fecha de nacimiento*</Form.Label>
                        {this.state.errors.birthday && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.birthday}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs='4' lg='4' md='8' sm='12'>
                      <Form.Group className="group margin-l-40p">
                        <select className='select-custom' value={this.state.gender} onChange={this.handleChangeGender}
                        >
                          <option value="" disabled></option>
                          <option value="Masculino" >Masculino</option>
                          <option value="Femenino" >Femenino</option>
                        </select>
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Genero*</Form.Label>
                        {this.state.errors.gender && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.gender}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs='4' lg='4' md='8' sm='12'>
                      <Form.Group className="group margin-l-40p">
                        <input type="text" id="domicilio" name="domicilio" value={this.state.street || ''}
                          onChange={event =>
                            this.setState({ street: event.target.value })}
                          className={(this.state.errors.street && ('input-errorLine')) + ' input-form'}
                          minLength="2" maxLength="40" />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>domicilio</Form.Label>
                        {this.state.errors.street && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.street}</span>
                        )}
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs='4' lg='4' md='8' sm='12'>
                      <Form.Group className="group margin-l-40p">
                        <input type="text" id="numero" name="numero" value={this.state.number || ''}
                          onChange={event =>
                            this.setState({ number: event.target.value })}
                          className={(this.state.errors.number && ('input-errorLine')) + ' input-form'}
                          minLength="1" maxLength="100" />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Número</Form.Label>
                        {this.state.errors.number && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.number}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs='4' lg='4' md='8' sm='12'>
                      <Form.Group className="group margin-l-40p">
                        <input type="text" id="zona" name="zona" value={this.state.neigboorhood || ''}
                          onChange={event =>
                            this.setState({ neigboorhood: event.target.value })}
                          className={(this.state.errors.neigboorhood && ('input-errorLine')) + ' input-form'}
                          minLength="2" maxLength="40" />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Zona</Form.Label>
                        {this.state.errors.neigboorhood && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.neigboorhood}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs='4' lg='4' md='8' sm='12'>
                      <Form.Group className="group margin-l-40p">
                        <input type="text" id="" name="" value={this.state.county || ''}
                          onChange={event =>
                            this.setState({ county: event.target.value })}
                          className={(this.state.errors.county && ('input-errorLine')) + ' input-form'}
                          minLength="2" maxLength="40" />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Pais</Form.Label>
                        {this.state.errors.county && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.county}</span>
                        )}
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs='4' lg='4' md='8' sm='12'>
                      <Form.Group className="group margin-l-40p">
                        <input type="text" id="estado" name="estado" value={this.state._state || ''}
                          onChange={event =>
                            this.setState({ _state: event.target.value })}
                          className={(this.state.errors._state && ('input-errorLine')) + ' input-form'}
                          minLength="2" maxLength="40" />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Estado</Form.Label>
                        {this.state.errors._state && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors._state}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs='4' lg='4' md='8' sm='12'>
                      <Form.Group className="group margin-l-40p">
                        <input type="text" id="codigo" name="codigo" value={this.state.zip || ''}
                          onChange={event =>
                            this.setState({ zip: event.target.value })}
                          className={(this.state.errors.zip && ('input-errorLine')) + ' input-form'}
                          minLength="2" maxLength="40" />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Codigo postal</Form.Label>
                        {this.state.errors.zip && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.zip}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs='4' lg='4' md='8' sm='12'>
                      <Form.Group className="group margin-l-40p">
                        <input type="text" id="telefono" name="telefono" value={this.state.telephone || ''}
                          onChange={event =>
                            this.setState({ telephone: event.target.value })}
                          className={(this.state.errors.telephone && ('input-errorLine')) + ' input-form'}
                          minLength="2" maxLength="40" />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Telefono</Form.Label>
                        {this.state.errors.telephone && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.telephone}</span>
                        )}
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs='4' lg='4' md='8' sm='12'>
                      <Form.Group className="group margin-l-40p">
                        <input type="text" id="correo" name="correo" value={this.state.email || ''}
                          onChange={event =>
                            this.setState({ email: event.target.value })}
                          className={(this.state.errors.email && ('input-errorLine')) + ' input-form'}
                          minLength="2" maxLength="40" />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Correo electronico</Form.Label>
                        {this.state.errors.email && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.email}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs='4' lg='4' md='8' sm='12'>
                      <Form.Group className="group margin-l-40p">
                        <input type="text" id="tarjeta" name="tarjeta" value={this.state.card_number || ''}
                          onChange={event =>
                            this.setState({ card_number: event.target.value })}
                          className={(this.state.errors.card_number && ('input-errorLine')) + ' input-form'}
                          minLength="4" maxLength="16" />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Numero de tarjeta*</Form.Label>
                        {this.state.errors.card_number && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.card_number}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs='4' lg='4' md='8' sm='12'>
                      <Form.Group className="group margin-l-40p">
                        <select className='select-custom' value={this.state.id_membership || ''} onChange={this.handleChange}
                        >
                          <option value="" disabled></option>
                          {
                            this.props.memberships.map((c, index) =>
                              <option key={'option' + index} value={c._id} >{c.name}</option>
                            )
                          }
                        </select>
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <Form.Label className='custom-label-form'>Plan*</Form.Label>
                        {this.state.errors.membership_name && (
                          <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.membership_name}</span>
                        )}
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row className="m-t-4rem">
                    <Col lg={7} xs={7} md={4} sm={2}></Col>
                    {CurrentUserIsActive() ? (
                      <Col>
                        <div className=''>
                          <div className='fixed-bottom bottom-planes-SA p-b-3-planes-SA p-t-2-planes-SA'>
                            <div className='row align-items-end justify-content-end'>
                              <div className='col-xl-5 col-lg-6 col-md-8'>
                                <Col className='d-inline'>
                                  <Link to='/admin/list_customers' className='btn-secondaryReward btn-custom-size back-btn'>Volver</Link>
                                </Col>
                                <Col className='d-inline'>
                                  <button className='btn-primaryReward btn-addPlanForm-SA' type='submit'>Añadir</button>
                                </Col>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ) : ('')}
                  </Form.Row>
                </form>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

AddCustomer.propTypes = {
  listMembershipsAction: PropTypes.func.isRequired,
  memberships: PropTypes.array.isRequired
};
AddCustomer.defaultProps = {
  memberships: []
};

const mapStateToProps = state => ({
  memberships: getMembershipsList(state)
});
export default withRouter(connect(mapStateToProps, { listMembershipsAction })(AddCustomer));
