import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './../style.css';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { updatePasswordUsersAction } from './../actions';
//import ModalCustom from './../../../globalComponents/modal/modal';
import { CurrentUserIsActive } from './../../../helpers/capabilitiesControl';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  IoIosCheckmarkCircle,
  IoIosCloseCircle
} from 'react-icons/io';

class Showuserinf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changePass: false,
      newPass: '',
      errors: {},
      actualizado: false,
      modal: false,
      modalTitle: '',
      modalContent: '',
      modalAcceptText: '',
      textUpdate: 'Aceptar'
    };
    this.changePass = this.changePass.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.isSubmitting = this.isSubmitting.bind(this);
  }
  changePass() {
    let textUp = '';
    if (this.state.changePass) {
      textUp = 'Aceptar';
    } else {
      textUp = 'Actualizar';
    }
    this.setState({
      changePass: !this.state.changePass,
      textUpdate: textUp
    });
  }
  closeModal() {
    this.setState({ modal: false });
  }
  onCloseModal() {
    this.setState({ modal: true });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.changePass) {
      let values = {
        newPass: this.state.newPass
      };

      this.validate(event, values);
    } else {
      // eslint-disable-next-line react/prop-types
      this.props.history.push('/admin/list_users');
    }
  }
  validate(event, values) {
    let errors = {};
    if (this.state.changePass) {
      if (!values.newPass || values.newPass === '') {
        errors.newPass = 'Por favor, introduce una contraseña.';
      }
    }

    this.setState({ errors });
    this.isSubmitting(event, errors);
  }

  isSubmitting(event, errors) {
    event.preventDefault();

    if (Object.keys(errors).length === 0) {
      const token = JSON.parse(localStorage.getItem('token'));
      const loginData = JSON.parse(localStorage.getItem('loginData'));

      //eslint-disable no-else-return
      if (this.state.changePass) {
        let newdata = {
          id: loginData._id,
          password: this.state.newPass,
          userId: this.props.match.params.userId
        };

        return updatePasswordUsersAction(token, loginData._id, newdata).then(
          response => {

            if (response.ok) {
              this.setState({
                actualizado: true,
                changePass: false,
                newPass: '',
                modalContent: 'El usuario se actualizo correctamente',
                modal: true,
                textUpdate: 'Aceptar'
              });
              this.notifySuccess();

            } else if (response.error.name === 'TokenExpiredError') {
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/admin/logout/true');
            } else {
              this.setState({
                modal: true,
                modalContent: 'Error al actualizar, intente mas tarde'
              });
              this.notifyError();

            }
          }
        );

      }
      this.setState({
        actualizado: true
      });
    }
  }

  notifySuccess = () => {
    toast.success(<div><IoIosCheckmarkCircle className='icon-toast' />{this.state.modalContent}</div>, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  notifyError = () => {
    toast.error(<div><IoIosCloseCircle className='icon-toast' />{this.state.modalContent}</div>, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  render() {
    const { data } = this.props;

    let username = '';
    let password = '********';
    let role = '';
    if (data[0]) {
      if (data[0].username) {
        username = data[0].username;
      }
      if (data[0].role) {
        role = data[0].role;
      }
    }

    return (
      <div className='col-11 float-right max-w-body'>
        <div className='custom-space-row'></div>
        <div className='custom-space-row'></div>
        <div className='custom-space-row'></div>
        <div className='row'>
          <p className=' position-link'><Link to='/admin/list_users' className='position-link'>Usuarios</Link> / <Link to={'/admin/SeeUser/' + this.props.match.params.userId} className='position-link'>Ver Usuario</Link></p>
        </div>
        <div className='row'>
          <p className='info-text'>Visualiza y/o edita los datos de este usaurio</p>
        </div>

        <ToastContainer autoClose={5000} />

        <Container fluid className='text-align-left info-color'>
          <form method="post" className="" id="form-change-pass-user" onSubmit={this.handleSubmit}>
            <div className='custom-space-row'></div>
            <div className='custom-space-row'></div>
            <div className='custom-space-row'></div>
            <div className='row'>
              <div className='col-11'>
                <Row>
                  <Col>
                    <p>Nombre de usuario:  {username}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Contraseña:  {!this.state.changePass ? (<abbr>{password}</abbr>) : ('')} <abbr>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</abbr><Link to={'/admin/SeeUser/' + this.props.match.params.userId} onClick={this.changePass}>[Cambiar]</Link></p>
                    <Col lg={5} xs={5} md={12} sm={12} length='4'>
                      {
                        this.state.changePass ? (
                          <input type="password" id="password" name="password" value={this.state.newPass || ''}
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            onChange={event => this.setState({ newPass: event.target.value })} required
                            className={' input-form custom-input ' + (this.state.errors.newPass && ('input-errorLine'))} />
                        ) : ('')
                      }
                    </Col>
                  </Col>
                </Row>
                {
                  this.state.changePass ? (
                    <Form.Row>
                      <Col lg={5} xs={5} md={12} sm={12} length='4'><p className='text-justify text-patt'>La contraseña debe tener minimo 8 caracteres, al menos un dígito y al menos una mayúscula, se aceptan caracteres especiales.</p></Col>
                    </Form.Row>) : ('')
                }
                <Row>
                  <Col>
                    <p>Rol:  {role}</p>
                  </Col>
                </Row>

                <Row className="m-t-8rem">
                  <Col lg={7} xs={7} md={4} sm={2}></Col>
                  {CurrentUserIsActive()?(
                    <Col>
                      <div className=''>
                        <div className='fixed-bottom bottom-planes-SA p-b-3-planes-SA p-t-2-planes-SA'>
                          <div className='row align-items-end justify-content-end'>
                            <div className='col-xl-5 col-lg-6 col-md-8'>
                              <Col className='d-inline m-l-8w'>
                                <Link to='/admin/list_users' className='btn-secondaryReward btn-custom-size  back-btn'>Volver</Link>
                              </Col>
                              <Col className='d-inline'>
                                <button className='btn-primaryReward btn-custom-size' type='submit'>{this.state.textUpdate}</button>
                              </Col>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ):(
                    <Col>
                      <div className=''>
                        <div className='fixed-bottom bottom-planes-SA p-b-3-planes-SA p-t-2-planes-SA'>
                          <div className='row align-items-end justify-content-end'>
                            <div className='col-xl-5 col-lg-6 col-md-8'>
                              <Col className='d-inline m-l-8w'>
                                <Link to='/admin/list_users' className='btn-secondaryReward btn-custom-size  back-btn'>Volver</Link>
                              </Col>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}

                </Row>

              </div>
            </div>
          </form>

        </Container>
      </div>
    );
  }
}

Showuserinf.propTypes = {
  data: PropTypes.array.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string.isRequired,
    })
  })
};
Showuserinf.defaultProps = {
  data: [],
};

export default withRouter(Showuserinf);
