/* eslint-disable no-dupe-else-if */
import React, { Component } from 'react';
import DemoForm from './../components/demoForm';

class FormDemo extends Component {

  render() {
    return (
      <DemoForm></DemoForm>
    );
  }
}

FormDemo.propTypes = {};

export default (FormDemo);
