import { URL_API, LIST_MEMBERSHIPS } from './../../../constants';
import { insertCustomerService, getgetMembershipService } from './../services';
import { createAction } from 'redux-actions';

export function insertCustomerAction(token, id, obj) {
  return new Promise((resolve, reject) => {
    var response = insertCustomerService(URL_API, token, id, obj);
    //var response = updateUserId(URL_BASE, id, data);
    response ? resolve(response) : reject('error');
  });
}


export const listMembershipsAction = createAction(LIST_MEMBERSHIPS,
  (token, id) => getgetMembershipService(URL_API, token, id)());
