import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import ModalError from './modalError';

class TablaPlanesAdmin extends Component {

  constructor(props) {
    super(props);

    this.state={
      eliminado: false,
      idAEliminar: '',
      nameAEliminar: ''
    };

    this.verPlan = this.verPlan.bind(this);
    this.editarPlan = this.editarPlan.bind(this);
    this.eliminarPlan = this.eliminarPlan.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  verPlan(idPlan) {
    this.props.history.push('/admin/planes/see/'+idPlan);
  }

  editarPlan(idPlan){
    this.props.history.push('/admin/planes/edit/'+idPlan);
  }

  eliminarPlan(idPlan, name){
    this.setState({
      eliminado: true,
      idAEliminar: idPlan,
      nameAEliminar: name
    });
  }

  onCloseModal(){
    this.setState({
      eliminado: false
    });
  }


  render() {
    let {demoData, search, active} = this.props;

    return (
      <div className='col-12'>
        <table className="tabla-users-SA m-b-10-planes-SA">
          <thead className="tablaHead-users-SA">
            <tr className="tr-head">
              {
                demoData ? (
                  demoData.thead.length > -1 ? (
                    demoData.thead.map((th, index) =>
                      <th key={index + 'th-table'}>{th}</th>
                    )
                  ) : (<th>no data</th>)
                ) : ('')
              }
            </tr>
          </thead>
          <tbody className="tablaBody-users-SA">
            {
              demoData ? (
                demoData.tbody.length > -1 ? (
                  demoData.tbody[0].map((tr, index) => //console.log(tr)
                    search ?
                      (
                        (tr.name.toLowerCase().includes(search.toLowerCase()) || tr.percent.toLowerCase().includes(search.toLowerCase())) ?
                          (
                            <tr key={index + 'th-table'}>
                              <td key={'td1-'+tr._id}>{tr.name}</td>
                              <td key={'td2-'+tr._id}>{tr.percent}</td>
                              {
                                active ?
                                  (
                                    <td key={'td4-'+tr._id}>
                                      <img src="/assets/tablas/ver.svg" className="iconTabla-planes-SA" alt="iconVer" onClick={this.verPlan.bind(this, tr._id)}/>
                                      <img src="/assets/tablas/editar.svg" className="iconTabla-planes-SA" alt="iconVer" onClick={this.editarPlan.bind(this, tr._id)}/>
                                      <img src="/assets/tablas/eliminar.svg" className="iconTabla-planes-SA" alt="iconVer" onClick={this.eliminarPlan.bind(this, tr._id, tr.name)}/>
                                    </td>
                                  ) :
                                  (
                                    <td key={'td4-'+tr._id}>
                                      <img src="/assets/tablas/ver.svg" className="iconTabla-planes-SA icon-disabled-SA" alt="iconVer"/>
                                      <img src="/assets/tablas/editar.svg" className="iconTabla-planes-SA icon-disabled-SA" alt="iconVer"/>
                                      <img src="/assets/tablas/eliminar.svg" className="iconTabla-planes-SA icon-disabled-SA" alt="iconVer"/>
                                    </td>
                                  )
                              }
                            </tr>
                          ):
                          (
                            <tr key={index + 'th-table'}></tr>
                          )
                      ) :
                      (
                        <tr key={index + 'th-table'}>
                          <td key={'td1-'+tr._id}>{tr.name}</td>
                          <td key={'td2-'+tr._id}>{tr.percent}</td>
                          {
                            active ?
                              (
                                <td key={'td4-'+tr._id}>
                                  <img src="/assets/tablas/ver.svg" className="iconTabla-planes-SA" alt="iconVer" onClick={this.verPlan.bind(this, tr._id)}/>
                                  <img src="/assets/tablas/editar.svg" className="iconTabla-planes-SA" alt="iconVer" onClick={this.editarPlan.bind(this, tr._id)}/>
                                  <img src="/assets/tablas/eliminar.svg" className="iconTabla-planes-SA" alt="iconVer" onClick={this.eliminarPlan.bind(this, tr._id, tr.name)}/>
                                </td>
                              ) :
                              (
                                <td key={'td4-'+tr._id}>
                                  <img src="/assets/tablas/ver.svg" className="iconTabla-planes-SA icon-disabled-SA" alt="iconVer"/>
                                  <img src="/assets/tablas/editar.svg" className="iconTabla-planes-SA icon-disabled-SA" alt="iconVer"/>
                                  <img src="/assets/tablas/eliminar.svg" className="iconTabla-planes-SA icon-disabled-SA" alt="iconVer"/>
                                </td>
                              )
                          }
                        </tr>
                      )
                  )
                ) : (<tr>no existen datos</tr>)
              ) : ('')
            }
          </tbody>
        </table>
        <ModalError
          open={this.state.eliminado}
          close={this.onCloseModal}
          onclickClose={this.onCloseModal}
          acceptText={'Aceptar'}
          closeText={'Cancelar'}
          idAEliminar={this.state.idAEliminar}
          nameAEliminar={this.state.nameAEliminar}
          consultarPlanesAdminAction={this.props.consultarPlanesAdminAction}
          active={active}
        />
      </div>
    );
  }
}

TablaPlanesAdmin.propTypes = {
  history: PropTypes.object,
  demoData: PropTypes.object.isRequired,
  consultarPlanesAdminAction: PropTypes.func.isRequired,
  search: PropTypes.string,
  active: PropTypes.bool.isRequired,
};

export default withRouter(TablaPlanesAdmin);
