import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FaCheck } from 'react-icons/fa';

class VisualizarPlanAdmin extends Component {

  constructor(props) {
    super(props);

    this.state = {
      idPlan: props.planAdmin._id,
      name: props.planAdmin.name,
      percent: props.planAdmin.percent,
      actualizarPlanAuto: props.planAdmin.auto_update.active || props.planAdmin.auto_degrade.active,
      visitasH: props.planAdmin.auto_update.conditions.visits.active || props.planAdmin.auto_degrade.conditions.visits.active,
      puntosH: props.planAdmin.auto_update.conditions.points.active || props.planAdmin.auto_degrade.conditions.points.active,
      comprasH: props.planAdmin.auto_update.conditions.purchase_amount.active || props.planAdmin.auto_degrade.conditions.purchase_amount.active,
      cantidadHV: props.planAdmin.auto_update.conditions.visits.required_amount,
      cantidadHP: props.planAdmin.auto_update.conditions.points.required_amount,
      cantidadHC: props.planAdmin.auto_update.conditions.purchase_amount.required_amount,
      cantidadBV: props.planAdmin.auto_degrade.conditions.visits.required_amount,
      cantidadBP: props.planAdmin.auto_degrade.conditions.points.required_amount,
      cantidadBC: props.planAdmin.auto_degrade.conditions.purchase_amount.required_amount,
      rangoDiasH: props.planAdmin.auto_update.days,
      rangoDiasB: props.planAdmin.auto_degrade.days,
      idPlanH: props.planAdmin.auto_update.id_plan_update,
      idPlanB: props.planAdmin.auto_degrade.id_plan_degrade,
      errors: {},
      errorSubmit: '',
      actualizado: false,
      minValue: 0,
      maxValue: 0,
      disableMax: false,
      disableMin: false
    };

    this.volver = this.volver.bind(this);
  }

  componentDidMount() {

    let minValue = 0;
    let maxValue = 0;
    let percents = [];
    for (let i = 0; i < this.props.planesAdminList.length; i++) {
      if (i < this.props.planesAdminList.length) {
        percents.push(parseFloat(this.props.planesAdminList[i].percent));
      }
    }
    minValue = Math.min(...percents);
    maxValue = Math.max(...percents);

    if (parseFloat(this.state.percent) === minValue) {
      this.setState({ disableMin: true });
    }
    if (parseFloat(this.state.percent) === maxValue) {
      this.setState({ disableMax: true });
    }
    this.setState({ minValue: minValue, maxValue: maxValue });
  }

  volver() {
    this.props.history.push('/admin/planes');
  }

  render() {
    const planesAdminList = this.props.planesAdminList;
    let planAutoUpdate = '';
    let planAutoDegrade = '';
    for (let i = 0; planesAdminList.length > i; i++) {
      if (planesAdminList[i]._id === this.state.idPlanH) {
        planAutoUpdate = planesAdminList[i].name;
      }
      if (planesAdminList[i]._id === this.state.idPlanB) {
        planAutoDegrade = planesAdminList[i].name;
      }
    }
    return (
      <div className="row">
        <div className="col-11">
          <div className="m-t-5-planes-SA">
            <div className="contentVerPlan-SA">

              <div className="row justify-content-between m-b-3-planes-SA">
                <div className="col-xl-7 col-lg-7 col-7">
                  <span className="txt-verPlan-SA">Plan: </span>
                  <span className="txt-verPlan-SA"> {this.state.name || ''} </span>
                </div>
                <div className="col-xl-4 col-lg-4 col-4">
                  <span className="txt-verPlan-SA">Porcentaje: </span>
                  <span className="txt-verPlan-SA"> {this.state.percent + '%' || ''} </span>
                </div>
              </div>

              <div className="row justify-content-start align-items-center">
                <div className="col-xl-12 col-lg-12 col-sm-12">
                  <span className="txt-verPlan-SA">Actualizar plan automáticamente: </span>
                  <span className="textPlanAuto-planes-SA">
                    {
                      this.state.actualizarPlanAuto ?
                        ('Activado') :
                        ('Desactivado')
                    }
                  </span>
                </div>
              </div>

              <hr></hr>

              {
                this.state.actualizarPlanAuto ?
                  (
                    <div className="row justify-content-between">
                      <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                        <div className="m-b-4-planes-SA contentPlanAuto-planes-SA">
                          <span className="textPlanAuto-planes-SA">Habilitado por:</span>
                        </div>
                        {
                          this.state.visitasH ?
                            (
                              <div className="row">
                                <div className="col-5">
                                  <span className="txt-verUsuario-SA">Visitas: </span>
                                  <span className="txt-verUsuario-SA"><FaCheck></FaCheck></span>
                                </div>
                                {!this.state.disableMax ? (
                                  <div className="col-7">
                                    <span className="txt-verUsuario-SA">Cantidad: </span>
                                    <span className="txt-verUsuario-SA"> {this.state.cantidadHV || ''} </span>
                                  </div>
                                ) : (<div></div>)}

                              </div>
                            ) :
                            (
                              <div></div>
                            )
                        }
                        {
                          this.state.puntosH ?
                            (
                              <div className="row">
                                <div className="col-5">
                                  <span className="txt-verUsuario-SA">Puntos: </span>
                                  <span className="txt-verUsuario-SA"><FaCheck></FaCheck></span>
                                </div>
                                {!this.state.disableMax ? (
                                  <div className="col-7">
                                    <span className="txt-verUsuario-SA">Cantidad: </span>
                                    <span className="txt-verUsuario-SA"> {this.state.cantidadHP || ''} </span>
                                  </div>
                                ) : (<div></div>)}
                              </div>
                            ) :
                            (
                              <div></div>
                            )
                        }
                        {
                          this.state.comprasH ?
                            (
                              <div className="row">
                                <div className="col-5">
                                  <span className="txt-verUsuario-SA">Compras: </span>
                                  <span className="txt-verUsuario-SA"><FaCheck></FaCheck></span>
                                </div>
                                {!this.state.disableMax ? (
                                  <div className="col-7">
                                    <span className="txt-verUsuario-SA">Cantidad: </span>
                                    <span className="txt-verUsuario-SA"> {this.state.cantidadHC || ''} </span>
                                  </div>
                                ) : (<div></div>)}
                              </div>
                            ) :
                            (
                              <div></div>
                            )
                        }
                        {!this.state.disableMax ? (
                          <div>
                            <div className="row m-t-10-planes-SA">
                              <div className="col-12">
                                <span className="txt-verUsuario-SA">Rango de días: </span>
                                <span className="txt-verUsuario-SA"> {this.state.rangoDiasH || ''} </span>
                              </div>
                            </div>
                            <div className="row m-t-3-planes-SA">
                              <div className="col-12">
                                <span className="txt-verUsuario-SA">Plan a actualizar automáticamente: </span>
                                <span className="txt-verUsuario-SA"> {planAutoUpdate || ''} </span>
                              </div>
                            </div>
                          </div>
                        ) : (<div></div>)}

                      </div>
                      {!this.state.disableMin ? (
                        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                          <div className="m-b-4-planes-SA contentPlanAuto-planes-SA">
                            <span className="textPlanAuto-planes-SA">Baja:</span>
                          </div>
                          {
                            this.state.visitasH ?
                              (
                                <div className="row">
                                  <div className="col-md-12">
                                    <span className="txt-verPlan-SA">Cantidad: </span>
                                    <span className="txt-verPlan-SA"> {this.state.cantidadBV || ''} </span>
                                  </div>
                                </div>
                              ) :
                              (
                                <div></div>
                              )
                          }
                          {
                            this.state.puntosH ?
                              (
                                <div className="row">
                                  <div className="col-md-12">
                                    <span className="txt-verPlan-SA">Cantidad: </span>
                                    <span className="txt-verPlan-SA"> {this.state.cantidadBP || ''} </span>
                                  </div>
                                </div>
                              ) :
                              (
                                <div></div>
                              )
                          }
                          {
                            this.state.comprasH ?
                              (
                                <div className="row">
                                  <div className="col-md-12">
                                    <span className="txt-verPlan-SA">Cantidad: </span>
                                    <span className="txt-verPlan-SA"> {this.state.cantidadBC || ''} </span>
                                  </div>
                                </div>
                              ) :
                              (
                                <div></div>
                              )
                          }

                          <div className="row m-t-10-planes-SA">
                            <div className="col-12">
                              <span className="txt-verPlan-SA">Rango de días: </span>
                              <span className="txt-verPlan-SA"> {this.state.rangoDiasB || ''} </span>
                            </div>
                          </div>
                          <div className="row m-t-3-planes-SA">
                            <div className="col-12">
                              <span className="txt-verPlan-SA">Plan a actualizar automáticamente: </span>
                              <span className="txt-verPlan-SA"> {planAutoDegrade || ''} </span>
                            </div>
                          </div>
                        </div>
                      ) : (<div></div>)}
                    </div>
                  ) :
                  (
                    <div></div>
                  )
              }

            </div>
          </div>
          <div className="fixed-bottom bottom-planes-SA p-b-3-planes-SA p-t-2-planes-SA">
            <div className="row align-items-end justify-content-end">
              <div className="col-xl-5 col-lg-6 col-md-8">
                <button type="button" onClick={this.volver} className="btn-secondaryReward btn-controlsPlanes-SA m-r-5-planes-SA"> Volver </button>
                <button type="button" onClick={this.volver} className="btn-primaryReward btn-controlsPlanes-SA"> Aceptar </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VisualizarPlanAdmin.propTypes = {
  history: PropTypes.object,
  planAdmin: PropTypes.object.isRequired,
  planesAdminList: PropTypes.array.isRequired
};

export default withRouter(VisualizarPlanAdmin);
