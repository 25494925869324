import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getUserAdminAction } from './../actions/getUserAdminAction';
import {getUserAdmin} from './../selectors';
import Nav from '../../menu/navBar';
import SideBar from '../../menu/sideBar';
import VisualizarUserAdmin from './../components/visualizarUserAdmin';

class VerUserAdminContainer extends Component {

  componentDidMount() {
    const data = {
      username: this.props.match.params.userName
    };
    this.props.getUserAdminAction(data);
  }

  render() {

    const userName = this.props.match.params.userName;
    const userAdmin = this.props.userAdmin;

    return (
      <div>
        <Nav />
        <div className='row custom-row' id='body-row'>
          <SideBar option={'rootuser'}/>
        </div>
        <div className='col-11 float-right'>
          <div className="row">
            <div className="col-11">
              <div className="row justify-content-start m-t-5-users-SA">
                <div className="col-md-12 content-users-SA">
                  <span>
                    <Link to='/admin/usuarios' className='link-users-SA'>Usuarios</Link>
                    { ' / ' }
                    <Link to={'/admin/usuarios/see/'+userName} className='link-users-SA'>Ver usuario</Link>
                  </span>
                </div>
              </div>
              <div className="row justify-content-start m-t-2-users-SA">
                <div className="col-md-12 content-users-SA">
                  <span className="text-nuevoUser-SA">
                    Visualiza los datos de este usuario.
                  </span>
                </div>
              </div>
            </div>
          </div>
          {
            userAdmin.length === 1 ?
              (
                <VisualizarUserAdmin userAdmin={userAdmin[0]}></VisualizarUserAdmin>
              ) :
              (
                <div></div>
              )
          }
        </div>
      </div>
    );
  }
}

VerUserAdminContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userName: PropTypes.string.isRequired,
    })
  }),
  userAdmin: PropTypes.array.isRequired,
  getUserAdminAction: PropTypes.func.isRequired,
};

VerUserAdminContainer.defaultProps = {
  userAdmin: []
};

const mapStateToProps = state => ({
  userAdmin: getUserAdmin(state)
});

export default withRouter(connect(mapStateToProps, { getUserAdminAction })(VerUserAdminContainer));
