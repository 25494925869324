import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './../style.css';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { TiInfoOutline } from 'react-icons/ti';
//import ModalCustom from './../../../globalComponents/modal/modal';
import { updateUserAction } from './../actions';
import { CurrentUserIsActive } from './../../../helpers/capabilitiesControl';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  IoIosCheckmarkCircle,
  IoIosCloseCircle
} from 'react-icons/io';

class UpdateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      errors: '',
      modalTitle: '',
      modalContent: '',
      modalAcceptText: '',
      modal: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.isSubmitting = this.isSubmitting.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {

  }

  openModal() {
    this.setState({ modal: true });
  }
  closeModal() {
    this.setState({ modal: false });
  }
  handleSubmit(event) {
    event.preventDefault();

    let values = {
      username: this.state.username
    };

    this.validate(event, values);
  }

  validate(event, values) {
    let errors = {};
    if (!values.username || values.username === '') {
      errors.username = 'Por favor, introduce un nuevo nombre de usuario valido.';
    }

    this.setState({ errors });
    this.isSubmitting(event, errors, values);
  }

  isSubmitting(event, errors, values) {
    event.preventDefault();

    if (Object.keys(errors).length === 0) {
      const loginData = JSON.parse(localStorage.getItem('loginData'));
      const token = JSON.parse(localStorage.getItem('token'));
      const { data } = this.props;

      let datos = {
        'username': values.username,
      };

      updateUserAction(token, loginData._id, data[0]._id, datos).then(
        res => {
          if (res[0]) {
            if (res[0].ok) {
              this.setState({
                modalContent: 'El usuario se ha actualizado correctamente'
              });
              this.notifySuccess();
            } else if (res[0].error.name === 'TokenExpiredError') {
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/admin/logout/true');
            } else {
              this.setState({
                modalContent: res[0].data.message
              });
              this.notifyError();

            }
          } else {
            this.setState({
              modalContent: 'verifique los datos y que no existan repetidos'
            });
            this.notifyError();

          }


        }, error => {
          this.setState({
            modalContent: 'verifique los datos y que no existan repetidos'
          });
          this.notifyError();
          this.errors = error;
        }
      );
    }
  }

  notifySuccess = () => {
    toast.success(<div><IoIosCheckmarkCircle className='icon-toast' />&nbsp;&nbsp;&nbsp;&nbsp;{this.state.modalContent}</div>, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  notifyError = () => {
    toast.error(<div><IoIosCloseCircle className='icon-toast' />&nbsp;&nbsp;&nbsp;&nbsp;{this.state.modalContent}</div>, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  render() {
    const { data } = this.props;
    let username = '';
    if (data[0]) {
      if (data[0].username) {
        username = data[0].username;
      }
    }

    return (
      <div className='col-11 float-right max-w-body'>
        <div className='custom-space-row'></div>
        <div className='custom-space-row'></div>
        <div className='custom-space-row'></div>
        <div className='row'>
          <p className=' position-link'><Link to='/admin/list_users' className='position-link'>Usuarios</Link> / <Link to='/admin/add_user' className='position-link'>Nuevo Usuario</Link></p>
        </div>
        <div className='row'>
          <p className='info-text'>Realiza los cambios que desees</p>
        </div>

        <ToastContainer autoClose={5000} />

        <Container fluid>
          <div className='row'>
            <div className='col-11'>
              <form className="formReward formReward-add" id="formAddDemo" onSubmit={this.handleSubmit}>
                <Form.Row>
                  <Col>
                    {this.state.errors.add && (
                      <span className="span-errorMessage error-message-custom"><TiInfoOutline></TiInfoOutline> {this.state.errors.add}</span>
                    )}
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xl length='6'>
                    <Form.Group className="group margin-l-40p">
                      <input type="text" id="empresa" name="empresa" value={this.state.username || username || ''}
                        onChange={event =>
                          this.setState({ username: event.target.value })}
                        className={(this.state.errors.username && ('input-errorLine')) + ' input-form'}
                        minLength="3" maxLength="40" pattern="[A-Za-z0-9]+" />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <Form.Label className='custom-label-form'>Nombre de Usuario</Form.Label>
                      {this.state.errors.username && (
                        <span className="span-errorMessage"><TiInfoOutline></TiInfoOutline> {this.state.errors.username}</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col></Col>
                </Form.Row>

                <Form.Row className="m-t-4rem">
                  <Col lg={7} xs={7} md={4} sm={2}></Col>
                  {CurrentUserIsActive() ? (
                    <Col>
                      <div className=''>
                        <div className='fixed-bottom bottom-planes-SA p-b-3-planes-SA p-t-2-planes-SA'>
                          <div className='row align-items-end justify-content-end'>
                            <div className='col-xl-5 col-lg-6 col-md-8'>
                              <Col className='d-inline'>
                                <Link to='/admin/list_users' className='btn-secondaryReward btn-custom-size  back-btn'>Volver</Link>
                              </Col>
                              <Col className='d-inline'>
                                <button className='btn-primaryReward btn-custom-size' type='submit'>Actualizar</button>
                              </Col>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ) : ('')}

                </Form.Row>

              </form>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

UpdateUser.propTypes = {
  data: PropTypes.array.isRequired
};
UpdateUser.defaultProps = {
  data: [],
};

export default withRouter(UpdateUser);
