/* eslint-disable no-dupe-else-if */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './globalComponents/buttons/containers/primary/buttonPrimary.css';
import './globalComponents/buttons/containers/secondary/buttonSecondary.css';
import './globalComponents/buttons/containers/red/buttonRed.css';

import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import registerServiceWorker from './registerServiceWorker';
const rootComponent = (
  <Provider store={store}>
    <App />
  </Provider>
);
ReactDOM.render(rootComponent, document.getElementById('root'));
registerServiceWorker();
/*
ReactDOM.render(<App />, document.getElementById('root'));
*/
