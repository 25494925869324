export function loginAdminService(url, data) {

  var URLSearchParams = Object.keys(data).map((key) => {
    return (key) + '=' + (data[key]);
  }).join('&');

  return new Promise((resolve, reject) => {
    var res = fetch(url + 'auth/login/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
      },
      body: URLSearchParams
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (responser) {
        return responser;
      }).catch((error) => {
        return error;
      });
    res ? resolve(res) : reject('error-');
  });
}
