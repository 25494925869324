import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AvisoPrivacidad from '../../components/avisoPrivacidad';
import FormCorreoRecuperacionToken from './../components/formCorreoRecuperacionToken';

class ResetPasswordTokenContainer extends Component {

  constructor(props){
    super(props);
    var classCycle=['fondo1','fondo2', 'fondo3'];

    var randomNumber = Math.floor(Math.random() * classCycle.length);

    this.state = {
      classToAdd: classCycle[randomNumber]
    };
  }

  render() {
    return (
      <div className={'login-content-'+this.state.classToAdd}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 m-t-3-login">
              <div className="logotipo-login m-l-10-login">
                <img src="/assets/login/Simulación logotipo-01.svg" alt="simulacion-logotipo"></img>
              </div>
            </div>
          </div>
          <div className="row m-t-7-login justify-content-end">
            <div className="col-xl-4 col-lg-5 col-md-7">
              <div className="texto-form-login">
                Iniciar sesión
              </div>
              <div className="component-form-login m-t-3-login">
                <FormCorreoRecuperacionToken></FormCorreoRecuperacionToken>
              </div>
            </div>
          </div>
        </div>

        <AvisoPrivacidad></AvisoPrivacidad>

      </div>
    );
  }
}

ResetPasswordTokenContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    })
  })
};

export default ResetPasswordTokenContainer;
