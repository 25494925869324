/* eslint-disable no-dupe-else-if */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import './buttonSecondary.css';


class ButtonSecondary extends Component {
  render() {
    return (
      <Button className="btn-secondaryReward">{this.props.title}</Button>
    );
  }
}

ButtonSecondary.propTypes = {
  title: PropTypes.string
};

export default ButtonSecondary;
