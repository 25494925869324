import { resetPasswordService, changePasswordService } from './../services/resetPasswordService';
import { URL_API } from './../../../constants';

export function resetPasswordAction(data) {
  return new Promise((resolve, reject) => {
    var response = resetPasswordService(URL_API, data);
    response ? resolve(response) : reject('error');
  });
}

export function changePasswordAction(data) {
  return new Promise((resolve, reject) => {
    var response = changePasswordService(URL_API, data);
    response ? resolve(response) : reject('error');
  });
}
