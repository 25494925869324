/* eslint-disable no-else-return */
export function actualizarUserAdminService(url, data, id, token) {

  var URLSearchParams = Object.keys(data).map((key) => {
    return (key) + '=' + (data[key]);
  }).join('&');

  return new Promise((resolve, reject) => {
    var res = fetch(url + 'company/'+id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
        'Authorization': token
      },
      body: URLSearchParams
    })
      .then(function (response) {
        let status = response.status;
        let res = {ok: response.ok, status: status};
        if(status === 401){
          return res;
        } else {
          return response.json();
        }
      })
      .then(function (responser) {
        return responser;
      }).catch((error) => {
        return error;
      });
    res ? resolve(res) : reject('error-');
  });
}
